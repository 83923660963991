import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useSocialSummariesApi } from '@/pages/PatientProfile/CarePlans/SocialSummary/store'
import { EditOffboardingSummaryForm } from './types'

/**
 * create social summary
 * @param payload
 */
export async function createOffboardingSummary(
  payload: EditOffboardingSummaryForm
) {
  let res
  try {
    res = await useSocialSummariesApi().create({ body: payload })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error creating offboarding summary',
      type: NotificationType.DANGER,
      error: `Error creating offboarding summary: ${err}`,
    })
  }

  useNotificationStore().setNotification({
    message: 'Successfully created offboarding summary.',
    type: NotificationType.SUCCESS,
  })
  return res
}

/**
 * update existing offboarding summary
 * @param payload
 * @param socialSummaryId
 */
export async function updateOffboardingSummary(
  payload: EditOffboardingSummaryForm,
  socialSummaryId: string
) {
  let res

  try {
    res = await useSocialSummariesApi().partialUpdate({
      body: payload,
      ids: [socialSummaryId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error updating offboarding summary',
      type: NotificationType.DANGER,
      error: `Error updating offboarding summary: ${err}`,
    })
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated offboarding summary.',
    type: NotificationType.SUCCESS,
  })
  return res
}
