import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { object, string, InferType, date } from 'yup'

export const PADSTART_DATE_FORMAT_INT = 2
export const HUMANA_MEMBER_ID_CHAR_REQUIREMENT = 11
export const IS_ELIGIBLE_STRING = 'Member is eligible'
export const IS_INELIGIBLE_STRING = 'Member is ineligible'
export const IS_INELIGIBLE_SOP =
  'https://docs.google.com/document/d/1rKOPQpk2RbBeCfu97gczGeLDAn7ouXv4QFTWjjkYKW4/edit?tab=t.0#bookmark=kix.c0grmjgm56mz'

export enum HumanaEligibilityFlag {
  ELIGIBLE = 'Y',
  NOT_ELIGIBILE = 'N',
  NOT_FOUND = '',
}

export type HumanaEligibilityPayload = {
  umid: string
  dateOfBirth: string
}

export type HumanaEligibilityData = {
  eligibilityFlag: HumanaEligibilityFlag | null
  message: string
}

export const HUMANA_ELIGIBILITY_RES_MAP = {
  ELIGIBLE: {
    label: IS_ELIGIBLE_STRING,
    instructions:
      'Proceed with creating a profile and moving the member to Enrollment program status',
    severity: MessageSeverity.SUCCESS,
    icon: AllowedIcons.checkShield,
    resourceLink: null,
  },
  NOT_ELIGIBILE: {
    label: IS_INELIGIBLE_STRING,
    instructions:
      'If referred by a provider, notify provider of ineligibility in the Network Ops slack channel. If self-referred, notify the individual directly. See linked SOP for talking points.',
    severity: MessageSeverity.ERROR,
    icon: AllowedIcons.exclamationShield,
    resourceLink: IS_INELIGIBLE_SOP,
  },
}

export const schema = object({
  humanaMemberDob: date().required(),
  humanaMemberId: string()
    .test(
      'is-not-empty',
      'humanaMemberId is a required field',
      (value) => !value || value.trim() !== '' // Pass if no value or a non-empty value is provided
    )
    .test(
      'max-length',
      ({ value }) =>
        `Maximum length is ${HUMANA_MEMBER_ID_CHAR_REQUIREMENT} characters, you have entered ${
          value?.length || 0
        } characters`,
      (value) => !value || value.length <= HUMANA_MEMBER_ID_CHAR_REQUIREMENT
    )
    .test(
      'min-length',
      ({ value }) =>
        `Minimum length is ${HUMANA_MEMBER_ID_CHAR_REQUIREMENT} characters, you have entered ${
          value?.length || 0
        } characters`,
      (value) => !value || value.length >= HUMANA_MEMBER_ID_CHAR_REQUIREMENT
    ),
})

export type EligibilityCheckForm = InferType<typeof schema>
