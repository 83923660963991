<template>
  <TCollapse
    ref="taskRef"
    data-cy="task-card"
    :class="`border py-2 px-3 w-full rounded ${
      isBlocked ? 'bg-nash-neutral200' : ''
    }`"
    :is-visible="showTask"
    @click="clicked = !clicked"
  >
    <template #trigger>
      <div class="flex-inline w-full mb-1 space-y-1">
        <div class="flex">
          <TTruncate
            :label="task?.title"
            :max-chars="clicked ? 10000 : 300"
            class="font-bold"
          />
          <div
            v-if="canOverridePathwaysBlockedStatus && currentIcon && isBlocked"
            v-tooltip.right="getTaskStatusString(task?.status)"
          >
            <LegacyTDropdown
              name="task-status"
              :icon="currentIcon"
              :expanded="false"
              :model-value="''"
              class="ml-2 h-6 items-center"
              :options="taskStatusOptions"
              @update:model-value="(v) => updateTaskStatus(v)"
            />
          </div>
        </div>
        <div class="flex w-full text-xs font-normal">
          {{ completedSubtasks }}/{{ subtaskIds?.length ?? 0 }} Subtasks
        </div>
      </div>
      <div><!-- Spacer Div, leave in --></div>
      <LegacyTButton
        v-if="!shouldDisableOutcomes"
        :value="outcomes?.length ? 'Edit Outcomes' : 'Add Outcomes'"
        icon="barChart"
        icon-fill="nash-midnightBlue600"
        icon-position="left"
        type="whiteAndGray"
        :size="ButtonSize.MD"
        class="rounded-full border w-48"
        @click.stop="outcomesModalOpen = true"
      />
      <PatientOutcomesModal
        v-if="outcomesModalOpen"
        :current-outcome-note="task.outcomeNote ?? undefined"
        :current-outcome-quotes="task.outcomeQuotes ?? undefined"
        :current-outcomes="outcomes ?? []"
        :current-task-id="task.taskId"
        :possible-outcomes="possibleOutcomes ?? []"
        @click.stop
        @hide-modal="outcomesModalOpen = false"
      />
    </template>
    <template #content>
      <TMTertiaryButton
        label="+ Add Subtask"
        class="my-3"
        padding="sm"
        @click="showTaskModal = true"
      />
      <div v-if="!taskSubtasks?.length && isLoading">
        <TMTertiaryButton label="Loading" padding="sm" class="my-3" />
      </div>
      <div v-if="taskSubtasks?.length" class="space-y-4">
        <SubtaskRow
          v-for="subtask in allSubtasksSorted"
          :id="`subtask-${subtask?.subtaskId}`"
          :key="subtask?.subtaskId"
          :display-type="SubtaskRowDisplayType.TASK_ACCORDION"
          :subtask="subtask"
          :is-overdue="isSubtaskOverdue(subtask)"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="refetchThymelineSubtasks"
        />
        <SubtaskReattemptModal
          v-if="selectedReattemptSubtask"
          :subtask="selectedReattemptSubtask"
          :is-open="showReattemptModal"
          @close="closeReattemptModal"
          @callback-fn="refetchPostUpdate"
        />
        <SubtaskSubstatusModal
          v-if="
            showSurfacedStatusReason &&
            subtaskStatus &&
            selectedSubStatusSubtask
          "
          :subtask="selectedSubStatusSubtask"
          :status="subtaskStatus"
          :is-open="showSubStatusModal"
          @close="showSubStatusModal = false"
          @callback-fn="refetchPostUpdate"
        />
        <TMTertiaryButton
          v-if="subtaskIds.length > allSubtasksSorted.length && !isLoading"
          :disabled="taskSubtasks.length >= subtaskIds.length"
          padding="sm"
          label="Load more"
          class="my-3"
          @click="loadMore"
        />
        <div v-if="isLoading">
          <TMTertiaryButton label="Loading" padding="sm" class="my-3" />
        </div>
      </div>
      <AddTaskModal
        :is-visible="showTaskModal"
        type="subtasks"
        :task-id="task?.taskId"
        @close="showTaskModal = false"
      />
    </template>
  </TCollapse>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import filter from 'lodash/filter'
import partition from 'lodash/partition'
import uniqBy from 'lodash/uniqBy'

import { storeToRefs } from 'pinia'
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import { QUERY_PARTS } from '@/legacy/components/queue/lib/sharedQueueParts'
import { compareNullableDatetimes, stringToDateTime } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'

import TCollapse from '@/legacy/nashville/TCollapse.vue'
import TTruncate from '@/legacy/nashville/text/TTruncate.vue'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'

import {
  useSubtaskApi,
  useTaskAccordionApi,
} from '@/legacy/store/modules/subtasks'
import { useTaskOutcomesApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { OutcomeReference, Outcome } from '@/legacy/types/outcomes'
import {
  taskStatusLabels,
  TaskStatus,
  SubtaskStatuses,
} from '@/legacy/types/pathways/pathways'
import {
  MAX_NUM_SUBTASKS,
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
  onlyOpenSubtaskStatuses,
} from '@/legacy/types/pathways/subtasks'
import { Task } from '@/legacy/types/pathways/tasks'
import PatientOutcomesModal from '../PatientOutcomesModal.vue'
import AddTaskModal from './AddTaskModal.vue'

import { isSubtaskOverdue } from './lib/subtask'
import { renderBlockedMessage } from './lib/task'
import SubtaskReattemptModal from './SubtaskReattemptModal.vue'
import SubtaskRow from './SubtaskRow.vue'

// CONST
const MAX_TOOL_TIP_TITLE_LENGTH = 30

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TMTertiaryButton,
    LegacyTButton,
    LegacyTDropdown,
    TCollapse,
    SubtaskRow,
    AddTaskModal,
    PatientOutcomesModal,
    SubtaskReattemptModal,
    TTruncate,
  },
  props: {
    task: {
      type: Object as PropType<Task>,
      required: true,
    },
  },
  emits: ['refetchTasks'],
  setup(props, context) {
    const { canOverridePathwaysBlockedStatus } = storeToRefs(useProfileStore())
    const { isLoading } = storeToRefs(useTaskAccordionApi())
    const { datum: updatedSubtask } = storeToRefs(useSubtaskApi())

    const router = useRouter()
    const route = useRoute()
    const taskRef = ref<ComponentPublicInstance | null>(null)
    const taskSubtasks = ref<Subtask[]>([])
    const showTaskModal = ref(false)
    const clicked = ref(false)
    const pageNumber = ref(1)
    const showReattemptModal = ref(false)
    const selectedReattemptSubtask = ref<Subtask | null>(null)
    const possibleOutcomes = ref<OutcomeReference[] | null>(null)
    const outcomes = ref<Outcome[] | null>(null)
    const showSubStatusModal = ref(false)
    const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
    const selectedSubStatusSubtask = ref<Subtask | null>(null)
    const subtaskStatus = ref<SubtaskStatus | null>(null)

    const subtaskIds = computed(() =>
      props.task?.subtaskMaps?.length
        ? props.task?.subtaskMaps.map((stm) => stm.subtaskId)
        : []
    )

    const queryTaskId = computed(() => route.query.taskId)
    const queryPathwayId = computed(() => route.query.pathwayId)
    const isBlocked = computed(() => props.task?.status === TaskStatus.BLOCKED)

    /**
     *Function to fetch all possible outcomes for given task's task template id
     */
    async function getPossibleOutcomes() {
      const taskTemplate = await useTasksStore().getAllTaskTemplates({
        filter_variant_ids: [props.task.taskVariantId],
      })
      possibleOutcomes.value = taskTemplate.data[0].possibleOutcomes
    }

    /**
     * Function to retrieve all task's outcomes
     */
    async function getTaskOutcomes() {
      const taskOutcomes = await useTaskOutcomesApi().list({
        params: {
          filter_task_ids: [props.task.taskId],
          filter_patient_ids: [route.params.patientId],
          parts: ['outcome_validation'],
        },
      })
      outcomes.value = taskOutcomes.data
    }

    /**
     * Function called to close reattempt modal
     */
    function closeReattemptModal() {
      showReattemptModal.value = false
    }
    /**
     *
     * @param subtask
     */
    function openReattemptModal(subtask: Subtask) {
      showReattemptModal.value = true
      selectedReattemptSubtask.value = subtask
    }

    /**
     * Function to show/open SubStatusModal modal
     * @param subtask
     * @param status
     */
    function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
      showSubStatusModal.value = true
      subtaskStatus.value = status
      selectedSubStatusSubtask.value = subtask
    }

    const showTask = computed(() => queryTaskId.value === props.task?.taskId)

    const subtask = computed(() => {
      if (
        showTask.value &&
        route.query.subtaskId &&
        taskSubtasks.value.length
      ) {
        return taskSubtasks.value.find(
          (s: Subtask) => s.subtaskId === route.query.subtaskId
        )
      }
      return null
    })

    const toolTipDirection = computed(() =>
      props.task.title.length > MAX_TOOL_TIP_TITLE_LENGTH ? 'left' : 'right'
    )

    /**
     * Helper function to scroll to matched subtask if `subtaskId` is in query
     */
    function scrollToView() {
      let el: HTMLElement | null
      if (subtask.value) {
        el = document.getElementById(`subtask-${subtask.value.subtaskId}`)
      } else if (showTask.value) {
        el = taskRef.value?.$el
      }

      setTimeout(function () {
        el?.scrollIntoView(false)
      }, 0)
    }

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      const results = await fetchSubtasks(pageNumber.value)
      taskSubtasks.value = [...taskSubtasks.value, ...results.data]
    }

    const refetchThymelineSubtasks = async (updatedSubtaskId: string) => {
      await refetchPostUpdate([updatedSubtaskId])
      const updatedSubtask = taskSubtasks.value.find(
        (s: Subtask) => s.subtaskId === updatedSubtaskId
      )
      if (updatedSubtask) {
        const patchOnly = onlyOpenSubtaskStatuses.find(
          (status) => status === updatedSubtask.status
        )

        if (patchOnly) {
          thymeDispatch('thymeline-patch', {
            id: updatedSubtaskId,
            item: updatedSubtask,
          })
        } else {
          thymeDispatch('thymeline-update')
        }
      }
    }

    const fetchSubtasks = async (
      pageNum = 1,
      idsToFetch: null | string[] = null
    ) => {
      return await useTaskAccordionApi().list({
        params: {
          parts: QUERY_PARTS,
          filter_patient_ids: [route.params?.patientId],
          filter_subtask_ids: idsToFetch ?? subtaskIds.value,
          page_length: MAX_NUM_SUBTASKS,
          page_number: pageNum,
          sort_by: ['status,desc', 'isCompleted,asc', 'dueDatetime,asc'],
        },
      })
    }

    const refetchPostUpdate = async (idsToFetch: null | string[] = null) => {
      const results = await fetchSubtasks(1, idsToFetch)
      taskSubtasks.value = uniqBy(
        [...results.data, ...taskSubtasks.value],
        'subtaskId'
      )
    }

    const allSubtaskForTaskCompleted = computed(() =>
      taskSubtasks.value?.every(
        (subtask) => subtask.status === SubtaskStatus.COMPLETED
      )
    )

    // Trigger outcomes modal if last subtask of parent task is completed
    watch(allSubtaskForTaskCompleted, () => {
      if (allSubtaskForTaskCompleted.value) {
        if (
          updatedSubtask.value &&
          updatedSubtask.value.status === SubtaskStatus.COMPLETED
        ) {
          const parentTaskSubtaskIds =
            props.task.subtaskMaps?.map((subtaskMap) => subtaskMap.subtaskId) ??
            []
          const parentTaskMatch = parentTaskSubtaskIds.includes(
            updatedSubtask.value?.subtaskId
          )
          if (parentTaskMatch && possibleOutcomes.value?.length) {
            outcomesModalOpen.value = true
          }
        } else {
          context.emit('refetchTasks')
        }
      }
    })

    // Update taskSubtasks data on create/updates and
    watch(subtaskIds, async () => {
      const results = await fetchSubtasks(pageNumber.value, subtaskIds.value)
      taskSubtasks.value = uniqBy(
        [...results.data, ...taskSubtasks.value],
        'subtaskId'
      )
      scrollToView()
    })

    // Set taskSubtask data if subtasks have not been fetched on accordion open
    //
    watch(clicked, async () => {
      if (!taskSubtasks.value.length) {
        const results = await fetchSubtasks()
        taskSubtasks.value = uniqBy(
          [...results.data, ...taskSubtasks.value],
          'subtaskId'
        )
      }
      if (queryTaskId.value) {
        void router.push({
          query: {
            pathwayId: queryPathwayId.value as string,
            taskId: undefined,
          },
        })
      }

      scrollToView()
    })

    watch(showTask, async () => {
      if (showTask.value) {
        const results = await fetchSubtasks(pageNumber.value)
        taskSubtasks.value = uniqBy(
          [...results.data, ...taskSubtasks.value],
          'subtaskId'
        )
      }
    })

    // When component loads, if accordion is open, fetch taskSubtasks
    onMounted(async () => {
      if (showTask.value) {
        const results = await fetchSubtasks(pageNumber.value)
        taskSubtasks.value = uniqBy(
          [...results.data, ...taskSubtasks.value],
          'subtaskId'
        )
      }

      void getPossibleOutcomes()
      void getTaskOutcomes()
      scrollToView()
    })

    /**
     *
     * @param v
     */
    async function updateTaskStatus(v: TaskStatus) {
      await useTasksStore().updateTask(props.task?.taskId, { status: v })
    }

    /**
     *
     * @param status
     */
    function getTaskStatusString(status: string) {
      if (status === TaskStatus.BLOCKED) {
        return renderBlockedMessage(
          props.task?.statusReason,
          props.task?.minStartDatetime,
          true
        )
      }

      const match = taskStatusLabels.find(
        ({ value }: { label: string; value: TaskStatus }) => value === status
      )
      if (match) {
        return match.label
      }
      return '<Unknown Status>'
    }

    const taskStatusOptions = computed(() => {
      const allOptions = taskStatusLabels.map(({ label, value }) => [
        value,
        label,
      ])
      return allOptions.filter(
        (option: string[]) =>
          option[0] !== TaskStatus.COMPLETED && option[0] !== TaskStatus.BLOCKED
      )
    })

    const currentIcon = computed(() => {
      const status = props.task?.status
      if (
        status === 'ON_HOLD' ||
        status === 'CANCELLED' ||
        status === 'BLOCKED'
      ) {
        return Object.values(SubtaskStatuses).filter(
          (taskStatus) => taskStatus.value === status
        )[0]?.icon
      }
      return null
    })

    const partitionedSubtasks = computed(
      () =>
        partition(taskSubtasks.value, [
          'status',
          SubtaskStatuses.COMPLETED.value,
        ]) ?? [[], []]
    )

    // Ensure "COMPLETED" subtasks are last in list
    const allSubtasksSorted = computed(() => {
      return [
        ...partitionedSubtasks.value[1]
          .slice()
          .sort((a, b) =>
            compareNullableDatetimes(
              stringToDateTime(a.dueDatetime),
              stringToDateTime(b.dueDatetime)
            )
          ),
        ...partitionedSubtasks.value[0],
      ]
    })

    const completedSubtasks = computed(() => {
      if (taskSubtasks.value?.length) {
        return filter(
          taskSubtasks.value,
          (subtask: Subtask) => subtask?.status === 'COMPLETED'
        ).length
      }
      return 0
    })

    const outcomesModalOpen = ref(false)
    const shouldDisableOutcomes = computed(
      () =>
        !possibleOutcomes.value?.length ||
        props.task?.status !== TaskStatus.COMPLETED
    )

    return {
      updatedSubtask,
      isBlocked,
      possibleOutcomes,
      outcomes,
      refetchPostUpdate,
      isLoading,
      loadMore,
      refetchThymelineSubtasks,
      subtaskIds,
      isSubtaskOverdue,
      SubtaskRowDisplayType,
      taskSubtasks,
      openReattemptModal,
      selectedReattemptSubtask,
      closeReattemptModal,
      showReattemptModal,
      showSurfacedStatusReason,
      subtaskStatus,
      showSubStatusModal,
      selectedSubStatusSubtask,
      openSubStatusModal,
      toolTipDirection,
      TaskStatus,
      updateTaskStatus,
      taskStatusOptions,
      getTaskStatusString,
      currentIcon,
      taskRef,
      allSubtasksSorted,
      completedSubtasks,
      showTaskModal,
      showTask,
      outcomesModalOpen,
      shouldDisableOutcomes,
      clicked,
      canOverridePathwaysBlockedStatus,
      MAX_NUM_SUBTASKS,
      ButtonSize,
    }
  },
})
</script>
