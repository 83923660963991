import { object, string, InferType, array, boolean } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

export const schema = object({
  transportationBarrier: array().of(string().required()).nullable(),
  foodBarrier: array().of(string().required()).nullable(),
  housingBarrier: array().of(string().required()).nullable(),
  financialBarrier: array().of(string().required()).nullable(),
  inHomeSupport: array().of(string().required()).nullable(),
  caregiverMapping: array().of(string().required()).nullable(),
  socialSupport: array().of(string().required()).nullable(),
  benefitsProgramsServices: array().of(string().required()).nullable(),
  healthcareProxy: boolean().nullable(),
  additionalContext: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditSocialSummaryForm = InferType<typeof schema>
