<template>
  <div
    @drop.prevent="onDrop"
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
  >
    <div class="actions-container">
      <div v-if="isCompleted">
        <TIcon class="text-nash-fern600" icon="checkCircle" />
        {{ completedMessage }}
      </div>
      <PointerSubtask
        v-else-if="pointerSubtask"
        :subtask="pointerSubtask"
        :disabled="isLoading"
        @refetch-comms-and-subtasks="refetchCommAndPointerSubtask"
      />
    </div>
    <div v-if="showWarnings" class="space-y-2 pb-5">
      <TMessage
        v-if="!isOwner"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="message-box-not-owner"
        :label="notOwnerMsg"
      />
      <TMessage
        v-if="hasConsented === null"
        :expanded="false"
        :severity="MessageSeverity.WARN"
        name="message-box-not-yet-consented"
        :label="notConsentedMsg"
      />
      <TMessage
        v-else-if="!hasConsented"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="message-box-unconsented"
        :label="notConsentedMsg"
      />
    </div>
    <div
      class="flex flex-col p-2 rounded-lg text-nash-neutral800 border border-nash-neutral500 border-1 w-full"
    >
      <TTextarea
        v-model="messageToSend"
        class="message-textarea flex-grow border-none"
        placeholder="Type a message"
        name="text-message"
        :disabled="disabledMessaging"
        :no-border="true"
      />
      <input id="file-upload" type="file" class="hidden" @change="onBrowse" />
      <div
        :class="`flex flex-row items-center pt-0 pb-0 px-1 ${
          addedMedia.length ? 'justify-between' : 'justify-end'
        }`"
      >
        <div class="flex flex-col">
          <div v-for="media in addedMedia" :key="media.name">
            <div class="flex flex-row items-center space-x-1">
              <div>{{ media.name }} ({{ readableSize(media.size) }})</div>
              <TMQuaternaryButton
                name="remove-added-media"
                icon="close"
                @click="() => removeAddedMedia(media.name)"
              />
            </div>
          </div>
        </div>
        <div
          :class="`${
            isOwner ? 'cursor-pointer' : 'cursor-not-allowed'
          } flex flex-row space-x-2 items-center text-message-buttons`"
        >
          <TMQuaternaryButton
            :disabled="disabledSendAndAttachBtns"
            icon="heroicons:paper-clip"
            @click="uploadFile"
          />
          <TMPrimaryButton
            name="send-message"
            icon="heroicons:paper-airplane"
            :disabled="disabledSendAndAttachBtns"
            :loading="isLoading"
            @click="sendMessage"
          />
        </div>
      </div>
    </div>
  </div>
  <DoNotContactModal
    v-if="showHumanaDncModal"
    :is-open="showHumanaDncModal"
    :alternate-modal-text="showHumanaDncModalAlternateText"
    @close="closeHumanaDncModal"
  />
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'

import DoNotContactModal from '@/legacy/components/patient/communicationV2/modals/DoNotContactModal/DoNotContactModal.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'
import setup from './controller'
import PointerSubtask from './PointerSubtask/PointerSubtask.vue'

export default defineComponent({
  components: {
    TMessage,
    TMQuaternaryButton,
    TTextarea,
    TMPrimaryButton,
    TIcon,
    PointerSubtask,
    DoNotContactModal,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.actions-container {
  @apply flex flex-row justify-between py-5 items-center;
}
</style>
