import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  HumanaDncDisplay,
  HumanaDncPayload,
  HumanaDncResponse,
} from '@/legacy/types/patients/humanaDnc'

export const useHumanaDncApi = apiStore<HumanaDncResponse>(
  'humanaDncApi',
  '/api/humana',
  {
    transformData: (d: { data: HumanaDncResponse[] }) => d,
  }
)

/**
 * Retrieve do not contact data for a given patient <> phone number pair.
 * If they are a valid Humana patient that meets the criteria to check DNC
 * this will involve calling the Humana API
 * Returns null if no data is returned.
 * @param payload
 */
export async function getHumanaDnc(
  payload: HumanaDncPayload
): Promise<HumanaDncDisplay> {
  const humanaDncApi = useHumanaDncApi()
  try {
    const response = await humanaDncApi.retrieve({
      ids: ['dnc'],
      params: payload,
      metaOptions: { bubbleErrorThrow: true },
    })
    if (!response) {
      return {
        isDnc: null,
        alternateModalText:
          'Error retrieving DNC status for patient. Please try again later.',
      }
    }
    return { isDnc: response.isDnc, alternateModalText: null }
  } catch (error) {
    if (
      error instanceof ApiError &&
      error.status === HTTPStatusCode.ERROR_422_UNPROCESSABLE_ENTITY
    ) {
      return {
        isDnc: null,
        alternateModalText:
          "This Humana patient lacks valid insurance credentials to check DNC status. Please confirm Humana insurance data is active and correctly specifies the patient's Member ID and try again.",
      }
    } else {
      return {
        isDnc: null,
        alternateModalText:
          'Error retrieving DNC status for patient. Please try again later.',
      }
    }
  }
}
