import apiStore from '@/legacy/store/modules/apiBuilder'
import { SocialSummary } from '@/pages/PatientProfile/CarePlans/SocialSummary/types'

export const useSocialSummariesApi = apiStore<SocialSummary, SocialSummary[]>(
  'socialSummaryApi',
  '/api/care_plan/social_summaries',
  {
    transformData: (d: { data: SocialSummary[] }) => d,
  }
)
