import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { FilterMetaType, FilterType } from '@thyme/nashville/src/types/tables'
import { storeToRefs } from 'pinia'
import { computed, ref, onBeforeMount, watch } from 'vue'
import { sortByLabel } from '@/legacy/libs/dropdowns'
import { staffRoles, humanizeRole } from '@/legacy/libs/entity'
import {
  formatNameFromPerson,
  formatNameFromEntity,
} from '@/legacy/libs/format'
import {
  useAdminCarePodApi,
  useAdminEntityApi,
} from '@/legacy/store/modules/admin'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  createMyQueueApi,
  useStaffQueueApi,
  useSubtaskStaffStatsApi,
} from '@/legacy/store/modules/reminders'
import { useStaffApi, useStaffStore } from '@/legacy/store/modules/staff'
import { MAX_PAGE_SIZE } from '@/legacy/types/api/apiBuilder'
import { CarePod, CarePodStaff } from '@/legacy/types/carePods'
import { Entity, EntityRole } from '@/legacy/types/entities/entities'
import { Person } from '@/legacy/types/entities/people'
import { openAssignedSubtaskStatuses } from '@/legacy/types/pathways/subtasks'
import { CarePodStatus } from '@/legacy/types/patients/carePods'
import { returnSubtaskStatsPerStaff } from '../lib/sharedQueueParts'

/**
 *
 * @param props
 */
export default function (props: any) {
  const { notification } = storeToRefs(useNotificationStore())
  const { data: carePods } = storeToRefs(useAdminCarePodApi())
  const { data: staff } = storeToRefs(useStaffApi())
  const { selfEntity: activeUser, roles } = storeToRefs(useProfileStore())
  const { careTeamChoices } = storeToRefs(useStaffStore())
  const {
    showEnrollmentQueueEnhancements,
    useStacksIncident121225,
    showHumanaDnc,
  } = storeToRefs(useFlagStore())

  const columns = computed(() => [
    {
      field: 'assignee',
      header: 'Assignee',
      display: (_: any, row: Entity) =>
        row.person ? formatNameFromPerson(row.person) : 'N/A',
      style: { 'flex-wrap': 'wrap', width: '20rem' },
    },
    {
      field: 'member',
      header: 'Member',
      displayData: (row: Entity) => returnSubtaskStatsPerStaff(row.entityId),
      style: { width: '10%' },
    },
    ...(!props.isEnrollmentSpecialist
      ? [
          {
            field: 'pod',
            header: 'Pod',
            displayData: (row: Entity) =>
              returnSubtaskStatsPerStaff(row.entityId),
            style: { width: '10%' },
          },
        ]
      : []),
    {
      field: 'acuity',
      header: 'Tier',
      style: { width: '10%' },
    },
    {
      field: 'language',
      header: 'Language',
      style: { width: '10%' },
    },
    {
      field: 'timing',
      header: 'Timing',
      displayData: (row: Entity) => returnSubtaskStatsPerStaff(row.entityId),
      style: { width: '15%' },
      truncateLength: 1000,
    },
    {
      field: 'tasks',
      header: showEnrollmentQueueEnhancements.value ? 'Subtasks' : 'Tasks',
      displayData: (row: Entity) => returnSubtaskStatsPerStaff(row.entityId),
      style: { width: '38%' },
    },
  ])

  const allPreDataLoaded = computed(() => {
    if (props.isEnrollmentSpecialist) {
      return (
        !!activeUser.value?.entityId &&
        !!carePods.value?.length &&
        !!careTeamChoices.value?.length
      )
    }
    return (
      !!activeUser.value?.entityId &&
      !!carePods.value?.length &&
      !!careTeamChoices.value?.length &&
      !!roles.value?.length
    )
  })

  const allCarePods = computed(() =>
    carePods.value
      ? carePods.value
          .map(({ carePodId, name }) => ({
            value: carePodId,
            label: name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : []
  )

  const allCarePodIds = computed(() => allCarePods.value.map((cp) => cp.value))

  const userCarePodIds = computed(() => {
    const myId = activeUser.value?.entityId
    if (allPreDataLoaded.value && carePods.value) {
      const userCarePods = carePods.value
        .filter(({ podMembers }: CarePod) =>
          podMembers?.find((memberId: string) => memberId === myId)
        )
        .map((carePod: CarePod) => carePod.carePodId)

      if (userCarePods.length) {
        return userCarePods
      }
    }
    return allCarePodIds.value
  })

  const allCarePodRoles = computed(() =>
    staffRoles
      .filter(
        (entityRole) =>
          entityRole !== EntityRole.CLINICAL__ENROLLMENT_SPECIALIST
      )
      .map(
        (entityRole) =>
          ({
            value: entityRole,
            label: humanizeRole(entityRole),
          } as DropdownKV)
      )
      .sort(sortByLabel)
  )

  const allStaff = computed(() =>
    staff.value
      ? Object.values(staff.value)
          .map((staffMember) => {
            return {
              value: staffMember.entityId,
              label: formatNameFromEntity(staffMember),
            } as DropdownKV
          })
          .sort(sortByLabel)
      : []
  )

  const carePodStaff = computed(() => {
    if (carePods.value?.length) {
      const allCarePodStaff = carePods.value
        .map(({ staff }: CarePod) => staff)
        .flat()
      const userCarePod = allCarePodStaff.filter(
        (carePodStaff: CarePodStaff | undefined) =>
          carePodStaff?.staffEntityId === activeUser.value?.entityId
      )
      return allCarePodStaff.filter(
        (carePodStaff: CarePodStaff | undefined) =>
          carePodStaff?.carePodId === userCarePod[0]?.carePodId
      )
    }
    return []
  })

  /**
   *
   * @param entityIds
   */
  async function fetchAppropriateStaffEntities(
    entityIds: (string | undefined)[] | null = null
  ) {
    await useAdminEntityApi().listAll({
      params: {
        ...(entityIds?.length ? { filter_entity_ids: entityIds } : {}),
        filter_roles: props.isEnrollmentSpecialist
          ? EntityRole.CLINICAL__ENROLLMENT_SPECIALIST
          : EntityRole.STAFF,
        parts: 'person',
      },
    })
  }

  const params = computed(() => ({
    parts: ['person', 'roles'],
    ...(props.isEnrollmentSpecialist
      ? {
          filter_roles: EntityRole.CLINICAL__ENROLLMENT_SPECIALIST,
        }
      : {}),
    ...{
      filter_excluded_program_status:
        props.isEnrollmentSpecialist && showHumanaDnc.value
          ? ['enrollment__do_not_call']
          : {},
    },
  }))

  /**
   * Function to refetch filter values from local storage
   * whenever filters are updated
   */
  function refetchFilter() {
    currentFilters.value = JSON.parse(
      localStorage.getItem(`dt-StaffQueue-store`) ?? '{}'
    ).filters
    if (currentFilters.value?.showPodless === true) {
      currentFilters.value.carePodIds.value = []
    }
  }
  window.addEventListener('refetch-filter-values', refetchFilter)

  const currentFilters = ref(
    JSON.parse(localStorage.getItem(`dt-StaffQueue-store`) ?? '{}').filters
  )
  watch(
    () => currentFilters.value?.showPodless,
    (newValue) => {
      if (newValue.value === true) {
        currentFilters.value.carePodIds.value = []
      }
    }
  )

  /**
   *
   * Function that fetches subtask staff stats
   * @param staff list of Entity objects belonging to Thyme Care staff
   */
  const getStaffStats = async (staff: Entity[]) => {
    const staffIds = staff.map((s: Entity) => s.entityId)
    await useSubtaskStaffStatsApi().listAll({
      params: {
        page_length: MAX_PAGE_SIZE,
        filter_staff_ids: staffIds,
        filter_subtask_status: openAssignedSubtaskStatuses,
      },
    })
  }

  const getAssigneeFilterOptions = async () => {
    await fetchAppropriateStaffEntities()
    if (useAdminEntityApi().data) {
      return useAdminEntityApi()
        .data?.map(({ person, entityId }: Entity) => ({
          value: entityId,
          label: formatNameFromPerson(person ?? ({} as Person)),
        }))
        .sort(sortByLabel)
    } else {
      return allStaff.value
    }
  }

  const getAssigneeFilter = () => ({
    value: null,
    matchMode: undefined,
    modalOptions: {
      label: 'Assignee',
      type: FilterType.Multiselect,
      optionsFn: getAssigneeFilterOptions,
    },
  })

  const getRolesFilter = () => ({
    value: null,
    matchMode: undefined,
    modalOptions: {
      label: 'Roles',
      type: FilterType.Multiselect,
      optionsFn: () => allCarePodRoles.value,
    },
  })
  const getShowPodlessFilter = () => ({
    value: null,
    matchMode: undefined,
    modalOptions: {
      label: 'Show Podless Only',
      type: FilterType.Select,
      options: [{ value: 'true', label: 'True' }],
    },
  })
  const isShowPodless = () => currentFilters.value?.showPodless?.value === true
  const getCarePodIdsFilter = () => ({
    value: null,
    matchMode: undefined,
    modalOptions: {
      label: 'Care Team Member Pods',
      type: FilterType.Multiselect,
      search: true,
      optionsFn: () => allCarePods.value,
      disabledOptionsFn: () => (isShowPodless() ? allCarePodIds.value : []),
      preEnabledOptionsFn: () => (isShowPodless() ? [] : userCarePodIds.value),
    },
  })

  const getAllFilters = (): FilterMetaType =>
    ({
      entityIds: getAssigneeFilter(),
      ...(!props.isEnrollmentSpecialist
        ? {
            roles: getRolesFilter(),
            carePodIds: getCarePodIdsFilter(),
            showPodless: getShowPodlessFilter(),
          }
        : {}),
    } as FilterMetaType)

  const filters = computed(() => getAllFilters())
  const table = ref<{ getData: () => object } | null>(null)

  onBeforeMount(async () => {
    if (!carePods.value && !props.isEnrollmentSpecialist) {
      await useAdminCarePodApi().listAll({
        params: {
          parts: 'staff',
          filter_status: CarePodStatus.ACTIVE,
        },
      })
    }
  })

  return {
    useStacksIncident121225,
    allCarePods,
    allPreDataLoaded,
    userCarePodIds,
    params,
    getStaffStats,
    createMyQueueApi,
    carePodStaff,
    currentFilters,
    staffRoles,
    filters,
    useStaffQueueApi,
    notification,
    columns,
    table,
  }
}
