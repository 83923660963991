import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { ExtractPropTypes, ref } from 'vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { editSocialOrOffboardingSummaryProps } from '../types'
import { createOffboardingSummary, updateOffboardingSummary } from './queries'
import {
  DirtyOffboardingSummary,
  EditOffboardingSummaryForm,
  reasonOffboardingOptions,
  schema,
} from './types'

type PropsType = ExtractPropTypes<typeof editSocialOrOffboardingSummaryProps>

/**
 *
 * @param props
 * @param context
 */
export default function (props: PropsType, context: any) {
  const dirtyModel = ref<DirtyOffboardingSummary>({
    reengagementAttemptsDescription:
      props.patientSocialSummary?.reengagementAttemptsDescription ?? '',
    reasonOffboarding: props.patientSocialSummary?.reasonOffboarding ?? '',
    offboardingAdditionalDetails:
      props.patientSocialSummary?.offboardingAdditionalDetails ?? '',
  })

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param values
   */
  function setOffboardingSummaryPayload(values: EditOffboardingSummaryForm) {
    return {
      reengagementAttemptsDescription: values.reengagementAttemptsDescription
        ?.length
        ? values.reengagementAttemptsDescription
        : null,
      reasonOffboarding: values.reasonOffboarding?.length
        ? values.reasonOffboarding
        : null,
      offboardingAdditionalDetails: values.offboardingAdditionalDetails?.length
        ? values.offboardingAdditionalDetails
        : null,
      patientId: props.patientId,
    } as EditOffboardingSummaryForm
  }

  const { handleSubmit } = useForm({
    initialValues: dirtyModel.value,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   */
  async function saveOffboardingSummary(values: EditOffboardingSummaryForm) {
    const payload = setOffboardingSummaryPayload(values)
    if (!props.patientSocialSummary) {
      await createOffboardingSummary(payload)
    } else {
      await updateOffboardingSummary(
        payload,
        props.patientSocialSummary.socialSummaryId
      )
    }
    context.emit('refetchSocialSummary')
    thymeDispatch('care-planV2-update')
    close()
  }

  const onSubmit = handleSubmit(saveOffboardingSummary)

  return {
    saveOffboardingSummary,
    close,
    onSubmit,
    ModalSize,
    dirtyModel,
    reasonOffboardingOptions,
  }
}
