<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Offboarding Summary"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5 overflow-scroll">
      <div class="flex flex-col space-y-3">
        <TMDropdownField
          label="Offboarding Reason"
          class="w-full my-2"
          name="reasonOffboarding"
          :options="reasonOffboardingOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.reasonOffboarding ?? undefined"
        />
        <TTextarea
          name="reengagementAttemptsDescription"
          label="Reengagement Attempts"
          :model-value="dirtyModel.reengagementAttemptsDescription ?? undefined"
        />
        <TTextarea
          name="offboardingAdditionalDetails"
          label="Additional Details"
          :model-value="dirtyModel.offboardingAdditionalDetails ?? undefined"
        />
      </div>
    </div>

    <template #actions>
      <div class="w-full flex flex-row justify-end space-x-4">
        <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
        <TMPrimaryButton label="Save" name="save" @click="onSubmit" />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { editSocialOrOffboardingSummaryProps } from '@/pages/PatientProfile/CarePlans/SocialSummary/types'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TTextarea,
    TMDropdownField,
  },
  props: editSocialOrOffboardingSummaryProps,
  emits: ['close', 'refetchSocialSummary'],
  setup,
})
</script>
