<template>
  <TDropdown
    :initial-value="initialValue"
    :multiple="multiple"
    :option-label="optionLabel"
    :option-value="optionValue"
    :placeholder="label"
    :input-class="inputClass"
    :show-clear="false"
    v-bind="$attrs"
    :model-value="initialValue"
    :pt="{
      trigger: { class: 'hidden' },
    }"
    @update:model-value="$emit('update:modelValue', $event.value)"
    @click="$emit('click', $event)"
  >
    <template #value="slotProps">
      <span class="flex items-center">
        <TIcon
          v-if="icon"
          :icon="icon"
          :color="iconFillType"
          :icon-pos="iconPos"
          :icon-classes="['mr-1']"
          class="mr-1"
          @click="onIconClick"
        />
        <span class="status-text">
          {{ getDisplayText(slotProps.value) }}
        </span>
        <TBadge
          v-if="badgeValue(slotProps.value)"
          :value="badgeValue(slotProps.value)"
        />
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center space-x-1">
        <TIcon
          v-if="slotProps.option.icon"
          :icon="slotProps.option.icon"
          :color="slotProps.option.fillType"
          class="mr-1"
        />
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>
  </TDropdown>
</template>

<script lang="ts">
import TBadge from '@nashville/badge/TBadge.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { iconProp, iconPositionProp } from '@thyme/nashville/src/types/icons'
import { defineComponent } from 'vue'
import TDropdown from './TDropdown.vue'

export default defineComponent({
  name: 'TMActionDropdown',
  components: {
    TDropdown,
    TIcon,
    TBadge,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: '',
    },
    initialValue: {
      type: [String, Number, Array, Object],
      default: null,
    },
    inputClass: {
      type: String,
      default: '',
    },
    iconClickable: {
      type: Boolean,
      default: false,
    },
    iconFillType: {
      type: String,
      default: null,
    },
    ...iconProp,
    ...iconPositionProp,
  },
  emits: ['click', 'iconClick', 'update:modelValue'],
  setup(props, context) {
    const badgeValue = (data: any) => {
      if (props.multiple) {
        return data?.length ? data.length.toString() : ''
      }
      return ''
    }

    const getDisplayText = (data: any) => {
      if (props.label) {
        return props.label
      }

      if (!data) {
        return ''
      }

      if (typeof data === 'string') {
        return data
      }

      if (typeof data === 'object') {
        // Handle array case for multiple select
        if (Array.isArray(data)) {
          return data.map((item) => item[props.optionLabel]).join(', ')
        }

        return data[props.optionLabel] || ''
      }

      return ''
    }

    const onIconClick = (e: Event) => {
      if (props.iconClickable) {
        context.emit('iconClick', e)
        e.stopPropagation()
      }
    }

    return {
      badgeValue,
      getDisplayText,
      onIconClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.p-multiselect.p-inputwrapper-filled,
.p-dropdown.p-inputwrapper-filled {
  @apply bg-nash-neutral000 text-nash-neutral800;
}
</style>
