import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { useFieldArray, useForm } from 'vee-validate'
import { computed, ExtractPropTypes, ref } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { createRadiations, updateRadiation } from './queries'
import {
  AddOrEditRadiationForm,
  addOrEditRadiationProps,
  AddOrEditRadiationsForm,
  listRadiationSchemas,
} from './types'

type PropsType = ExtractPropTypes<typeof addOrEditRadiationProps>

/**
 * Set up the AddOrEditRadiation component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const modalTitle = computed(
    () => `${props.showAdd ? 'Add' : 'Edit'} Radiation`
  )
  const defaultForm = () => ({
    radiationDate: null,
    cancerDiagnosisId: props.cancerDiagnosisId,
    additionalInformation: null,
    removed: false,
  })

  const radiationPayloads = ref<AddOrEditRadiationForm[]>([
    // @ts-ignore
    props.showAdd ? defaultForm() : props.initialData,
  ])

  const { handleSubmit } = useForm({
    initialValues: { radiationPayload: radiationPayloads.value },
    validationSchema: toTypedSchema(listRadiationSchemas),
  })
  const { fields, push, update } =
    useFieldArray<AddOrEditRadiationForm>('radiationPayload')

  /**
   * add another radiation payload
   */
  function addRadiationForm() {
    // @ts-ignore
    push(defaultForm())
  }

  /**
   *
   * @param index
   * using remove will mess with the link between each element
   * and it's associated index
   * band-aid solution is to mark payload
   * to be removed and delete it before making api call
   */
  function removeRadiationForm(index: number) {
    update(index, { removed: true })
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param radiationPayloads
   * @param isEdit
   */
  function cleanupRadiationPayloads(
    radiationPayloads: AddOrEditRadiationsForm,
    isEdit = false
  ) {
    const radiationPayloadsCopy = { ...radiationPayloads }

    // remove payloads marked to be removed
    radiationPayloadsCopy.radiationPayload =
      radiationPayloadsCopy.radiationPayload.filter(
        (radiation) => !radiation.removed
      )

    // then update the payload values
    radiationPayloadsCopy.radiationPayload.forEach((radiation) => {
      delete radiation.removed

      if (isEdit) {
        delete radiation.cancerDiagnosisId
      }
    })

    return radiationPayloadsCopy
  }

  /**
   *
   * @param values
   */
  async function editRadiation(values: AddOrEditRadiationsForm) {
    // cleanup payload
    const cleanedRadiationPayloads = cleanupRadiationPayloads(values, true)

    try {
      // make update call
      await updateRadiation(
        props.radiationId,
        cleanedRadiationPayloads.radiationPayload[0]
      )
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update radiation.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  /**
   *
   * @param values
   */
  async function addRadiation(values: AddOrEditRadiationsForm) {
    const cleanedRadiationPayloads = cleanupRadiationPayloads(values)
    try {
      // make create call
      await createRadiations(cleanedRadiationPayloads.radiationPayload)
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to add radiations.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  const onAddSubmit = handleSubmit(addRadiation)
  const onEditSubmit = handleSubmit(editRadiation)
  return {
    ModalSize,
    modalTitle,
    radiationPayloads,
    removeRadiationForm,
    addRadiationForm,
    close,
    fields,
    // onSubmit,
    onAddSubmit,
    onEditSubmit,
  }
}
