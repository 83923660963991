import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { startCase } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { isSubtaskOverdue } from '@/legacy/components/patient/pathways/lib/subtask'
import {
  getSubtasksFromEnrollmentQueueStacks,
  enrollmentTableFilters,
  freeTextOptions,
  segmentColumn,
  callHistoryColumn,
} from '@/legacy/components/queue/lib/enrollmentQueueParts'
import {
  getPreferredContactTimes,
  getScheduledCalls,
  patientColumn,
  languageColumn,
  getTimingColumn,
  getSubtasksColumn,
  assignAllColumn,
  SELECTED_SUBTASK_LIMIT,
} from '@/legacy/components/queue/lib/sharedQueueParts'
import {
  callAttemptsFilter,
  enrollmentSegmentFilter,
  setupContractingEntityIdsFilter,
  showHiddenSubtasksFilter,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { buildCommunicationUrl } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePayerApi } from '@/legacy/store/modules/payers'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  useUnassignedSubtaskStacksApi,
  useSubtasksStore,
  useUnassignedEnrollmentQueueApiWithScheduledCalls,
} from '@/legacy/store/modules/subtasks'
import {
  Subtask,
  SubtaskStatus,
  SubtaskRowDisplayType,
  SubtaskPriority,
} from '@/legacy/types/pathways/subtasks'
import { Insurance } from '@/legacy/types/patients/insurances'
import { Stack, StackData } from '@/legacy/types/reminders'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const table = ref<{ getData: () => object } | null>(null)
  const getData = () => table.value?.getData()
  const { selfEntity: activeUser } = storeToRefs(useProfileStore())
  const { data: payers } = storeToRefs(usePayerApi())
  useContractingEntities()

  const showBulkEditModal = ref(false)
  const selectedSubtaskPatients = ref<Array<Stack>>([])
  const { data: subtasks } = storeToRefs(useUnassignedSubtaskStacksApi())
  const {
    sortEnrollmentQueueByToc,
    showEnrollmentQueueEnhancements,
    showHumanaDnc,
  } = storeToRefs(useFlagStore())

  // return all subtasks associated with each selected memberId
  // using useUnassignedStacksApi data and flatten into single array
  const selectedSubtasks = computed(() => {
    const selected: Subtask[][] = []
    const memberEntityIds = selectedSubtaskPatients.value.map(
      (stack: Stack) => stack.patient.entityId
    )
    memberEntityIds.forEach((memberId: string) => {
      if (subtasks.value && subtasks.value[memberId]) {
        selected.push(subtasks.value[memberId])
      }
    })
    return selected.flat()
  })

  const onSubtaskStatusChanged = () => {
    void getData()
    context.emit('subtaskStatusChanged')
  }

  const getSubtaskAndCommDetailsForPatientStack = async (stacks: Stack[]) => {
    await Promise.all([
      getPreferredContactTimes(stacks),
      getSubtasksFromEnrollmentQueueStacks(stacks, true),
      getScheduledCalls(stacks, true),
    ])
  }

  const params = computed(() => ({
    filter_subtask_status: [SubtaskStatus.OPEN_UNASSIGNED],
    ff_order_by_recent_toc: sortEnrollmentQueueByToc.value,
    ...{
      filter_due_before: showEnrollmentQueueEnhancements.value
        ? DateTime.now().plus({ days: 1 }).toISO()
        : {},
    },
    ...{
      filter_excluded_program_status: showHumanaDnc.value
        ? ['enrollment__do_not_call']
        : {},
    },
  }))

  /**
   * Function to bulk assign subtasks to current user
   * @param stackData
   */
  async function assignToMe(stackData: StackData) {
    const promises: Promise<void>[] = []

    stackData?.subtasks?.forEach((subtask) => {
      promises.push(
        useSubtasksStore().updateSubtask(subtask.subtaskId, {
          responsibleStaffId: activeUser.value?.entityId,
          status: SubtaskStatus.OPEN_ASSIGNED,
          etag: subtask.updatedAt,
        })
      )
    })

    if (stackData?.scheduled_call) {
      promises.push(
        useCommunicationsStore().updateCommunication(
          stackData.scheduled_call.communicationId,
          {
            responsibleStaffId: activeUser.value?.entityId,
          }
        )
      )
    }

    await Promise.all(promises)

    context.emit('assign')
    void table.value?.getData()
  }

  const displayPayerForInsurance = (insurance: Insurance) => {
    const payer = safeLookup(insurance.payerId, payers.value)
    return payer?.payerName
  }

  const payerColumn: ColumnOptions = {
    header: 'Payer(s)',
    display: (_: any, row: Stack) => {
      const insurances = row.patient?.insurances
      const payerNames = insurances?.map(displayPayerForInsurance)
      return payerNames?.join(', ') ?? ''
    },
    style: { 'flex-wrap': 'wrap', width: '12%' },
  }

  const bulkAssignColumn = assignAllColumn
  bulkAssignColumn.header = showEnrollmentQueueEnhancements.value
    ? ''
    : 'Assign All'

  const columns = computed<ColumnOptions[]>(() => [
    patientColumn,
    languageColumn,
    ...(showEnrollmentQueueEnhancements.value
      ? [segmentColumn]
      : [payerColumn]),
    getTimingColumn(false),
    ...(showEnrollmentQueueEnhancements.value ? [callHistoryColumn] : []),
    getSubtasksColumn(true),
    ...(!props.hasBulkEdit ? [bulkAssignColumn] : []),
  ])

  const getFilters = () => ({
    ...enrollmentTableFilters(),
    contractingEntityIds: setupContractingEntityIdsFilter(),
    ...(showEnrollmentQueueEnhancements.value
      ? {
          includeHiddenSubtasks: showHiddenSubtasksFilter,
          callAttempts: callAttemptsFilter,
          enrollmentSegment: enrollmentSegmentFilter,
        }
      : {}),
  })

  const filters = computed(() => getFilters())

  /**
   * Set selected subtasks
   * @param updated
   */
  function updateSelectedItems(updated: Stack[]) {
    selectedSubtaskPatients.value = updated
  }

  /**
   * Show/hide bulk edit modal
   */
  function toggleBulkEditModal() {
    if (
      !showBulkEditModal.value &&
      selectedSubtaskPatients.value.length > SELECTED_SUBTASK_LIMIT
    ) {
      window.confirm(
        `There are ${selectedSubtaskPatients.value.length} subtasks selected. Please select no more than ${SELECTED_SUBTASK_LIMIT} subtasks.`
      )
    } else {
      showBulkEditModal.value = !showBulkEditModal.value
    }
  }

  /**
   * Clear/reset selected subtasks
   */
  function resetSelectedSubtasks() {
    selectedSubtaskPatients.value = []
  }

  return {
    selectedSubtasks,
    showBulkEditModal,
    selectedSubtaskPatients,
    resetSelectedSubtasks,
    toggleBulkEditModal,
    updateSelectedItems,
    getData,
    filters,
    freeTextOptions,
    SubtaskRowDisplayType,
    isSubtaskOverdue,
    table,
    columns,
    params,
    assignToMe,
    startCase,
    SubtaskPriority,
    getSubtaskAndCommDetailsForPatientStack,
    onSubtaskStatusChanged,
    useUnassignedEnrollmentQueueApiWithScheduledCalls,
    buildCommunicationUrl,
  }
}
