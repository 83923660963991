<template>
  <div>
    <TMSecondaryButton
      icon="bookOpen"
      class="border rounded-md w-9 h-10 p-9 ml-3 p-4"
      @click="open"
    />

    <TModal
      :is-visible="showManagePathways"
      :size="ModalSize.LG"
      title="Manage Playbooks"
      @close="close"
    >
      <div class="flex justify-between">
        <div class="flex">
          <TTab
            :tabs="statuses"
            :active="selectedStatus"
            :item-counts="pathwayCounts"
            @select="selectStatus"
          />
        </div>
        <div
          v-if="pathwaysLimitReached"
          v-tooltip.left="
            'You have reached the maximum number of active Playbooks for this member.'
          "
        >
          <TMPrimaryButton label="+ Add" :disabled="true" />
        </div>
        <TMPrimaryButton v-else label="+ Add" @click="openAddModal" />
      </div>
      <div v-if="!!pathwaysByStatus.length">
        <div v-for="pathway in pathwaysByStatus" :key="pathway.pathwayId">
          <PatientTreatmentPlans
            :pathway="pathway"
            :allow-status-update="true"
          />
        </div>
      </div>
      <div v-else class="text-neutral-600">
        There are no {{ selectedStatusLabel.toLowerCase() }} Playbooks
      </div>
    </TModal>
    <AddPathwaysModal
      :num-allowed-pathways="MAX_PATHWAYS - currentActivePathways.length"
      :exclude-variant-ids="incompletePathwayVariantIds"
      :is-visible="addModalVisible"
      @close="close"
      @back="returnToManage"
      @cancel="returnToManage"
      @save="save"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import { computed, defineComponent, PropType, ref } from 'vue'
import AddPathwaysModal from '@/legacy/components/patient/pathways/AddPathwaysModal.vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import TModal from '@/legacy/nashville/TModal.vue'
import {
  usePathwaysStore,
  isEvergreenPathway,
} from '@/legacy/store/modules/pathways'
import {
  Pathway,
  MAX_PATHWAYS,
  closedPathwayStatuses,
  pathwayStatusLabels,
  PathwayStatus,
} from '@/legacy/types/pathways/pathways'

export default defineComponent({
  components: {
    TMSecondaryButton,
    TMPrimaryButton,
    TModal,
    AddPathwaysModal,
    TTab,
    PatientTreatmentPlans,
  },
  props: {
    pathways: {
      type: Array as PropType<Pathway[]>,
      required: true,
    },
  },
  setup(props) {
    const hiddenPathwayStatuses = computed(() => {
      return [PathwayStatus.BLOCKED]
    })
    const showManagePathways = ref(false)
    const statuses = ref(
      pathwayStatusLabels.filter(
        (status) => !hiddenPathwayStatuses.value.includes(status.value)
      )
    )
    const selectedStatus = ref(statuses.value[0].value)
    const selectedStatusLabel = ref(statuses.value[0].label)

    const addModalVisible = ref(false)

    const sortedPathways = computed(() => {
      return props.pathways
        .slice()
        .sort((a: Pathway, b: Pathway) =>
          isEvergreenPathway(a.title) ? 1 : isEvergreenPathway(b.title) ? -1 : 0
        )
    })

    const pathwayCounts = computed(() => {
      const counter: any = {}
      statuses.value.forEach((status) => {
        counter[status.value] = sortedPathways.value.filter(
          (pathway: Pathway) => pathway.status === status.value
        ).length
      })
      return counter
    })

    const pathwaysByStatus = computed(() =>
      sortedPathways.value.filter(
        (pathway: Pathway) => pathway.status === selectedStatus.value
      )
    )

    const currentActivePathways = computed(() =>
      props.pathways.filter(
        (pathway: Pathway) => pathway.status === PathwayStatus.ACTIVE
      )
    )

    const incompletePathwayVariantIds = computed(() =>
      props.pathways
        .filter(
          (pathway: Pathway) =>
            !closedPathwayStatuses.includes(pathway.status as PathwayStatus)
        )
        .map((pathway) => pathway.pathwayVariantId)
    )

    const pathwaysLimitReached = computed(
      () =>
        props.pathways.filter(
          (pathway: Pathway) => pathway.status === PathwayStatus.ACTIVE
        ).length >= MAX_PATHWAYS
    )

    const selectStatus = (v: Tab) => (
      (selectedStatus.value = v.value as PathwayStatus),
      (selectedStatusLabel.value = v.label)
    )
    const open = () => (showManagePathways.value = true)
    const close = () => {
      addModalVisible.value = false
      showManagePathways.value = false
    }

    const openAddModal = () => {
      addModalVisible.value = true
      showManagePathways.value = false
    }

    const returnToManage = () => {
      addModalVisible.value = false
      showManagePathways.value = true
    }

    const save = async (pathwayTemplateIds: string[]) => {
      await usePathwaysStore().createPathways(pathwayTemplateIds)
      thymeDispatch('thymeline-update')
      close()
    }

    return {
      ModalSize,
      pathwayCounts,
      selectedStatusLabel,
      save,
      currentActivePathways,
      addModalVisible,
      pathwaysByStatus,
      pathwaysLimitReached,
      selectedStatus,
      showManagePathways,
      statuses,
      selectStatus,
      close,
      open,
      openAddModal,
      returnToManage,
      sortedPathways,
      MAX_PATHWAYS,
      incompletePathwayVariantIds,
    }
  },
})
</script>
