import { requiredInject } from '@thyme/libs/src/vue/inject'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { getLabels } from '../shared/utils'
import { getSocialSummary } from './queries'
import { useSocialSummariesApi } from './store'
import {
  BenefitsProgramsServices,
  CaregiverMapping,
  FinancialBarrier,
  FoodBarrier,
  HousingBarrier,
  InHomeSupport,
  SocialSupport,
  TransportationBarrier,
  benefitsProgramsServicesOptions,
  caregiverMappingOptions,
  financialBarrierOptions,
  foodBarrierOptions,
  housingBarrierOptions,
  inHomeSupportOptions,
  socialSupportOptions,
  transportationBarrierOptions,
} from './types'

/**
 *
 */
export default function () {
  const patientId = requiredInject(PATIENT_ID_KEY)

  const { data: socialSummary } = storeToRefs(useSocialSummariesApi())

  const showEditModal = ref<boolean>(false)
  const showOffBoardingSummaryEdit = ref<boolean>(false)

  const patientSocialSummary = computed(() =>
    socialSummary.value?.length ? socialSummary.value[0] : null
  )

  const hasOffboardingSummary = computed(() => {
    return (
      patientSocialSummary.value?.reasonOffboarding?.length ??
      patientSocialSummary.value?.reengagementAttemptsDescription?.length
    )
  })

  /**
   * fetch last updated values in care plan section
   */
  async function fetchSocialSummary() {
    await getSocialSummary(patientId.value)
  }

  onMounted(fetchSocialSummary)

  const benefitsProgramsServicesDisplay = computed(() => {
    if (patientSocialSummary.value?.benefitsProgramsServices?.length) {
      return patientSocialSummary.value.benefitsProgramsServices
        .map((str: string) =>
          getLabels(
            str,
            benefitsProgramsServicesOptions,
            BenefitsProgramsServices
          )
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })
  const caregiverMappingDisplay = computed(() => {
    if (patientSocialSummary.value?.caregiverMapping?.length) {
      return patientSocialSummary.value.caregiverMapping
        .map((str: string) =>
          getLabels(str, caregiverMappingOptions, CaregiverMapping)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })
  const financialBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.financialBarrier?.length) {
      return patientSocialSummary.value.financialBarrier
        .map((str: string) =>
          getLabels(str, financialBarrierOptions, FinancialBarrier)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const foodBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.foodBarrier?.length) {
      return patientSocialSummary.value.foodBarrier
        .map((str: string) => getLabels(str, foodBarrierOptions, FoodBarrier))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const isHealthcareProxyOrPOA = computed(() => {
    if (patientSocialSummary.value?.healthcareProxy === true) {
      return 'Yes'
    } else if (patientSocialSummary.value?.healthcareProxy === false) {
      return 'No'
    }
    return '-'
  })

  const housingBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.housingBarrier?.length) {
      return patientSocialSummary.value.housingBarrier
        .map((str: string) =>
          getLabels(str, housingBarrierOptions, HousingBarrier)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const inHomeSupportDisplay = computed(() => {
    if (patientSocialSummary.value?.inHomeSupport?.length) {
      return patientSocialSummary.value.inHomeSupport
        .map((str: string) =>
          getLabels(str, inHomeSupportOptions, InHomeSupport)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const socialSupportDisplay = computed(() => {
    if (patientSocialSummary.value?.socialSupport?.length) {
      return patientSocialSummary.value.socialSupport
        .map((str: string) =>
          getLabels(str, socialSupportOptions, SocialSupport)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const transportationBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.transportationBarrier?.length) {
      return patientSocialSummary.value.transportationBarrier
        .map((str: string) =>
          getLabels(str, transportationBarrierOptions, TransportationBarrier)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const additionalContextDisplay = computed(() => {
    return patientSocialSummary.value?.additionalContext ?? null
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(patientSocialSummary.value)
    return lastUpdatedAtStr ?? '-'
  })

  /**
   * open/close edit modal
   */
  function toggleSocialSummaryEdit() {
    showEditModal.value = !showEditModal.value
  }
  /**
   * open/close edit modal
   */
  function toggleOffboardingSummaryEdit() {
    showOffBoardingSummaryEdit.value = !showOffBoardingSummaryEdit.value
  }

  return {
    fetchSocialSummary,
    patientId,
    patientSocialSummary,
    hasOffboardingSummary,
    // display values
    lastUpdatedDisplay,
    benefitsProgramsServicesDisplay,
    caregiverMappingDisplay,
    financialBarrierDisplay,
    foodBarrierDisplay,
    isHealthcareProxyOrPOA,
    housingBarrierDisplay,
    inHomeSupportDisplay,
    socialSupportDisplay,
    transportationBarrierDisplay,
    additionalContextDisplay,
    // social summary edit modal
    toggleSocialSummaryEdit,
    showEditModal,
    // offboarding summary
    showOffBoardingSummaryEdit,
    toggleOffboardingSummaryEdit,
  }
}
