<template>
  <TabMenu
    :model="items"
    :active-index="currentTabIndex"
    @tab-change="tabChange"
  >
    <template #item="{ label, item, props }">
      <router-link
        v-if="item.route"
        v-slot="routerProps"
        :to="item.route"
        custom
      >
        <a
          :href="item.route.path"
          v-bind="props.action"
          @click="routerProps.navigate"
        >
          <span v-bind="props.label">{{ label }}</span>
          <TBadge
            v-if="item.badge"
            class="ml-1"
            name="tab-menu-badge"
            :value="item.badge"
          />
        </a>
      </router-link>
      <span v-else v-bind="props.action">
        <span v-bind="props.label">{{ label }}</span>
        <TBadge
          v-if="item.badge"
          class="ml-1"
          name="tab-menu-badge"
          :value="item.badge"
        />
      </span>
    </template>
  </TabMenu>
</template>

<script lang="ts">
import TBadge from '@thyme/nashville/src/components/badge/TBadge.vue'
import { MenuItem } from '@thyme/nashville/src/types/navbar'
import TabMenu, { TabMenuChangeEvent } from 'primevue/tabmenu'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  components: {
    TBadge,
    TabMenu,
  },
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    currentTabIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ['tabChange'],
  setup(props, context) {
    /**
     *
     * @param tab
     */
    function tabChange(tab: TabMenuChangeEvent) {
      context.emit('tabChange', tab)
    }
    return {
      tabChange,
    }
  },
})
</script>
