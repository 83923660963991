import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useContactsApi, usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { PhoneNumberType } from '@/legacy/types/entities/phoneNumbers'

/**
 *
 */
export default function () {
  const { phoneNumbers, contacts } = storeToRefs(usePatientStore())
  const { isLoaded: patientLoaded } = storeToRefs(usePatientsApi())
  const { isLoaded: contactsLoaded } = storeToRefs(useContactsApi())
  const { isLoaded: mapEntityPhoneNumberLoaded } = storeToRefs(
    useMapEntityPhoneNumberApi()
  )

  const hasTextablePhoneNumber = computed(() => {
    const patientHasPhoneNumbers =
      Object.values(phoneNumbers.value ?? {}).length &&
      Object.values(phoneNumbers.value ?? {}).some(
        (phoneNumber) =>
          phoneNumber.phoneNumber.type !== PhoneNumberType.LANDLINE
      )
    const contactsHavePhoneNumbers =
      Object.values(contacts.value ?? {}).length &&
      Object.values(contacts.value ?? {}).some(
        (contact) =>
          contact.contactEntity.phoneNumbers?.length &&
          contact.contactEntity.phoneNumbers[0].phoneNumber.type !==
            PhoneNumberType.LANDLINE &&
          contact.approvedToSpeak
      )
    return patientHasPhoneNumbers || contactsHavePhoneNumbers
  })

  const dataHasLoaded = computed(() => {
    return (
      patientLoaded.value &&
      contactsLoaded.value &&
      mapEntityPhoneNumberLoaded.value
    )
  })

  return {
    hasTextablePhoneNumber,
    dataHasLoaded,
  }
}
