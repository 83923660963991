import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { camelCase, startCase } from 'lodash'
import { useForm } from 'vee-validate'
import { ExtractPropTypes } from 'vue'
import { checkForEmptyArr } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { addECOGStatusLevel } from '@/pages/PatientProfile/CarePlans/shared/format'
import {
  booleanOptions,
  ADLsInability,
  DME,
  IADLsInability,
  ProceduralSupport,
  ECOGStatus,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  createPhysicalFunctionAssessment,
  updatePhysicalFunctionAssessment,
} from './queries'
import {
  EditPhysicalFunctionAssessmentForm,
  editPhysicalFunctionAssessmentProps,
  schema,
} from './types'

type PropsType = ExtractPropTypes<typeof editPhysicalFunctionAssessmentProps>

/**
 * Set up the EditPhysicalFunctionAssessment component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const ecogStatusOptions = Object.values(ECOGStatus)
    .map((val) => {
      return { value: val, label: addECOGStatusLevel(val) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const IADLsInabilityOptions = Object.values(IADLsInability)
    .map((val) => {
      if (val === IADLsInability.NOT_APPLICABLE_INDEPENDENT) {
        return { value: val, label: 'Not Applicable, Independent' }
      }
      if (val === IADLsInability.UNKNOWN_HAS_NOT_BEEN_ASSESSED) {
        return { value: val, label: 'Unknown, has not been assessed' }
      }
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const ADLsInabilityOptions = Object.values(ADLsInability)
    .map((val) => {
      if (val === ADLsInability.NOT_APPLICABLE_INDEPENDENT) {
        return { value: val, label: 'Not Applicable, Independent' }
      }
      if (val === ADLsInability.UNKNOWN_HAS_NOT_BEEN_ASSESSED) {
        return { value: val, label: 'Unknown, has not been assessed' }
      }
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const DMEOptions = Object.values(DME)
    .map((val) => {
      if (val === DME.CPAP) {
        return { value: val, label: val }
      }
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const proceduralSupportOptions = Object.values(ProceduralSupport)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const ECOGStatusOptions = Object.values(ECOGStatus)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   * update existing physical function assessment
   * or create new one if there is none
   */
  async function savePhysicalFunctionAssessment(
    values: EditPhysicalFunctionAssessmentForm
  ) {
    Object.assign(values, {
      iadlsInability: checkForEmptyArr(values.iadlsInability),
      dmeAtHome: checkForEmptyArr(values.dmeAtHome),
      adlsInability: checkForEmptyArr(values.adlsInability),
      proceduralSupport: checkForEmptyArr(values.proceduralSupport),
    })
    if (props.physicalFunctionAssessmentId) {
      await updatePhysicalFunctionAssessment(
        props.physicalFunctionAssessmentId,
        values
      )
    } else {
      await createPhysicalFunctionAssessment(props.patientId, values)
    }

    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(savePhysicalFunctionAssessment)

  return {
    close,
    ModalSize,
    // dropdown options
    booleanOptions,
    IADLsInabilityOptions,
    ECOGStatusOptions,
    proceduralSupportOptions,
    DMEOptions,
    ADLsInabilityOptions,
    ecogStatusOptions,
    // actions
    onSubmit,
  }
}
