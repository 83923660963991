<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Additional Information"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5 overflow-scroll">
      <div class="flex flex-col space-y-3">
        <TTextarea
          v-model="summaryString"
          name="offboardingAdditionalDetails"
          label="Additional Information"
        />
      </div>
    </div>

    <template #actions>
      <div class="w-full flex flex-row justify-end space-x-4">
        <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
        <TMPrimaryButton label="Save" name="save" @click="submit" />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import setup from './controller'
import { editPatientSummaryProps } from './types'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TTextarea,
  },
  props: editPatientSummaryProps,
  emits: ['close', 'submit'],
  setup,
})
</script>
