import { ColumnOptions, EditOptions } from '@thyme/nashville/src/types/tables'
import { PropType, ref } from 'vue'

export const tableEditProps = {
  editable: {
    type: Boolean,
    default: true,
  },
  editColumns: {
    type: Array as PropType<ColumnOptions[]>,
    default: () => [],
  },
  addable: {
    type: Boolean,
    default: true,
  },
  buttonText: {
    type: String,
    default: 'Save',
  },
  editOptions: {
    type: Object as PropType<EditOptions>,
    default: () => ({}),
  },
}

export const createEditRefs = (context: any) => {
  const addModalOpen = ref(false)
  const currentEditingRow = ref<any>(null)

  const editRow = (row: any) => {
    addModalOpen.value = true
    currentEditingRow.value = row
  }

  const addRow = () => {
    addModalOpen.value = true
    context.emit('addModalOpen', addModalOpen.value)
  }

  const closeAddModal = () => {
    currentEditingRow.value = null
    addModalOpen.value = false
    context.emit('addModalOpen', addModalOpen.value)
  }

  return {
    addModalOpen,
    currentEditingRow,
    editRow,
    addRow,
    closeAddModal,
  }
}

export const createDeleteRefs = () => {
  const deleteModalOpen = ref(false)
  const currentDeletingRow = ref<any>(null)

  const openDeleteModal = (row: any) => {
    deleteModalOpen.value = true
    currentDeletingRow.value = row
  }

  const closeDeleteModal = () => {
    currentDeletingRow.value = null
    deleteModalOpen.value = false
  }

  return {
    deleteModalOpen,
    currentDeletingRow,
    openDeleteModal,
    closeDeleteModal,
  }
}

export default {
  createEditRefs,
  createDeleteRefs,
}
