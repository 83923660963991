import {
  ColumnOptions,
  FilterMetaType,
  FilterType,
} from '@thyme/nashville/src/types/tables'
import { partition, startCase } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { ref, computed, onBeforeMount } from 'vue'
import { isSubtaskOverdue } from '@/legacy/components/patient/pathways/lib/subtask'
import {
  subtaskStatusChoices,
  dueDateFilters,
  setupContractingEntityIdsFilter,
  setupSpecificRoleFilter,
  memberStateMultiselectFilter,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { dateTimeToString } from '@/legacy/libs/date'
import { buildCommunicationUrl } from '@/legacy/libs/format'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useUnassignedStacksApi } from '@/legacy/store/modules/reminders'
import { useStaffStore } from '@/legacy/store/modules/staff'
import {
  useUnassignedSubtaskStacksApi,
  useSubtasksStore,
} from '@/legacy/store/modules/subtasks'
import { CarePod } from '@/legacy/types/carePods'
import { minDateTomorrow } from '@/legacy/types/global/dates'
import {
  SubtaskStatus,
  QueueStyle,
  SubtaskRowDisplayType,
  SubtaskPriority,
} from '@/legacy/types/pathways/subtasks'
import { CarePodStatus } from '@/legacy/types/patients/carePods'
import { Stack, StackData } from '@/legacy/types/reminders'
import {
  dueBeforeTomorrow,
  getFollowUpStacksData,
  maxStackDateDateTime,
  queueListParams,
  patientColumn,
  languageColumn,
  getTimingColumn,
  getSubtasksColumn,
  assignAllColumn,
  sharedFilters,
  SELECTED_SUBTASK_LIMIT,
  tierColumn,
} from '../lib/sharedQueueParts'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  useContractingEntities()
  const table = ref<{ getData: () => object } | null>(null)
  const getData = () => table.value?.getData()
  const { selfEntity: activeUser, roles } = storeToRefs(useProfileStore())
  const { data: carePods } = storeToRefs(useAdminCarePodApi())
  const { careTeamChoices } = storeToRefs(useStaffStore())
  const showBulkEditModal = ref(false)
  const selectedSubtaskPatients = ref<Array<Stack>>([])
  const { data: subtasks } = storeToRefs(useUnassignedSubtaskStacksApi())

  const unassignedTableName = computed(() =>
    props.isPodLeadQueue ? 'LeadTeamQueue' : 'TeamQueue'
  )

  // return all subtasks associated with each selected memberId
  // using useUnassignedStacksApi and flatten into single array
  const selectedSubtasks = computed(() => {
    const selected = []
    const memberEntityIds = selectedSubtaskPatients.value.map(
      (stack: Stack) => stack.patient.entityId
    )
    for (let i = 0; i < memberEntityIds.length; i++) {
      const memberId = memberEntityIds[i]
      if (subtasks.value && subtasks.value[memberId]) {
        selected.push(subtasks.value[memberId])
      }
    }
    return selected.flat()
  })

  const allPreDataLoaded = computed(
    () =>
      !!activeUser.value?.entityId &&
      !!carePods.value?.length &&
      !!careTeamChoices.value?.length &&
      !!roles.value?.length
  )

  const currentFilters = ref(
    JSON.parse(
      localStorage.getItem(`dt-${unassignedTableName.value}-store`) ?? '{}'
    ).filters
  )
  /**
   * Function to refetch filter values from local storage
   * whenever filters are updated
   */
  function refetchFilter() {
    currentFilters.value = JSON.parse(
      localStorage.getItem(`dt-${unassignedTableName.value}-store`) ?? '{}'
    ).filters
  }
  window.addEventListener('refetch-filter-values', refetchFilter)
  const currentDueAfterFilter = computed(
    () => currentFilters.value?.dueAfter?.value
  )

  const currentDueBeforeFilter = computed(
    () => currentFilters.value?.dueBefore?.value ?? dueBeforeTomorrow
  )

  const currentSubtaskStatusFilter = computed(
    () =>
      currentFilters.value?.subtaskStatus?.value ?? [
        SubtaskStatus.OPEN_UNASSIGNED,
      ]
  )
  const currentRolesFilter = computed(
    () => currentFilters.value?.roles?.value ?? []
  )

  // Exclude scheduled calls from enrollment and lead queues
  const shouldIncludeScheduledCalls = computed(() => !props.isPodLeadQueue)

  const getFollowUpDataForUnassigned = async (stacks: Stack[]) => {
    await getFollowUpStacksData(
      stacks,
      true,
      props.isPodLeadQueue,
      false, // isEnrollmentSpecialist=false
      shouldIncludeScheduledCalls.value,
      {
        ...(currentRolesFilter.value.length > 0
          ? { filter_roles: currentRolesFilter.value }
          : roles.value
          ? { filter_roles: roles.value }
          : {}),
        // set default values for filters in podLeadQueue
        // update values when filter changes
        ...(props.isPodLeadQueue
          ? { filter_subtask_status: currentSubtaskStatusFilter.value }
          : { filter_subtask_status: [SubtaskStatus.OPEN_UNASSIGNED] }),
        ...(props.isPodLeadQueue && currentDueAfterFilter.value
          ? { filter_due_after: dateTimeToString(currentDueAfterFilter.value) }
          : {}),
        ...(props.isPodLeadQueue
          ? {
              filter_due_before: dateTimeToString(currentDueBeforeFilter.value),
            }
          : {
              filter_due_before: dateTimeToString(maxStackDateDateTime),
            }),
      }
    )
  }
  const params = computed(() => ({
    ...queueListParams,
    ...{
      ...(props.isPodLeadQueue && currentDueAfterFilter.value
        ? { filter_due_after: dateTimeToString(currentDueAfterFilter.value) }
        : {}),
      filter_due_before: props.isPodLeadQueue
        ? dateTimeToString(currentDueBeforeFilter.value)
        : dateTimeToString(DateTime.fromJSDate(minDateTomorrow)),
      filter_subtask_status: props.isPodLeadQueue
        ? currentSubtaskStatusFilter.value
        : SubtaskStatus.OPEN_UNASSIGNED,
      ...(currentRolesFilter.value.length > 0
        ? { filter_roles: currentRolesFilter.value }
        : roles.value
        ? { filter_roles: roles.value }
        : {}),
      show_scheduled_calls_first: true,
      filter_queue_style: [QueueStyle.STANDARD],
    },
  }))

  /**
   * Function to bulk assign subtasks to current user
   * @param stackData
   */
  async function assignToMe(stackData: StackData) {
    const promises: Promise<void>[] = []

    stackData?.subtasks?.forEach((subtask) => {
      promises.push(
        useSubtasksStore().updateSubtask(subtask.subtaskId, {
          responsibleStaffId: activeUser.value?.entityId,
          status: SubtaskStatus.OPEN_ASSIGNED,
          etag: subtask.updatedAt,
        })
      )
    })

    if (stackData?.scheduled_call) {
      promises.push(
        useCommunicationsStore().updateCommunication(
          stackData.scheduled_call.communicationId,
          {
            responsibleStaffId: activeUser.value?.entityId,
          }
        )
      )
    }

    await Promise.all(promises)

    context.emit('assign')
    void table.value?.getData()
  }

  const columns = computed<ColumnOptions[]>(() => [
    patientColumn,
    tierColumn,
    languageColumn,
    getTimingColumn(true),
    getSubtasksColumn(true),
    ...(!props.hasBulkEdit ? [assignAllColumn] : []),
  ])

  onBeforeMount(async () => {
    if (!useAdminCarePodApi().isQueued()) {
      await useAdminCarePodApi().listAll({
        params: {
          parts: 'staff',
          filter_status: CarePodStatus.ACTIVE,
        },
      })
    }
  })

  const allCarePods = computed(() =>
    carePods.value
      ? carePods.value
          .map(({ carePodId, name }) => ({
            value: carePodId,
            label: name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : []
  )

  const allCarePodIds = computed(() => allCarePods.value.map((cp) => cp.value))

  const userCarePodIds = computed(() => {
    const myId = activeUser.value?.entityId
    if (allPreDataLoaded.value && carePods.value) {
      const userCarePods = carePods.value
        .filter(({ podMembers }: CarePod) =>
          podMembers?.find((memberId: string) => memberId === myId)
        )
        .map((carePod: CarePod) => carePod.carePodId)

      if (userCarePods.length) {
        return userCarePods
      }
    }
    return allCarePodIds.value
  })

  // This value is a partition of carepod IDs that the
  // current user is a part of vs. not a part of
  const partitionedCarePod = computed(() =>
    partition(allCarePods.value, (cp) =>
      userCarePodIds.value.find((ucp) => ucp === cp.value)
    )
  )

  const disabledCarePodIds = computed(() =>
    partitionedCarePod.value[1].map((cp) => cp.value)
  )

  const disabledCarePodsForUser = computed(() =>
    disabledCarePodIds.value.length ? disabledCarePodIds.value : []
  )

  const teamQueueFilters = computed<FilterMetaType>(() => ({
    ...sharedFilters(),
    carePodIds: {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Pods',
        type: FilterType.Multiselect,
        search: true,
        optionsFn: () => allCarePods.value,
        preEnabledOptionsFn: () => userCarePodIds.value,
        disabledOptionsFn: () =>
          props.isPodLeadQueue ? [] : disabledCarePodsForUser.value,
      },
    },
    ...(props.isPodLeadQueue
      ? {
          subtaskStatus: {
            value: null,
            matchMode: undefined,
            modalOptions: {
              label: 'Subtask Statuses',
              type: FilterType.Multiselect,
              optionsFn: () => subtaskStatusChoices,
              preEnabledOptionsFn: () =>
                subtaskStatusChoices
                  .filter((arr) => arr.value === SubtaskStatus.OPEN_UNASSIGNED)
                  .map((opts) => opts.value),
            },
          },
          ...dueDateFilters,
        }
      : {}),
    contractingEntityIds: setupContractingEntityIdsFilter(),
    roles: setupSpecificRoleFilter(),
    state: memberStateMultiselectFilter,
  }))

  /**
   * Set selected subtasks
   * @param updated
   */
  function updateSelectedItems(updated: Stack[]) {
    selectedSubtaskPatients.value = updated
  }

  /**
   * Show/hide bulk edit modal
   */
  function toggleBulkEditModal() {
    if (
      !showBulkEditModal.value &&
      selectedSubtaskPatients.value.length > SELECTED_SUBTASK_LIMIT
    ) {
      window.confirm(
        `There are ${selectedSubtaskPatients.value.length} subtasks selected. Please select no more than ${SELECTED_SUBTASK_LIMIT} subtasks.`
      )
    } else {
      showBulkEditModal.value = !showBulkEditModal.value
    }
  }

  /**
   * Clear/reset selected subtasks
   */
  function resetSelectedSubtasks() {
    selectedSubtaskPatients.value = []
  }

  return {
    unassignedTableName,
    disabledCarePodsForUser,
    userCarePodIds,
    allCarePods,
    allPreDataLoaded,
    teamQueueFilters,
    SubtaskRowDisplayType,
    isSubtaskOverdue,
    getFollowUpDataForUnassigned,
    table,
    columns,
    params,
    useUnassignedStacksApi,
    assignToMe,
    startCase,
    SubtaskPriority,
    updateSelectedItems,
    toggleBulkEditModal,
    resetSelectedSubtasks,
    showBulkEditModal,
    selectedSubtaskPatients,
    SELECTED_SUBTASK_LIMIT,
    selectedSubtasks,
    getData,
    shouldIncludeScheduledCalls,
    buildCommunicationUrl,
  }
}
