import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { computed, ref } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { formatMemberDob } from './format'
import { getHumanaEligibility } from './queries'
import { useHumanaEligibilityApi } from './store'
import {
  EligibilityCheckForm,
  HUMANA_ELIGIBILITY_RES_MAP,
  HumanaEligibilityData,
  IS_ELIGIBLE_STRING,
  IS_INELIGIBLE_STRING,
  schema,
} from './types'

/**
 *
 */
export function setup() {
  const { notification } = storeToRefs(useNotificationStore())
  const { isLoading } = storeToRefs(useHumanaEligibilityApi())

  const humanaMemberId = ref('')
  const humanaMemberDob = ref<Date | undefined>(undefined)

  const disableSubmit = computed(() => {
    return !humanaMemberId.value || !humanaMemberDob.value
  })

  const showClearButton = computed(() => {
    return !!humanaMemberId.value || !!humanaMemberDob.value
  })

  const eligibilityResponse = ref<null | HumanaEligibilityData>(null)

  /**
   * function to clear all inputs
   */
  function clearInputs() {
    humanaMemberId.value = ''
    humanaMemberDob.value = undefined
    eligibilityResponse.value = null
  }

  const { handleSubmit } = useForm({
    initialValues: {
      humanaMemberId: humanaMemberId.value,
      humanaMemberDob: humanaMemberDob.value,
    },
    validationSchema: toTypedSchema(schema),
  })

  /**
   * function to submit form for eligibility check
   * @param values
   */
  async function submit(values: EligibilityCheckForm) {
    eligibilityResponse.value = null

    const payload = {
      umid: values.humanaMemberId ?? '',
      dateOfBirth: formatMemberDob(values.humanaMemberDob),
    }

    try {
      const res = await getHumanaEligibility(payload)
      eligibilityResponse.value = res
    } catch (err) {
      useNotificationStore().setNotification({
        message: `Failed to get Humana Eligibility for ID ${values.humanaMemberId}`,
        type: NotificationType.DANGER,
      })
      return
    }
  }

  const onSubmit = handleSubmit(submit)

  const eligibilityResponseDetails = computed(() => {
    if (eligibilityResponse.value?.eligibilityFlag === 'Y') {
      return HUMANA_ELIGIBILITY_RES_MAP.ELIGIBLE
    }
    if (
      eligibilityResponse.value?.eligibilityFlag === 'N' ||
      !eligibilityResponse.value?.eligibilityFlag
    ) {
      return HUMANA_ELIGIBILITY_RES_MAP.NOT_ELIGIBILE
    }
    return null
  })

  return {
    IS_ELIGIBLE_STRING,
    IS_INELIGIBLE_STRING,
    isLoading,
    notification,
    AllowedIcons,
    // computed
    eligibilityResponse,
    eligibilityResponseDetails,
    showClearButton,
    disableSubmit,
    // refs
    humanaMemberId,
    humanaMemberDob,
    // functions
    submit,
    onSubmit,
    clearInputs,
  }
}
