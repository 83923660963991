import { requiredInject } from '@thyme/libs/src/vue/inject'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import {
  arrayToStringDisplay,
  formatStringDisplay,
} from '@/pages/PatientProfile/CarePlans/shared/utils'
import { useFallRisk } from '@/pages/PatientProfile/CarePlans/shared/variables'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditFallRiskForm } from './EditFallRisk/types'

// Defined only for convenience and use in testing.
export type FallRiskViewModel = {
  patientId: Ref<string>
  fallRiskId: Ref<string | undefined>
  fallInTheLastTwelveMonthsDisplay: Ref<string>
  unsteadyWithStandingOrWalkingDisplay: Ref<string>
  lastUpdatedDisplay: Ref<string>
  fearOfFallingDisplay: Ref<string>
  usesDMEToAssistWithAmbulationDisplay: Ref<string>
  peripheralNeuropathyDisplay: Ref<string>
  anticoagulationDisplay: Ref<string>
  sedatingMedicationsDisplay: Ref<string>
  additionalInformationDisplay: Ref<string | null>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditFallRiskForm>>
  toggleFallRiskEdit: () => void
  arrayToStringDisplay: (arr: string[]) => string
  formatStringDisplay: (str: string) => string
  refetchFallRisk: () => Promise<void>
}

/**
 *
 *
 */
export function setup(): FallRiskViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)

  const {
    fallRisk,
    fallRiskId,
    // Display values
    fallInTheLastTwelveMonthsDisplay: baseFallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay:
      baseUnsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay: baseFearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay:
      baseUsesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay: basePeripheralNeuropathyDisplay,
    anticoagulationDisplay: baseAnticoagulationDisplay,
    sedatingMedicationsDisplay: baseSedatingMedicationsDisplay,
    additionalInformationDisplay: baseAdditionalInformationDisplay,
    // Raw values for edit form
    fallInLastTwelveMonths,
    unsteadyWalkingOrStanding,
    fearOfFalling,
    usesDmeAssistForAmbulation,
    hasPeripheralNeuropathy,
    refetchFallRisk,
  } = useFallRisk(patientId)

  // Wrap the base displays to convert undefined to '-'
  const fallInTheLastTwelveMonthsDisplay = computed(
    () => baseFallInTheLastTwelveMonthsDisplay.value ?? '-'
  )

  const unsteadyWithStandingOrWalkingDisplay = computed(
    () => baseUnsteadyWithStandingOrWalkingDisplay.value ?? '-'
  )

  const fearOfFallingDisplay = computed(
    () => baseFearOfFallingDisplay.value ?? '-'
  )

  const usesDMEToAssistWithAmbulationDisplay = computed(
    () => baseUsesDMEToAssistWithAmbulationDisplay.value ?? '-'
  )

  const peripheralNeuropathyDisplay = computed(
    () => basePeripheralNeuropathyDisplay.value ?? '-'
  )

  const anticoagulationDisplay = computed(
    () => baseAnticoagulationDisplay.value ?? '-'
  )

  const sedatingMedicationsDisplay = computed(
    () => baseSedatingMedicationsDisplay.value ?? '-'
  )

  const additionalInformationDisplay = computed(
    () => baseAdditionalInformationDisplay.value ?? null
  )

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(fallRisk.value)
    return lastUpdatedAtStr ?? '-'
  })

  const editModalInitialData = computed(() => ({
    fallInLastTwelveMonthsEnum: fallInLastTwelveMonths.value,
    fearOfFallingEnum: fearOfFalling.value,
    hasPeripheralNeuropathy: hasPeripheralNeuropathy.value,
    sedatingMedications: fallRisk.value?.sedatingMedications,
    otherSedatingMedications: fallRisk.value?.otherSedatingMedications,
    unsteadyWalkingOrStandingEnum: unsteadyWalkingOrStanding.value,
    usesDmeAssistForAmbulation: usesDmeAssistForAmbulation.value,
    anticoagulation: fallRisk.value?.anticoagulation,
    otherAnticoagulation: fallRisk.value?.otherAnticoagulation,
    additionalContext: fallRisk.value?.additionalContext,
  }))

  /**
   * open/close edit modal
   */
  function toggleFallRiskEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(refetchFallRisk)

  return {
    patientId,
    fallRiskId,
    // display values
    lastUpdatedDisplay,
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
    // functions to format display values
    arrayToStringDisplay,
    formatStringDisplay,
    // fall risk edit modal
    toggleFallRiskEdit,
    showEditModal,
    editModalInitialData,
    refetchFallRisk,
  }
}
