import { PropType } from 'vue'
import { UUID } from '@/legacy/types/global/base'
import { ReasonOffboarding } from '../shared/types'

// TYPES --------------------
export type SocialSummary = {
  socialSummaryId: UUID
  patientId: UUID

  // Social Summary
  transportationBarrier: TransportationBarrier[] | string[] | null
  foodBarrier: FoodBarrier[] | string[] | null
  housingBarrier: HousingBarrier[] | string[] | null
  financialBarrier: FinancialBarrier[] | string[] | null
  inHomeSupport: InHomeSupport[] | string[] | null
  caregiverMapping: CaregiverMapping[] | string[] | null
  socialSupport: SocialSupport[] | string[] | null
  benefitsProgramsServices: BenefitsProgramsServices[] | string[] | null
  healthcareProxy: boolean | null | undefined
  additionalContext: string | null
  // Offboarding Summary
  reasonOffboarding: ReasonOffboarding | null
  offboardingAdditionalDetails: string | null
  reengagementAttemptsDescription: string | null
}

export type DirtySocialSummary = {
  transportationBarrier: string[] | undefined
  foodBarrier: string[] | undefined
  housingBarrier: string[] | undefined
  financialBarrier: string[] | undefined
  inHomeSupport: string[] | undefined
  caregiverMapping: string[] | undefined
  socialSupport: string[] | undefined
  benefitsProgramsServices: string[] | undefined
  healthcareProxy: boolean | undefined | null
  additionalContext: string | undefined
}

export type SocialSummaryPayload = {
  transportationBarrier: string[] | null
  foodBarrier: string[] | null
  housingBarrier: string[] | null
  financialBarrier: string[] | null
  inHomeSupport: string[] | null
  caregiverMapping: string[] | null
  socialSupport: string[] | null
  benefitsProgramsServices: string[] | null
  healthcareProxy: boolean | null
  additionalContext: string | null
}

export type SocialSummaryFields =
  | typeof TransportationBarrier
  | typeof FoodBarrier
  | typeof HousingBarrier
  | typeof FinancialBarrier
  | typeof InHomeSupport
  | typeof CaregiverMapping
  | typeof SocialSupport
  | typeof BenefitsProgramsServices

// ENUMS --------------------
export enum TransportationBarrier {
  DOES_NOT_HAVE_TRANSPORTATION = 'DOES_NOT_HAVE_TRANSPORTATION',
  COST_OF_GASOLINE = 'COST_OF_GASOLINE',
  COST_OF_TRANSPORTATION = 'COST_OF_TRANSPORTATION',
  WALKING_DISTANCE_TO_PUBLIC_TRANSPORTATION = 'WALKING_DISTANCE_TO_PUBLIC_TRANSPORTATION',
  WALKING_DISTANCE_FROM_HOME_TO_CURB = 'WALKING_DISTANCE_FROM_HOME_TO_CURB',
  WALKING_DISTANCE_FROM_PARKING_LOT_TO_CLINIC = 'WALKING_DISTANCE_FROM_PARKING_LOT_TO_CLINIC',
  NUMBER_OF_PUBLIC_TRANSPORTATION_TRANSFERS = 'NUMBER_OF_PUBLIC_TRANSPORTATION_TRANSFERS',
  SAFETY_ON_PUBLIC_TRANSPORTATION = 'SAFETY_ON_PUBLIC_TRANSPORTATION',
  DIFFICULTY_DUE_TO_PHYSICAL_OR_BEHAVIORAL_DISABILITY = 'DIFFICULTY_DUE_TO_PHYSICAL_OR_BEHAVIORAL_DISABILITY',
  HAS_RELIABLE_TRANSPORTATION = 'HAS_RELIABLE_TRANSPORTATION',
  UNKNOWN = 'UNKNOWN',
}

export enum FoodBarrier {
  FINANCIAL_DIFFICULTIES = 'FINANCIAL_DIFFICULTIES',
  LACK_OF_TRANSPORTATION = 'LACK_OF_TRANSPORTATION',
  INADEQUATE_LOCAL_MARKETS = 'INADEQUATE_LOCAL_MARKETS',
  PHYSICAL_OR_MOBILITY_ISSUES = 'PHYSICAL_OR_MOBILITY_ISSUES',
  LIMITED_FOOD_NUTRITION_KNOWLEDGE = 'LIMITED_FOOD_NUTRITION_KNOWLEDGE',
  INSUFFICIENT_COOKING_FACILITIES = 'INSUFFICIENT_COOKING_FACILITIES',
  DIETARY_RESTRICTIONS_DUE_TO_HEALTH_CONDITIONS = 'DIETARY_RESTRICTIONS_DUE_TO_HEALTH_CONDITIONS',
  SAFETY_CONCERNS = 'SAFETY_CONCERNS',
  NO_FOOD_BARRIERS = 'NO_FOOD_BARRIERS',
  UNKNOWN = 'UNKNOWN',
}

export enum HousingBarrier {
  RECEIVED_EVICTION_NOTICE = 'RECEIVED_EVICTION_NOTICE',
  UNABLE_TO_PAY_RENT_AND_IS_PAST_DUE = 'UNABLE_TO_PAY_RENT_AND_IS_PAST_DUE',
  WILL_BE_UNABLE_TO_PAY_FUTURE_RENT = 'WILL_BE_UNABLE_TO_PAY_FUTURE_RENT',
  UTILITIES_SHUT_OFF = 'UTILITIES_SHUT_OFF',
  UNSAFE_LIVING_CONDITIONS = 'UNSAFE_LIVING_CONDITIONS',
  PERSONAL_CONFLICT_WITH_OTHERS_IN_HOUSEHOLD = 'PERSONAL_CONFLICT_WITH_OTHERS_IN_HOUSEHOLD',
  UNHOUSED = 'UNHOUSED',
  NO_HOUSING_ISSUES = 'NO_HOUSING_ISSUES',
  UNKNOWN = 'UNKNOWN',
}

export enum FinancialBarrier {
  UNEMPLOYMENT = 'UNEMPLOYMENT',
  INSUFFICIENT_EARNINGS_OR_INCOME = 'INSUFFICIENT_EARNINGS_OR_INCOME',
  HEALTHCARE_COSTS = 'HEALTHCARE_COSTS',
  HOUSING_COSTS = 'HOUSING_COSTS',
  UNMANAGEABLE_DEBT = 'UNMANAGEABLE_DEBT',
  INADEQUATE_BENEFITS = 'INADEQUATE_BENEFITS',
  LIMITED_FINANCIAL_LITERACY = 'LIMITED_FINANCIAL_LITERACY',
  MANDATED_CONTRIBUTIONS = 'MANDATED_CONTRIBUTIONS',
  NO_FINANCIAL_HARDSHIP = 'NO_FINANCIAL_HARDSHIP',
  UNKNOWN = 'UNKNOWN',
}

export enum SocialSupport {
  FAMILY = 'FAMILY',
  FRIENDS = 'FRIENDS',
  NEIGHBORS = 'NEIGHBORS',
  PETS = 'PETS',
  COMMUNITY_NETWORKS = 'COMMUNITY_NETWORKS',
  RELIGIOUS_SPIRITUAL_AFFILIATIONS = 'RELIGIOUS_SPIRITUAL_AFFILIATIONS',
  LIMITED_OR_NO_SOCIAL_SUPPORT = 'LIMITED_OR_NO_SOCIAL_SUPPORT',
  UNKNOWN = 'UNKNOWN',
}

export enum InHomeSupport {
  LIVES_WITH_PARTNER_SPOUSE = 'LIVES_WITH_PARTNER_SPOUSE',
  LIVES_WITH_CHILDREN = 'LIVES_WITH_CHILDREN',
  LIVES_WITH_PARENTS = 'LIVES_WITH_PARENTS',
  LIVES_WITH_OTHER_FAMILY_MEMBERS = 'LIVES_WITH_OTHER_FAMILY_MEMBERS',
  LIVES_ALONE = 'LIVES_ALONE',
  UNKNOWN = 'UNKNOWN',
}

export enum CaregiverMapping {
  HAS_PAID_CAREGIVER = 'HAS_PAID_CAREGIVER',
  HAS_UNPAID_CAREGIVER = 'HAS_UNPAID_CAREGIVER',
  UNKNOWN = 'UNKNOWN',
  NEEDS_CAREGIVER = 'NEEDS_CAREGIVER',
  DOES_NOT_NEED_CAREGIVER = 'DOES_NOT_NEED_CAREGIVER',
}

export enum BenefitsProgramsServices {
  FOOD_ASSISTANCE = 'FOOD_ASSISTANCE',
  MEDICAID = 'MEDICAID',
  HOUSING_ASSISTANCE = 'HOUSING_ASSISTANCE',
  VETERAN_BENEFITS = 'VETERAN_BENEFITS',
  DISABILITY = 'DISABILITY',
  GRANTS_OR_CHARITABLE_CARE = 'GRANTS_OR_CHARITABLE_CARE',
  SOCIAL_SECURITY = 'SOCIAL_SECURITY',
  LIHEAP = 'LIHEAP',
  LIFELINE = 'LIFELINE',
  WAP = 'WAP',
  SPECIAL_NEEDS_TRANSPORTATION = 'SPECIAL_NEEDS_TRANSPORTATION',
  MEDICAL_BILL_ASSISTANCE = 'MEDICAL_BILL_ASSISTANCE',
  MEDICATION_ASSISTANCE = 'MEDICATION_ASSISTANCE',
  OTHER_CASE_MGMT_SOCIAL_WORK_PROGRAM = 'OTHER_CASE_MGMT_SOCIAL_WORK_PROGRAM',
  DOES_NOT_NEED_BENEFITS = 'DOES_NOT_NEED_BENEFITS',
  UNKNOWN = 'UNKNOWN',
}

// CONSTS

export const transportationBarrierOptions = [
  {
    label: 'Not applicable, has reliable transportation',
    value: TransportationBarrier.HAS_RELIABLE_TRANSPORTATION,
  },
  {
    label: 'Unknown, has not been assessed',
    value: TransportationBarrier.UNKNOWN,
  },
  ...[
    {
      label: 'Does not have a ride',
      value: TransportationBarrier.DOES_NOT_HAVE_TRANSPORTATION,
    },
    {
      label: 'Cost of gasoline',
      value: TransportationBarrier.COST_OF_GASOLINE,
    },
    {
      label: 'Cost of public transportation, ride share, or taxi',
      value: TransportationBarrier.COST_OF_TRANSPORTATION,
    },
    {
      label: 'Walking distance to nearest public transportation site',
      value: TransportationBarrier.WALKING_DISTANCE_TO_PUBLIC_TRANSPORTATION,
    },
    {
      label: 'Walking distance from home to curb',
      value: TransportationBarrier.WALKING_DISTANCE_FROM_HOME_TO_CURB,
    },
    {
      label: 'Walking distance from parking lot to clinic',
      value: TransportationBarrier.WALKING_DISTANCE_FROM_PARKING_LOT_TO_CLINIC,
    },
    {
      label: 'Safety on public transportation',
      value: TransportationBarrier.SAFETY_ON_PUBLIC_TRANSPORTATION,
    },
    {
      label: 'Number of transfers when using public transportation',
      value: TransportationBarrier.NUMBER_OF_PUBLIC_TRANSPORTATION_TRANSFERS,
    },
    {
      label:
        'Physical disability or behavioral health condition that makes it difficult to use public transportation',
      value:
        TransportationBarrier.DIFFICULTY_DUE_TO_PHYSICAL_OR_BEHAVIORAL_DISABILITY,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const financialBarrierOptions = [
  {
    label: 'Not applicable, no current financial hardship or concerns',
    value: FinancialBarrier.NO_FINANCIAL_HARDSHIP,
  },
  { label: 'Unknown, has not been assessed', value: FinancialBarrier.UNKNOWN },
  ...[
    { label: 'Unemployment', value: FinancialBarrier.UNEMPLOYMENT },
    {
      label: 'Insufficient earnings or income',
      value: FinancialBarrier.INSUFFICIENT_EARNINGS_OR_INCOME,
    },
    {
      label: 'Healthcare costs (e.g., copays, coinsurance, medications)',
      value: FinancialBarrier.HEALTHCARE_COSTS,
    },
    { label: 'Housing costs', value: FinancialBarrier.HOUSING_COSTS },
    { label: 'Unmanageable debt', value: FinancialBarrier.UNMANAGEABLE_DEBT },
    {
      label: 'Inadequate benefits, programs, or services',
      value: FinancialBarrier.INADEQUATE_BENEFITS,
    },
    {
      label: 'Limited financial literacy',
      value: FinancialBarrier.LIMITED_FINANCIAL_LITERACY,
    },
    {
      label:
        'Mandated contributions (e.g., spousal support, child support, fines, etc)',
      value: FinancialBarrier.MANDATED_CONTRIBUTIONS,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const foodBarrierOptions = [
  {
    label:
      'Not applicable, has not been without food for a day in past 12 months',
    value: FoodBarrier.NO_FOOD_BARRIERS,
  },
  { label: 'Unknown, has not been assessed', value: FoodBarrier.UNKNOWN },
  ...[
    {
      label: 'Financial difficulties',
      value: FoodBarrier.FINANCIAL_DIFFICULTIES,
    },
    {
      label: 'Lack of transportation',
      value: FoodBarrier.LACK_OF_TRANSPORTATION,
    },
    {
      label: 'Inadequate local grocery stores or markets',
      value: FoodBarrier.INADEQUATE_LOCAL_MARKETS,
    },
    {
      label: 'Physical disability or mobility issues',
      value: FoodBarrier.PHYSICAL_OR_MOBILITY_ISSUES,
    },
    {
      label: 'Lack of knowledge about nutritious food options',
      value: FoodBarrier.LIMITED_FOOD_NUTRITION_KNOWLEDGE,
    },
    {
      label: 'Insufficient cooking facilities',
      value: FoodBarrier.INSUFFICIENT_COOKING_FACILITIES,
    },
    {
      label: 'Dietary restrictions due to cancer or other health conditions',
      value: FoodBarrier.DIETARY_RESTRICTIONS_DUE_TO_HEALTH_CONDITIONS,
    },
    { label: 'Safety concerns', value: FoodBarrier.SAFETY_CONCERNS },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const inHomeSupportOptions = [
  { label: 'Unknown, has not been assessed', value: InHomeSupport.UNKNOWN },
  ...[
    {
      label: 'Lives with partner/spouse',
      value: InHomeSupport.LIVES_WITH_PARTNER_SPOUSE,
    },
    { label: 'Lives with children', value: InHomeSupport.LIVES_WITH_CHILDREN },
    { label: 'Lives with parents', value: InHomeSupport.LIVES_WITH_PARENTS },
    {
      label: 'Lives with other family members or friends',
      value: InHomeSupport.LIVES_WITH_OTHER_FAMILY_MEMBERS,
    },
    { label: 'Lives alone', value: InHomeSupport.LIVES_ALONE },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const caregiverMappingOptions = [
  {
    label: 'Not applicable, independent and does not require caregivers',
    value: CaregiverMapping.DOES_NOT_NEED_CAREGIVER,
  },
  { label: 'Unknown, has not been assessed', value: CaregiverMapping.UNKNOWN },
  ...[
    {
      label: 'Paid Caregiver (e.g., home health aid)',
      value: CaregiverMapping.HAS_PAID_CAREGIVER,
    },
    {
      label: 'Unpaid Caregiver (e.g., family member, friend)',
      value: CaregiverMapping.HAS_UNPAID_CAREGIVER,
    },
    {
      label: 'Does not have sufficient caregiver support and requires it',
      value: CaregiverMapping.NEEDS_CAREGIVER,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const socialSupportOptions = [
  { label: 'Unknown, has not been assessed', value: SocialSupport.UNKNOWN },
  {
    label: 'Limited or no social support network',
    value: SocialSupport.LIMITED_OR_NO_SOCIAL_SUPPORT,
  },
  ...[
    { label: 'Family', value: SocialSupport.FAMILY },
    { label: 'Friends', value: SocialSupport.FRIENDS },
    { label: 'Neighbors', value: SocialSupport.NEIGHBORS },
    { label: 'Pets', value: SocialSupport.PETS },
    {
      label: 'Community Networks (e.g., Clubs, Groups, Associations)',
      value: SocialSupport.COMMUNITY_NETWORKS,
    },
    {
      label: 'Religious or Spiritual Affiliations ',
      value: SocialSupport.RELIGIOUS_SPIRITUAL_AFFILIATIONS,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const benefitsProgramsServicesOptions = [
  {
    label: 'Not applicable, does not require benefits, programs, and services',
    value: BenefitsProgramsServices.DOES_NOT_NEED_BENEFITS,
  },
  {
    label: 'Unknown, has not been assessed',
    value: BenefitsProgramsServices.UNKNOWN,
  },
  ...[
    {
      label: 'Assistance with food (SNAP, D-SNAP, food stamps)',
      value: BenefitsProgramsServices.FOOD_ASSISTANCE,
    },
    { label: 'Medicaid', value: BenefitsProgramsServices.MEDICAID },
    {
      label:
        'Assistance with housing (e.g., section 8, housing voucher, public housing)',
      value: BenefitsProgramsServices.HOUSING_ASSISTANCE,
    },
    {
      label: "Veteran's benefits",
      value: BenefitsProgramsServices.VETERAN_BENEFITS,
    },
    {
      label: 'Disability (e.g., SSI, private insurance)',
      value: BenefitsProgramsServices.DISABILITY,
    },
    {
      label: 'Grants or charitable care',
      value: BenefitsProgramsServices.GRANTS_OR_CHARITABLE_CARE,
    },
    {
      label: 'Social Security',
      value: BenefitsProgramsServices.SOCIAL_SECURITY,
    },
    {
      label: 'Low Income Home Energy Assistance Program (LIHEAP)',
      value: BenefitsProgramsServices.LIHEAP,
    },
    {
      label: 'LIfeline (i.e., low income telephone and internet support)',
      value: BenefitsProgramsServices.LIFELINE,
    },
    {
      label: 'Weatherization Assistance Program (WAP)',
      value: BenefitsProgramsServices.WAP,
    },
    {
      label: 'Special Needs Transportation (e.g., Dial-a-ride, Access-a-ride)',
      value: BenefitsProgramsServices.SPECIAL_NEEDS_TRANSPORTATION,
    },
    {
      label: 'Medical bill asisstance (e.g., payment plans, copay assistance)',
      value: BenefitsProgramsServices.MEDICAL_BILL_ASSISTANCE,
    },
    {
      label: 'Medication assistance (e.g., free drug, prescription assistance)',
      value: BenefitsProgramsServices.MEDICATION_ASSISTANCE,
    },
    {
      label: 'Other case management and/or social work program',
      value: BenefitsProgramsServices.OTHER_CASE_MGMT_SOCIAL_WORK_PROGRAM,
    },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const housingBarrierOptions = [
  {
    label: 'Not applicable, has steady place to live',
    value: HousingBarrier.NO_HOUSING_ISSUES,
  },
  { label: 'Unknown, has not been assessed', value: HousingBarrier.UNKNOWN },
  ...[
    {
      label: 'Received an eviction notice (verbal or written)',
      value: HousingBarrier.RECEIVED_EVICTION_NOTICE,
    },
    {
      label: 'Not been able to pay rent and rent is past due',
      value: HousingBarrier.UNABLE_TO_PAY_RENT_AND_IS_PAST_DUE,
    },
    {
      label:
        'Will be unable to pay future rent because lost a housing subsidy, job, or other income source',
      value: HousingBarrier.WILL_BE_UNABLE_TO_PAY_FUTURE_RENT,
    },
    {
      label: 'Utilities are shut off',
      value: HousingBarrier.UTILITIES_SHUT_OFF,
    },
    {
      label: 'Living conditions are unsafe, violent, or overcrowded',
      value: HousingBarrier.UNSAFE_LIVING_CONDITIONS,
    },
    {
      label:
        'Experiencing personal conflict with other people in the household',
      value: HousingBarrier.PERSONAL_CONFLICT_WITH_OTHERS_IN_HOUSEHOLD,
    },
    { label: 'Unhoused', value: HousingBarrier.UNHOUSED },
  ].sort((a, b) => a.label.localeCompare(b.label)),
]

export const editSocialOrOffboardingSummaryProps = {
  isOpen: {
    type: Boolean,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  patientSocialSummary: {
    type: Object as PropType<SocialSummary | null>,
    required: true,
  },
} as const
