import router from '@/legacy/router'
import { ViewFormResponseForm } from '@/pages/Tools/FormResponse/types'

export const updateFormResponseIdParam = async (
  values: ViewFormResponseForm
) => {
  await router.push({
    query: {
      formResponseId: values.formResponseId,
    },
  })
}
