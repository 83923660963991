<template>
  <div class="flex space-x-3">
    <div class="w-80">
      <TInputText
        name="formResponseId"
        :model-value="initialData?.formResponseId ?? undefined"
        placeholder="Form Response ID"
      />
    </div>
    <TMPrimaryButton
      name="lookup-form-response"
      label="Search"
      class="h-full"
      @click="onSubmit"
    />
  </div>
</template>
<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import { viewFormResponseFormProps } from '@/pages/Tools/FormResponse/types'
import { setup } from './controller'

export default defineComponent({
  components: {
    TInputText,
    TMPrimaryButton,
  },
  props: viewFormResponseFormProps,
  setup,
})
</script>
