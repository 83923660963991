<template>
  <section class="bg-nash-neutral000">
    <slot name="tableTop">
      <div
        v-if="!!title"
        id="table-title"
        class="w-full flex justify-between items-center nash-table-title"
      >
        <h2>{{ title }}</h2>
        <slot name="tableTopActions"><div></div></slot>
      </div>
    </slot>
    <!-- expanded-row-icon and collapsed-row-icon properties were deprecated in primevue 3.37
     if upgrading to 4.0, we can potentially bring this back -->
    <DataTable
      v-model:filters="filterMeta"
      v-model:expandedRows="expandedRows"
      v-model:sortField="sortFieldState"
      v-model:sortOrder="sortOrderState"
      v-model:first="firstIndexState"
      :selection="selectedItems"
      :data-key="dataKey"
      :value="computedData"
      :loading="isLoading"
      :state-storage="stateStorage"
      :state-key="stateKey"
      :rows="rows"
      :paginator="!!rows"
      :total-records="totalRecords"
      :scrollable="true"
      :lazy="true"
      :row-hover="true"
      scroll-height="flex"
      :class="tableClasses"
      :paginator-template="paginatorTemplate"
      :page-link-size="5"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      :rows-per-page-options="rowsPerPageOptions"
      v-bind="$attrs"
      @page="getData"
      @sort="getData"
      @filter="getData"
      @state-restore="restoreFilters"
      @column-resize-end="triggerGlobalResize"
      @row-click="onRowClick"
      @update:selection="updateSelected"
      @row-expand="onRowExpand"
    >
      <template #loading>
        <TSpinner name="table" :partial-page="true" />
      </template>
      <template #header>
        <div v-if="filterColumns">
          <div
            class="flex justify-between py-3 px-4 border-t-1 border-nash-neutral400"
          >
            <div class="flex gap-3">
              <slot name="filterOptions"><div></div></slot>
              <div class="flex flex-col">
                <TInputText
                  v-if="freeTextFilter"
                  v-model="filterMeta[freeTextFilterName].value"
                  name="t-table-free-text-search"
                  class="w-48"
                  :placeholder="freeTextPlaceholder"
                  :rules="freeTextValidationRules"
                  @keyup.enter="getDataAndResetPage()"
                />
              </div>
              <div v-if="filterColumns.length">
                <TOTableButton
                  name="add-filters"
                  label="Filter"
                  icon="filterFunnel"
                  :icon-pos="IconPosition.LEFT"
                  @click="editingFilters = true"
                />
              </div>
              <slot name="headerStart"></slot>
            </div>
            <div class="flex gap-1">
              <slot name="headerEnd"></slot>
              <MultiSelect
                v-if="allowColToggle"
                :model-value="selectedColumns"
                :options="columns"
                option-disabled="alwaysVisible"
                option-label="header"
                :show-toggle-all="false"
                placeholder="Select Columns"
                class="w-80"
                @update:model-value="onToggleColumn"
              />
              <div class="flex space-x-2">
                <slot name="multiSelectActions"></slot>
                <TOTableButton
                  v-tooltip.left="'Refresh Table'"
                  :name="`${name}-table-refresh`"
                  icon="refresh"
                  size="sm"
                  @click="getData()"
                />
              </div>
            </div>
          </div>
          <div
            v-if="appliedFilters?.length"
            class="flex flex-wrap gap-3 py-3 px-4"
          >
            <TOAppliedFilter
              v-for="filter in appliedFilters"
              :key="filter"
              :filter="filterMeta[filter]"
              :data="filterMeta[filter].value"
              :multiple="
                filterMeta[filter].modalOptions?.type === FilterType.Multiselect
              "
              :label="
                filterMeta[filter].modalOptions?.label ?? startCase(filter)
              "
              @hide="(v) => updateFilter(filter, filterMeta[filter], v)"
              @icon-click="(v) => updateFilter(filter, filterMeta[filter], [])"
              @update="(v) => updateFilter(filter, filterMeta[filter], v)"
            />

            <TOTableButton
              icon="close"
              :icon-pos="IconPosition.RIGHT"
              label="Clear All"
              class="p-button-table-clear"
              @click="resetAndClear()"
            />
          </div>
        </div>
      </template>
      <slot name="startColumns"></slot>
      <Column
        v-for="(col, index) of selectedColumns"
        :key="typeof col.field === 'string' ? col.field : index"
        v-bind="col"
      >
        <template #body="slotProps">
          <slot
            :name="`column-${col.field}`"
            :column="col"
            :row="slotProps.data"
          >
            <TTableCell :column="col" :row="slotProps.data" />
          </slot>
        </template>
      </Column>
      <slot name="endColumns"></slot>
      <template #expansion="slotProps"
        ><slot name="expansion" v-bind="slotProps"></slot
      ></template>
      <Column
        v-if="showHeaderContainer"
        body-style="max-width: 4rem"
        header-style="max-width: 4rem"
      >
        <template #body="slotProps">
          <slot name="lastColumnBody" v-bind="slotProps"></slot>
        </template>
      </Column>
      <template #empty>No results found</template>
    </DataTable>
    <TOFilterModal
      v-if="editingFilters"
      :filters="filterMeta"
      name="tableFilterModal"
      @save="saveFilters"
      @close="editingFilters = false"
    />
  </section>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { IconPosition } from '@thyme/nashville/src/types/icons'
import {
  TablePadding,
  ColumnOptions,
  FilterMetaType,
  FilterType,
  Filter,
  SORT_DESCENDING,
  sortOrder,
  DEFAULT_ROWS_PER_PAGE_OPTS,
} from '@thyme/nashville/src/types/tables'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import values from 'lodash/values'
import { storeToRefs } from 'pinia'
import Column from 'primevue/column'
import DataTable, {
  DataTableProps,
  DataTableSortEvent,
} from 'primevue/datatable'
import MultiSelect from 'primevue/multiselect'
import {
  computed,
  defineComponent,
  defineExpose,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue'
import {
  checkAndTransformMultiValFilters,
  setFiltersDefaults,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { jsDateTimeToString } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { Patient } from '@/legacy/types/patients/patients'
import TOFilterModal from '../table/filterModal/TOFilterModal.vue'
import TOAppliedFilter from '../table/TOAppliedFilter.vue'
import TOTableButton from '../table/TOTableButton.vue'
import { setupFiltersForTable, updateFilterSet } from './filterableTableSetup'
import TTableCell from './TTableCell.vue'
export default defineComponent({
  components: {
    TSpinner,
    DataTable,
    MultiSelect,
    Column,
    TTableCell,
    TOTableButton,
    TOAppliedFilter,
    TInputText,
    TOFilterModal,
  },
  props: {
    dataKey: {
      type: String,
      default: null,
    },
    selectedItems: {
      type: Array as PropType<any>,
      default: () => [],
    },
    stateStorage: {
      type: String as PropType<DataTableProps['stateStorage']>,
      default: 'local',
    },
    showRowsPerPage: {
      type: Boolean,
      default: true,
    },
    rowsPerPage: {
      type: Array as PropType<number[]>,
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      default: () => DEFAULT_ROWS_PER_PAGE_OPTS,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnOptions[]>,
      required: true,
    },
    apiStore: {
      type: Function,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
    allowColToggle: {
      type: Boolean,
      default: false,
    },
    tablePadding: {
      type: String,
      default: TablePadding.MD,
      validator: (v: string) => Object.values<string>(TablePadding).includes(v),
      // @ts-ignore options custom attr
      options: TablePadding,
    },
    filters: {
      type: Object as PropType<FilterMetaType>,
      default: () => ({}),
    },
    freeTextFilter: {
      type: Boolean,
      default: false,
    },
    freeTextPlaceholder: {
      type: String,
      default: '',
    },
    freeTextFilterName: {
      type: String,
      default: 'freeText',
    },
    rows: {
      type: Number,
      default: null,
    },
    showHeaderContainer: {
      type: Boolean,
      default: true,
    },
    sortOrder: {
      type: Number,
      default: null,
    },
    sortField: {
      type: String,
      default: null,
    },
  },
  emits: ['dataLoaded', 'onRowClick', 'updateSelectedItems', 'rowExpand'],
  setup(props, context) {
    const expandedRows = ref<any[]>([])
    const clearOtherFilters = computed(() => props.freeTextFilterName)
    const stateKey = computed(() => `dt-${props.name}-store`)
    const getDataAndResetPage = async () => {
      setupFilters.firstIndexState.value = 0
      await getData()
    }
    const _getData = async (tableParams?: DataTableSortEvent) => {
      // trigger event listener that refetches filter values
      // from local storage when they change
      window.dispatchEvent(new Event('refetch-filter-values'))
      // Ensure most recent refetched filters are applied
      await nextTick()
      const data = await props.apiStore().list({
        params: {
          ...tablePaginationParams(stateKey.value, tableParams),
          ...(props.params ?? {}),
        },
      })

      if (data?.data?.length) {
        context.emit('dataLoaded', data.data)
        triggerGlobalResize()
      }
    }
    const freeTextValidationRules = computed(() => {
      if (props.freeTextFilterName.toLowerCase().includes('phonenumber')) {
        return 'phone'
      } else {
        return undefined
      }
    })

    const DEBOUNCE_INTERVAL_MS = 300

    const getData = debounce(_getData, DEBOUNCE_INTERVAL_MS)

    const setupFilters = setupFiltersForTable(stateKey, props, getData)

    watch(clearOtherFilters, () => {
      if (clearOtherFilters.value) {
        setupFilters.resetAndClear()
      }
    })
    const tablePadding = computed(() =>
      props.tablePadding ? `p-datatable-${props.tablePadding}` : ''
    )

    const rowsPerPageOptions = computed(() => {
      if (props.showRowsPerPage) {
        if (props.rowsPerPage.includes(props.rows) || !props.rows) {
          return props.rowsPerPage
        }
        // If rows is passed in, make sure it's also a selectable option
        return [props.rows].concat(props.rowsPerPage).sort((a, b) => a - b)
      }
      return undefined
    })

    const tableClasses = computed(() => [tablePadding.value])

    const paginatorTemplate = computed(
      () =>
        `RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink`
    )

    const {
      isLoading,
      queryMetadata,
      computedData: data,
    } = storeToRefs(props.apiStore())
    const totalRecords = computed(() => queryMetadata.value?.total ?? 0)

    const computedData = computed(() =>
      isArray(data.value) ? data.value : values(data.value)
    )

    const selectedColumns = ref(
      props.columns.filter((column) => !column.hidden)
    )
    const onToggleColumn = (newCols: ColumnOptions[]) =>
      (selectedColumns.value = newCols)

    const updateSelected = (selected: any) => {
      context.emit('updateSelectedItems', selected)
    }

    const updateFilter = (field: string, filter: Filter, newValue: any) => {
      const newVals = {}
      if (
        filter.modalOptions?.preEnabledOptionsFn &&
        isArray(newValue) &&
        !newValue?.length
      ) {
        newValue = filter.modalOptions.preEnabledOptionsFn()
      }
      updateFilterSet(field, newValue, filter, newVals)
      setupFilters.saveFilters(newVals)
    }

    const tablePaginationParams = (
      key: string,
      tableParams?: DataTableSortEvent
    ) => {
      const storageKey =
        props.stateStorage === 'local'
          ? localStorage.getItem(key)
          : sessionStorage.getItem(key)
      const tableState = tableParams ?? JSON.parse(storageKey ?? '{}')

      let pageParams = {}
      const pageNumber = tableState.rows
        ? Math.floor(tableState.first / tableState.rows) + 1
        : 1
      const hasPresort = computed(() => !!tableState.multiSortMeta?.length)

      // https://primevue.org/datatable/#pre_sort
      // Here, `multiSortMeta` can mean multi-sort but following
      // primevue conventions, will leave it as "presort".
      // Also, here, we are checking if there is also an actual
      // presort set to prioritize any defaulted sorting.
      // In the case of MyRemindersTable, we are using `myRemindersPagePresort`
      // variables as a default "presort"
      const presort = computed(() =>
        hasPresort.value
          ? {
              sort_by: tableState.multiSortMeta.map(
                (sort: any) =>
                  `${sort.field},${
                    sort.order === SORT_DESCENDING
                      ? sortOrder.DESC
                      : sortOrder.ASC
                  }`
              ),
            }
          : {}
      )

      const sort = computed(() => {
        const order = tableState.sortOrder
          ? tableState.sortOrder === SORT_DESCENDING
            ? `,${sortOrder.DESC}`
            : `,${sortOrder.ASC}`
          : ''

        return tableState.sortField
          ? {
              sort_by: [`${tableState.sortField}${order}`],
            }
          : {}
      })

      pageParams = { page_number: pageNumber }

      let filters = reduce(
        tableState.filters,
        (acc, { value, hidden }, key) => {
          const filter = setupFilters.filterMeta.value[key]
          if (filter?.valToParamFn) {
            value = filter.valToParamFn(value)
          }
          if (value && !hidden) {
            acc[`filter_${snakeCase(key)}`] = value
          }
          return acc
        },
        {} as { [key: string]: string }
      )

      // See comments in below function directory for use
      filters = checkAndTransformMultiValFilters(filters)
      filters = setFiltersDefaults(filters, setupFilters.filterMeta.value)

      // Do not remove!
      // Currently, if users toggle pagination buttons (per page, page number),
      // incorrect date formats are sent (date obj vs. string format).
      // Below checks ensure dates are formatted properly every time.
      // IMPROVEME(MT-1956): Figure out root cause for above behavior for better fix
      if (filters.filter_due_before) {
        filters.filter_due_before =
          jsDateTimeToString(new Date(filters.filter_due_before)) ?? []
      }

      if (filters.filter_due_after) {
        filters.filter_due_after =
          jsDateTimeToString(new Date(filters.filter_due_after)) ?? []
      }

      if (filters.filter_completed_on_after) {
        filters.filter_completed_on_after =
          jsDateTimeToString(new Date(filters.filter_completed_on_after)) ?? []
      }
      if (filters.filter_completed_on_before) {
        filters.filter_completed_on_before =
          jsDateTimeToString(new Date(filters.filter_completed_on_before)) ?? []
      }

      return {
        ...(hasPresort.value ? presort.value : sort.value ? sort.value : {}),
        ...(tableState.rows || props.rows
          ? { page_length: tableState.rows ?? props.rows }
          : {}),
        ...pageParams,
        ...filters,
      }
    }

    /**
     * trigger global resize event when column is resized
     * this helps dynamic truncate reset to the proper width
     */
    function triggerGlobalResize() {
      thymeDispatch('resize')
    }

    /**
     * Emit an event that will change router link
     * when a row is clicked
     * @param event
     * @param event.data
     * @param event.originalEvent
     */
    function onRowClick(event: { data: Patient; originalEvent: Event }) {
      context.emit('onRowClick', event)
    }

    /**
     * Bubble rowExpand event to parent
     * @param event
     * @param event.data Row data
     * @param event.originalEvent JSEvent trigger
     */
    function onRowExpand(event: { data: any; originalEvent: Event }) {
      context.emit('rowExpand', event)
    }

    onMounted(() => getData())
    defineExpose({
      getData,
    })

    return {
      updateSelected,
      expandedRows,
      updateFilter,
      onRowClick,
      onRowExpand,
      tableClasses,
      stateKey,
      totalRecords,
      computedData,
      isLoading,
      getData,
      selectedColumns,
      paginatorTemplate,
      triggerGlobalResize,
      onToggleColumn,
      FilterType,
      IconPosition,
      startCase,
      rowsPerPageOptions,
      getDataAndResetPage,
      freeTextValidationRules,
      ...setupFilters,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
}

:deep(.p-datatable-row-expansion > td) {
  padding: 0 !important;
  .p-datatable {
    padding: 0 !important;
    thead {
      display: none;
    }
    .p-datatable-header {
      display: none;
    }
    td {
      @apply bg-nash-purple50;
    }
  }
}

:deep(.hide-expander .p-row-toggler) {
  display: none;
}
</style>
