<template>
  <div
    :class="`inline-flex space-x-2 items-center ${expanded ? 'w-1/2' : 'w-64'}`"
  >
    <PhoneDropdown
      name="comm-callee"
      :entities="callOptionEntities"
      :model-value="dirtySelectedCallee"
      :label="plannedCall ? '' : 'Speak to'"
      empty-option-label="N/A: Manual Call"
      @update:model-value="(v: any) => updateCallee(v)"
    />
    <div
      v-if="selectedPhoneNumber && shouldNotContact"
      v-tooltip.right="DNC_STRING"
      class="relative inline-block"
    >
      <TOCallButton
        class="shrink-0 p-2"
        name="patient-dnc-call"
        :phone-number="selectedPhoneNumber.phoneNumber"
        :call-icon="IconifyIcons.phoneXMark"
        call-icon-fill="nash-brick600"
        :is-patient-contact="isCallingPatient"
        @call="createCallDisposition"
        @update-humana-dnc-modal="updateHumanaDncModal"
      />
    </div>
    <TOCallButton
      v-else-if="selectedPhoneNumber"
      class="shrink-0 p-2"
      name="communication"
      :phone-number="selectedPhoneNumber.phoneNumber"
      :is-patient-contact="isCallingPatient"
      @call="createCallDisposition"
      @update-humana-dnc-modal="updateHumanaDncModal"
    />

    <div v-else class="block relative">
      <TMSecondaryButton
        :disabled="isDisabled"
        icon="dialpad"
        label="Manual Call"
        @click="togglePopover"
      />
      <OverlayPanel ref="showPopover">
        <TDialpad
          @dial="
            (phoneNumber) => {
              generateOutboundCall(phoneNumber)
              togglePopover(phoneNumber)
            }
          "
        />
      </OverlayPanel>
    </div>
  </div>
  <DoNotContactModal
    v-if="showHumanaDncModal"
    :is-open="showHumanaDncModal"
    :alternate-modal-text="showHumanaDncModalAlternateText"
    @close="closeHumanaDncModal"
  />
</template>
<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import OverlayPanel from 'primevue/overlaypanel'
import { defineComponent, PropType } from 'vue'
import PhoneDropdown from '@/legacy/components/patient/communicationV2/dropdowns/PhoneDropdown/PhoneDropdown.vue'
import DoNotContactModal from '@/legacy/components/patient/communicationV2/modals/DoNotContactModal/DoNotContactModal.vue'
import TOCallButton from '@/legacy/nashville/button/TOCallButton.vue'
import TDialpad from '@/legacy/nashville/TDialpad.vue'
import { BaseCommunicationPayload } from '@/legacy/types/communications/communications'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'
import setup from './controller'

export default defineComponent({
  components: {
    OverlayPanel,
    TDialpad,
    TMSecondaryButton,
    PhoneDropdown,
    TOCallButton,
    DoNotContactModal,
  },
  props: {
    plannedCall: {
      type: Object as PropType<DirtyPlannedCall>,
      default: null,
    },
    communication: {
      type: Object as PropType<BaseCommunicationPayload>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updatePlannedCallee'],
  setup,
})
</script>
