<template>
  <PatientProfileTabbed v-if="showTabbedPatientProfile" />
  <section v-else>
    <div class="columns h-full is-marginless">
      <Transition>
        <TNotification v-if="notification" />
      </Transition>

      <div
        data-cy="patient-sidebar"
        class="column is-one-fifth bg-nash-purple50 border-r border-nash-neutral400 overflow-y-auto"
      >
        <PatientSidebar />
      </div>

      <div class="column overflow-y-auto overflow-x-hidden space-y-4 p-5 pt-0">
        <DuplicatePatientWarning
          v-if="person"
          class="sticky-banner"
          :patient-person="person"
        />

        <PatientSummary />

        <!-- Care Plans restructured: Care Plan Section  -->
        <PatientCarePlan />
        <PatientThymeline />
        <!-- Wait to load Patient medical because there is an infinite loop timing between sidebar and medical pinia stores which I can't figure out -->
        <PatientMedical v-if="patient" />
      </div>

      <component
        :is="activeSideBar"
        v-if="activeSideBar && patientLoaded"
        :key="editingCommunicationId"
        class="column overflow-y-auto is-two-fifths border-l border-nash-neutral400"
        :data-cy="activeSideBar"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import PatientCarePlan from '@/legacy/components/patient/carePlans/PatientCarePlan/PatientCarePlan.vue'
import CommunicationsSidebarV2 from '@/legacy/components/patient/communicationV2/sidebar/CommunicationsSidebar/CommunicationsSidebar.vue'

import DuplicatePatientWarning from '@/legacy/components/patient/duplicatePatientWarning/DuplicatePatientWarning.vue'
import PatientMedical from '@/legacy/components/patient/medical/PatientMedical/PatientMedical.vue'

import PathwaySidebar from '@/legacy/components/patient/pathways/PathwaySidebar.vue'
import SubtaskSidebar from '@/legacy/components/patient/pathways/SubtaskSidebar.vue'
import ReferralsSidebar from '@/legacy/components/patient/referral/ReferralsSidebar.vue'
import PatientSidebar from '@/legacy/components/patient/sidebar/PatientSidebar.vue'
import PatientSummary from '@/legacy/components/patient/summary/PatientSummary.vue'
import PatientThymeline from '@/legacy/components/patient/thymeline/PatientThymeline/PatientThymeline.vue'
import VisitNoteSidebar from '@/legacy/components/patient/visitNote/VisitNoteSidebar.vue'
import VisitSummarySidebar from '@/legacy/components/patient/visitNote/VisitSummarySidebar.vue'
import { getValFromQuery } from '@/legacy/libs/lookup'
import TNotification from '@/legacy/nashville/TNotification.vue'
import PatientProfileTabbed from '@/legacy/pages/PatientProfileTabbed/PatientProfileTabbed.vue'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useGoalApi } from '@/legacy/store/modules/goals'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  usePathwayApi,
  usePathwaysStore,
  usePathwayTemplateApi,
} from '@/legacy/store/modules/pathways'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  useMapEntityOtherContactPhoneNumberApi,
  useMapEntityPhoneNumberApi,
} from '@/legacy/store/modules/phoneNumbers'
import { useReattemptApi } from '@/legacy/store/modules/reattempts'
import {
  useSubtaskApi,
  useSubtaskTemplateApi,
} from '@/legacy/store/modules/subtasks'
import { useTaskApi, useTaskTemplateApi } from '@/legacy/store/modules/tasks'
import { useTextingStore } from '@/legacy/store/modules/textingV2'
import {
  useBlockedThymelineApi,
  useOpenThymelineApi,
  useInProgressThymelineApi,
  useCompletedThymelineApi,
  useCancelledThymelineApi,
} from '@/legacy/store/modules/thymelines'
import { usePatientIdProvide } from '@/pages/PatientProfile/providePatientId'
/**
 * Function to return creation/editing state of subtask sidebar
 */
function useSubtasksSidebar() {
  const route = useRoute()

  const querySubtaskId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'subtaskId')
  })

  const isEditingSubtask = computed(() => !!querySubtaskId.value)

  return {
    isEditingSubtask,
  }
}

/**
 * Function to return viewing state of referrals sidebar
 */
function useReferralSidebar() {
  const route = useRoute()
  const queryReferralIds = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'referralIds')
  })

  const isViewingReferrals = computed(() => !!queryReferralIds.value?.length)

  return { isViewingReferrals }
}

/**
 * Function to return viewing state of visit note sidebar
 */
function useVisitNoteSidebar() {
  const route = useRoute()
  const queryVisitNoteId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'visitNoteId')
  })
  const isViewingVisitNote = computed(() => !!queryVisitNoteId.value)

  return { isViewingVisitNote }
}

/**
 * Function to return viewing state of visit summary sidebar
 */
function useVisitSummarySidebar() {
  const route = useRoute()
  const queryVisitNoteId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'visitNoteId')
  })

  const isVisitSummary = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'isVisitSummary')
  })
  const isViewingVisitSummary = computed(
    () => !!queryVisitNoteId.value && isVisitSummary.value
  )

  return { isViewingVisitSummary }
}

/**
 * Function to return creation/editing state of pathways sidebar along with store resets
 */
function usePathwaySidebar() {
  const pathwaysStore = usePathwaysStore()
  const patientStore = usePatientStore()
  const route = useRoute()

  const queryPathwayId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'pathwayId')
  })

  const queryTaskId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'taskId')
  })

  const resetStores = async () => {
    // RESET STORES ON LOAD!
    // IMPROVEME -- can this info live in the component? Does this wipe out info we otherwise want?
    patientStore.$reset()
    usePathwayTemplateApi().reset()
    useTaskTemplateApi().reset()
    useSubtaskTemplateApi().reset()
    usePathwayApi().reset()
    useTaskApi().reset()
    useSubtaskApi().reset()
    useReattemptApi().reset()
    useOpenThymelineApi().reset()
    useCompletedThymelineApi().reset()
    useCancelledThymelineApi().reset()
    useInProgressThymelineApi().reset()
    useBlockedThymelineApi().reset()
    useGoalApi().reset()
    useMapEntityPhoneNumberApi().reset()
    useMapEntityOtherContactPhoneNumberApi().reset()
    void pathwaysStore.getAllPathways(route.params.patientId as string)
    void patientStore.fetchPatient(route.params.patientId as string)
    useTextingStore().reset()
  }

  const isEditingPathway = computed(
    () => !!queryPathwayId.value || !!queryTaskId.value
  )

  return {
    pathwaysStore,
    isEditingPathway,
    resetStores,
  }
}

/**
 * Function to return creation/editing state of communications sidebar along with store resets
 */
function useCommsSidebar() {
  const communicationsStore = useCommunicationsStore()
  const pathwaysStore = usePathwaysStore()
  const patientStore = usePatientStore()
  const route = useRoute()

  const { isCreating: isCreatingCommunication } =
    storeToRefs(communicationsStore)

  const editingCommunicationId = computed(() => {
    const queryData = route.query
    const isSmsType = getValFromQuery(queryData, 'type')
    if (!isSmsType) {
      const commId = getValFromQuery(queryData, 'commId')
      return commId?.length ? commId[0] : undefined
    }
    return undefined
  })

  const resetStores = async () => {
    patientStore.$reset()
    usePathwayApi().reset()
    useTaskApi().reset()
    useSubtaskApi().reset()
    usePathwayTemplateApi().reset()
    useTaskTemplateApi().reset()
    useSubtaskTemplateApi().reset()
    useReattemptApi().reset()
    communicationsStore.reset()

    void pathwaysStore.getAllPathways(route.params.patientId as string)
    void patientStore.fetchPatient(route.params.patientId as string)
    useTextingStore().reset()
  }

  return {
    isCreatingCommunication,
    editingCommunicationId,
    resetStores,
  }
}

export default defineComponent({
  components: {
    PatientProfileTabbed,
    PatientSidebar,
    SubtaskSidebar,
    PathwaySidebar,
    CommunicationsSidebarV2,
    VisitNoteSidebar,
    VisitSummarySidebar,
    ReferralsSidebar,
    PatientMedical,
    PatientSummary,
    PatientThymeline,
    TNotification,
    DuplicatePatientWarning,
    PatientCarePlan,
  },
  setup() {
    const route = useRoute()
    usePatientIdProvide()

    const { showTabbedPatientProfile } = storeToRefs(useFlagStore())
    const { notification } = storeToRefs(useNotificationStore())
    const { patient, person, phoneNumbers, contacts, isLoading } = storeToRefs(
      usePatientStore()
    )

    const { isEditingSubtask } = useSubtasksSidebar()

    const { isEditingPathway, resetStores: resetPathwaysStores } =
      usePathwaySidebar()

    const { isViewingVisitNote } = useVisitNoteSidebar()
    const { isViewingVisitSummary } = useVisitSummarySidebar()
    const { isViewingReferrals } = useReferralSidebar()

    const {
      resetStores: resetCommsStores,
      isCreatingCommunication,
      editingCommunicationId,
    } = useCommsSidebar()

    const patientLoaded = computed(
      () =>
        !!patient.value?.entityId && !!phoneNumbers.value && !!contacts.value
    )

    const commSidebarOpen = computed(
      () => isCreatingCommunication.value || !!editingCommunicationId.value
    )

    const activeSideBar = computed(() => {
      return commSidebarOpen.value
        ? 'CommunicationsSidebarV2'
        : isEditingPathway.value
        ? 'PathwaySidebar'
        : isEditingSubtask.value
        ? 'SubtaskSidebar'
        : isViewingReferrals.value
        ? 'ReferralsSidebar'
        : isViewingVisitSummary.value
        ? 'VisitSummarySidebar'
        : isViewingVisitNote.value
        ? 'VisitNoteSidebar'
        : null
    })

    if (!showTabbedPatientProfile.value) {
      if (activeSideBar.value === 'CommunicationsSidebarV2') {
        onBeforeMount(resetCommsStores)
      } else {
        onBeforeMount(resetPathwaysStores)
      }
    }

    // refetch member info when params change
    // for more info:
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
    watch(
      () => route.params.patientId,
      async () => {
        if (!showTabbedPatientProfile.value) {
          if (route.params.patientId) {
            if (activeSideBar.value === 'CommunicationsSidebarV2') {
              await resetCommsStores()
            } else {
              await resetPathwaysStores()
            }
          }
        }
      }
    )

    return {
      showTabbedPatientProfile,
      isCreatingCommunication,
      patient,
      notification,
      activeSideBar,
      editingCommunicationId,
      isLoading,
      patientLoaded,
      person,
    }
  },
})
</script>
<style lang="scss">
.v-enter-active,
.v-leave-active {
  @apply transition ease-out;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.sticky-banner {
  position: sticky;
  top: 10px;
  z-index: 999;
}
</style>
