<template>
  <div>
    <CarePlan />
    <PatientSummary />
    <PatientGoals />
  </div>
</template>

<script lang="ts">
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { defineComponent, onBeforeMount } from 'vue'
import PatientSummary from '@/legacy/components/patient/summary/PatientSummary.vue'
import { useGoalApi } from '@/legacy/store/modules/goals'
import {
  usePathwayApi,
  usePathwaysStore,
  usePathwayTemplateApi,
} from '@/legacy/store/modules/pathways'
import { useReattemptApi } from '@/legacy/store/modules/reattempts'
import {
  useSubtaskApi,
  useSubtaskTemplateApi,
} from '@/legacy/store/modules/subtasks'
import { useTaskApi, useTaskTemplateApi } from '@/legacy/store/modules/tasks'
import CarePlan from '@/pages/PatientProfile/CarePlans/CarePlan/CarePlan.vue'
import PatientGoals from '@/pages/PatientProfile/CarePlans/PatientGoals/PatientGoals.vue'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

export default defineComponent({
  components: {
    CarePlan,
    PatientSummary,
    PatientGoals,
  },
  setup() {
    const patientId = requiredInject(PATIENT_ID_KEY)

    /**
     *
     */
    async function resetStores() {
      usePathwayTemplateApi().reset()
      useTaskTemplateApi().reset()
      useSubtaskTemplateApi().reset()
      usePathwayApi().reset()
      useTaskApi().reset()
      useSubtaskApi().reset()
      useReattemptApi().reset()
      useGoalApi().reset()
    }

    /**
     *
     */
    async function fetchAllPathways() {
      void usePathwaysStore().getAllPathways(patientId.value)
    }

    onBeforeMount(async () => {
      await resetStores()
      await fetchAllPathways()
    })

    return {}
  },
})
</script>
