import { Nullable } from 'vitest'
import { UUID } from '../global/base'

// TYPES --------------------
export type PrioritySegment = {
  patientId: UUID
  prioritySegmentId: UUID
  priorityMetadataJson: Nullable<object>
  enrollmentPriorityScore: number
  enrollmentSegment: EnrollmentSegment | string
  enrollmentOutreachStatus: EnrollmentOutreachStatus
  totalEnrollmentOutreaches: number
  latestEnrollmentOutreachDate: Nullable<Date>
}

// ENUMS --------------------
export enum EnrollmentSegment {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum EnrollmentOutreachStatus {
  HIDDEN = 'HIDDEN',
  INCLUDED = 'INCLUDED',
}
