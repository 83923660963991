import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import {
  ViewFormResponseForm,
  inputSchema,
  viewFormResponseFormProps,
} from '@/pages/Tools/FormResponse/types'
import type { ExtractPropTypes } from 'vue'
type PropsType = ExtractPropTypes<typeof viewFormResponseFormProps>

/**
 *
 * @param props
 */
export function setup(props: PropsType) {
  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(inputSchema),
  })

  /**
   *
   * @param values
   */
  function submit(values: ViewFormResponseForm) {
    props.submitFunction(values)
  }

  const onSubmit = handleSubmit(submit)

  return { onSubmit }
}
