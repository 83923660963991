import { IFlowAnswers } from '@formsort/constants/lib/flow'
import type { UUID } from '@/legacy/types/global/base'

// TYPES --------------------
export type FormSortAnswerType = string | number | boolean

export type FormsortData = {
  flowLabel: string
  formResponseId: UUID
  prefillData: Array<[string, string]>
  variantLabel: string
  variantRevisionId: UUID
  responderUuid: UUID
  createdAt: string
}

export type FormResponse = {
  formResponse: FormsortData
}
export type FormsortEventResponse = {
  variantRevisionUuid: string
  answers?: IFlowAnswers
}

// CONSTANTS --------------------
// https://docs.formsort.com/handling-data/responder-uuids#setting-the-responder-uuid-manually
export const RESPONDER_UUID_KEY = 'responderUuid'

// https://docs.formsort.com/building-flows/publishing-and-versions/history#force-load-a-specific-variant-revision
export const VARIANT_REVISION_KEY = 'variantRevisionUuid'

export const INLINE_STEPS_KEY = 'showAllStepsInline'

// https://docs.formsort.com/handling-data/environments/setting-the-environment#accessing-the-formsortenv-variable-within-a-flow
export const FORMSORT_ENV_KEY = 'formsortEnv'

// Metadata field we pass with form completions to map form responses <> subtasks
export const SUBTASK_ID_KEY = 'subtask_id'
