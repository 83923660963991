<template>
  <div
    v-if="canViewPageFormResponses"
    class="bg-gray-100 p-5 space-y-2 !overflow-y-auto"
  >
    <h2>Form Response Viewer</h2>
    <FormResponseForm
      :submit-function="updateFormResponseIdParam"
      :initial-data="initialInputData"
    />
    <TSpinner
      v-if="
        state === FormResponseStates.LOADING ||
        state === FormResponseStates.AWAITING_EMBED
      "
      name="loading-form-response"
      :partial-page="true"
    />
    <div v-if="state === FormResponseStates.ERROR">Error: {{ errorMsg }}</div>
    <div v-show="state === FormResponseStates.EMBEDDED">
      <p>Form submitted on {{ formResponseCreatedAt }}</p>
      <div
        v-if="!isDevEnv"
        :id="FORM_EMBED_ROOT_ID"
        class="pace-y-2 is-vcentered pointer-events-none opacity-50"
      />
      <div v-else class="h-20 text-center">Cannot load forms locally</div>
    </div>
  </div>
  <div v-else>You do not have access to view this page.</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import FormResponseForm from '@/pages/Tools/FormResponse/FormResponseForm/FormResponseForm.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    FormResponseForm,
    TSpinner,
  },
  // IMPROVE(SPC-1627) - figure out why setup must be defined this way in order to be mocked out in tests
  setup() {
    return setup()
  },
})
</script>
