import { storeToRefs } from 'pinia'
import { TabMenuChangeEvent } from 'primevue/tabmenu'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useGoalApi } from '@/legacy/store/modules/goals'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  usePathwayApi,
  usePathwaysStore,
  usePathwayTemplateApi,
} from '@/legacy/store/modules/pathways'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  useMapEntityOtherContactPhoneNumberApi,
  useMapEntityPhoneNumberApi,
} from '@/legacy/store/modules/phoneNumbers'
import { useReattemptApi } from '@/legacy/store/modules/reattempts'
import {
  useSubtaskApi,
  useSubtaskTemplateApi,
} from '@/legacy/store/modules/subtasks'
import { useTaskApi, useTaskTemplateApi } from '@/legacy/store/modules/tasks'
import {
  useOpenTextingSubtaskApi,
  useTextingStore,
} from '@/legacy/store/modules/textingV2'
import { usePatientIdProvide } from '@/pages/PatientProfile/providePatientId'
import { TabMenuItems } from '@/pages/PatientProfile/Tabs/shared/types'
import { getOpenPointerSubtasks } from '../../components/texting/lib/sharedTextingParts'

/**
 * Function to return creation/editing state of subtask sidebar
 */
function useSubtasksSidebar() {
  const route = useRoute()

  const querySubtaskId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'subtaskId')
  })

  const isEditingSubtask = computed(() => !!querySubtaskId.value)

  return {
    isEditingSubtask,
  }
}

/**
 * Function to return viewing state of referrals sidebar
 */
function useReferralSidebar() {
  const route = useRoute()
  const queryReferralIds = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'referralIds')
  })

  const isViewingReferrals = computed(() => !!queryReferralIds.value?.length)

  return { isViewingReferrals }
}

/**
 * Function to return viewing state of visit note sidebar
 */
function useVisitNoteSidebar() {
  const route = useRoute()
  const queryVisitNoteId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'visitNoteId')
  })
  const isViewingVisitNote = computed(() => !!queryVisitNoteId.value)

  return { isViewingVisitNote }
}

/**
 * Function to return viewing state of visit summary sidebar
 */
function useVisitSummarySidebar() {
  const route = useRoute()
  const queryVisitNoteId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'visitNoteId')
  })

  const isVisitSummary = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'isVisitSummary')
  })
  const isViewingVisitSummary = computed(
    () => !!queryVisitNoteId.value && isVisitSummary.value
  )

  return { isViewingVisitSummary }
}

/**
 * Function to return creation/editing state of pathways sidebar along with store resets
 */
function usePathwaySidebar() {
  const pathwaysStore = usePathwaysStore()
  const patientStore = usePatientStore()
  const route = useRoute()

  const queryPathwayId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'pathwayId')
  })

  const queryTaskId = computed(() => {
    const queryData = route.query
    return getValFromQuery(queryData, 'taskId')
  })

  const resetPathwayStores = async () => {
    usePathwayTemplateApi().reset()
    useTaskTemplateApi().reset()
    useSubtaskTemplateApi().reset()
    usePathwayApi().reset()
    useTaskApi().reset()
    useSubtaskApi().reset()
    useReattemptApi().reset()
    useGoalApi().reset()
    void pathwaysStore.getAllPathways(route.params.patientId as string)
  }

  const resetPatientStores = async () => {
    // RESET STORES ON LOAD!
    // IMPROVEME -- can this info live in the component? Does this wipe out info we otherwise want?
    patientStore.$reset()
    useMapEntityPhoneNumberApi().reset()
    useMapEntityOtherContactPhoneNumberApi().reset()
    void patientStore.fetchPatient(route.params.patientId as string)
  }

  const isEditingPathway = computed(
    () => !!queryPathwayId.value || !!queryTaskId.value
  )

  return {
    pathwaysStore,
    isEditingPathway,
    resetPathwayStores,
    resetPatientStores,
  }
}

/**
 * Function to return creation/editing state of communications sidebar along with store resets
 */
function useCommsSidebar() {
  const communicationsStore = useCommunicationsStore()
  const pathwaysStore = usePathwaysStore()
  const patientStore = usePatientStore()
  const route = useRoute()

  const { isCreating: isCreatingCommunication } =
    storeToRefs(communicationsStore)

  const editingCommunicationId = computed(() => {
    const queryData = route.query
    const isSmsType = getValFromQuery(queryData, 'type')
    if (!isSmsType) {
      const commId = getValFromQuery(queryData, 'commId')
      return commId?.length ? commId[0] : undefined
    }
    return undefined
  })

  const resetStores = async () => {
    patientStore.$reset()
    usePathwayApi().reset()
    useTaskApi().reset()
    useSubtaskApi().reset()
    usePathwayTemplateApi().reset()
    useTaskTemplateApi().reset()
    useSubtaskTemplateApi().reset()
    useReattemptApi().reset()
    communicationsStore.reset()

    void pathwaysStore.getAllPathways(route.params.patientId as string)
    void patientStore.fetchPatient(route.params.patientId as string)
    useTextingStore().reset()
  }

  return {
    isCreatingCommunication,
    editingCommunicationId,
    resetStores,
  }
}

/**
 *
 */
export function setup() {
  usePatientIdProvide()

  const route = useRoute()
  const router = useRouter()

  const { data: openPointerSubtasks } = storeToRefs(useOpenTextingSubtaskApi())

  const openPointerSubtasksNum = computed(() => {
    if (openPointerSubtasks.value) {
      return Object.values(openPointerSubtasks.value).length
    }
    return 0
  })

  const items = computed(() => {
    const basePath = `/patient/${route.params.patientId}`
    const currentQuery = route.query

    return [
      {
        label: 'Care Plan',
        route: {
          path: `${basePath}/${TabMenuItems.CARE_PLAN}`,
          query: currentQuery,
        },
      },
      {
        label: 'Clinical Summary',
        route: {
          path: `${basePath}/${TabMenuItems.CLINICAL_SUMMARY}`,
          query: currentQuery,
        },
      },
      {
        label: 'Social Summary',
        route: {
          path: `${basePath}/${TabMenuItems.SOCIAL_SUMMARY}`,
          query: currentQuery,
        },
      },
      {
        label: 'History',
        route: {
          path: `${basePath}/${TabMenuItems.HISTORY}`,
          query: currentQuery,
        },
      },
      {
        label: 'To Do',
        route: {
          path: `${basePath}/${TabMenuItems.TO_DO}`,
          query: currentQuery,
        },
      },
      {
        label: 'Symptoms',
        route: {
          path: `${basePath}/${TabMenuItems.SYMPTOMS}`,
          query: currentQuery,
        },
      },
      {
        label: 'Texting',
        route: {
          path: `${basePath}/${TabMenuItems.TEXTING}`,
          query: currentQuery,
        },
        badge: openPointerSubtasksNum.value,
      },
      {
        label: 'Medical Context',
        route: {
          path: `${basePath}/${TabMenuItems.MEDICAL_CONTEXT}`,
          query: currentQuery,
        },
      },
      {
        label: 'Documents',
        route: {
          path: `${basePath}/${TabMenuItems.DOCUMENTS}`,
          query: currentQuery,
        },
      },
    ]
  })

  const currentTabFromUrl = computed(() => {
    const segments = route.path.split('/')
    const seg = segments[segments.length - 1]

    if (
      items.value.map((item) => item.route.path.split('/').pop()).includes(seg)
    ) {
      return seg
    } else {
      return 'care-plan'
    }
  })

  const currentTabIndexFromUrl = computed(() => {
    const tabRoutes = items.value.map((item) =>
      item.route.path.split('/').pop()
    )

    const idx = tabRoutes.indexOf(currentTabFromUrl.value)

    if (idx !== -1) {
      return idx
    } else {
      return 0
    }
  })

  const currentTabIndex = ref(currentTabIndexFromUrl.value)

  const { notification } = storeToRefs(useNotificationStore())
  const { patient, person, phoneNumbers, contacts, isLoading } = storeToRefs(
    usePatientStore()
  )

  const { isEditingSubtask } = useSubtasksSidebar()

  const { isEditingPathway, resetPathwayStores, resetPatientStores } =
    usePathwaySidebar()

  const { isViewingVisitNote } = useVisitNoteSidebar()
  const { isViewingVisitSummary } = useVisitSummarySidebar()
  const { isViewingReferrals } = useReferralSidebar()

  const {
    resetStores: resetCommsStores,
    isCreatingCommunication,
    editingCommunicationId,
  } = useCommsSidebar()

  const patientLoaded = computed(
    () => !!patient.value?.entityId && !!phoneNumbers.value && !!contacts.value
  )

  const commSidebarOpen = computed(
    () => isCreatingCommunication.value || !!editingCommunicationId.value
  )

  const activeSideBar = computed(() => {
    return commSidebarOpen.value
      ? 'CommunicationsSidebar'
      : isEditingPathway.value
      ? 'PathwaySidebar'
      : isEditingSubtask.value
      ? 'SubtaskSidebar'
      : isViewingReferrals.value
      ? 'ReferralsSidebar'
      : isViewingVisitSummary.value
      ? 'VisitSummarySidebar'
      : isViewingVisitNote.value
      ? 'VisitNoteSidebar'
      : null
  })

  onBeforeMount(async () => {
    if (activeSideBar.value === 'CommunicationsSidebar') {
      await resetCommsStores()
    } else if (activeSideBar.value === 'PathwaySidebar') {
      await resetPathwayStores()
    }
    await resetPatientStores()
    await getOpenPointerSubtasks(route.params.patientId as string)
  })

  // refetch member info when params change
  // for more info:
  // https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
  watch(
    () => route.params.patientId,
    async () => {
      if (route.params.patientId) {
        if (activeSideBar.value === 'CommunicationsSidebar') {
          await resetCommsStores()
        } else if (activeSideBar.value === 'PathwaySidebar') {
          await resetPathwayStores()
        } else {
          await resetPatientStores()
        }
      }
    }
  )

  watch(
    () => route.fullPath, // look at the full path of the route
    async (newPath) => {
      if (route.query.type === 'sms' && !newPath.includes('/texting')) {
        const newPathname = newPath.replace(/\/[^/]+$/, '/texting') // Update the path
        await router.replace({
          path: newPathname,
          query: route.query, // maintain all query parameters
        })
        const textingIndex = items.value.findIndex(
          (item) => item.label.toLowerCase() === 'texting'
        )
        currentTabIndex.value = textingIndex
      }
    },
    { immediate: true } // run the watcher immediately on component mount
  )

  /**
   *
   * @param tabIndex
   */
  function onTabChange(tabIndex: TabMenuChangeEvent) {
    currentTabIndex.value = tabIndex.index
  }

  /**
   *
   */
  function createCommunication() {
    void useCommunicationsStore().setIsCreating()
  }

  return {
    createCommunication,
    commSidebarOpen,
    TabMenuItems,
    currentTabFromUrl,
    onTabChange,
    currentTabIndex,
    items,
    isCreatingCommunication,
    notification,
    activeSideBar,
    editingCommunicationId,
    isLoading,
    patientLoaded,
    person,
  }
}
