import { NO_CACHE } from '@thyme/libs/src/api/types/api'
import omit from 'lodash/omit'
import { defineStore } from 'pinia'
import {
  normalize,
  toIdMap,
  normalTransform,
  updateNormalTransform,
  idMapTransform,
} from '@/legacy/libs/store'

import apiStore from '@/legacy/store/modules/apiBuilder'

import { usePracticeStore } from '@/legacy/store/modules/practice'
import { useProvidersApi } from '@/legacy/store/modules/provider'
import { apiMutations, initialApiState } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { ThymePhoneNumber } from '@/legacy/types/communications/thymePhoneNumbers'
import { Address } from '@/legacy/types/entities/addresses'
import type { Entity } from '@/legacy/types/entities/entities'
import type { Person } from '@/legacy/types/entities/people'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { Comorbidity } from '@/legacy/types/patients/comorbidities'
import { Contact } from '@/legacy/types/patients/contacts'
import { Diagnosis } from '@/legacy/types/patients/diagnoses'
import { Insurance } from '@/legacy/types/patients/insurances'
import { MapProviderPatient } from '@/legacy/types/patients/mapProvidersPatients'
import { OncologyTreatment } from '@/legacy/types/patients/oncologyTreatments'
import { Patient, PatientState } from '@/legacy/types/patients/patients'
import { Provider } from '@/legacy/types/providers'
import { useConfigStore } from './config'
import { useMapProvidersPatientsApi } from './mapProvidersPatients'
import { usePatientsApi } from './patients'
import {
  useMapEntityPhoneNumberApi,
  useMapEntityOtherContactPhoneNumberApi,
} from './phoneNumbers'

/**
 *
 * @param data
 */
function transformEntity(data: Entity): Partial<PatientState> {
  return normalTransform<PatientState>({}, 'entity', data, {
    addresses: 'addressId',
    phoneNumbers: 'phoneNumberId',
    labels: 'labelId',
  })
}

/**
 *
 * @param patientState
 * @param data
 */
function transformPatientProviders(
  patientState: Partial<PatientState>,
  data: MapProviderPatient[] | undefined
): Partial<PatientState> {
  return {
    ...omit(patientState, 'providers'),
    ...(data ? { providers: toIdMap(data, 'providerId') } : {}),
  }
}

/**
 *
 * @param data
 */
function transform(data: Patient): Partial<PatientState> {
  return {
    ...normalTransform({}, 'patient', omit(data, ['person', 'entity']), {
      contacts: 'contactId',
      insurances: 'insuranceId',
    }),
    ...transformEntity(data.entity),
    person: data.person,
  }
}

const initialState = (): PatientState => ({
  ...initialApiState(),
  addresses: null,
  contacts: null,
  entity: null,
  insurances: null,
  patient: null,
  person: null,
  phoneNumbers: null,
  providers: null,
})

export const usePatientStore = defineStore('patient', {
  state: initialState,
  getters: {
    treatmentStatusKey: (state): string | undefined =>
      state.patient &&
      state.patient.treatmentStatus &&
      state.patient.treatmentSubstatus
        ? `${state.patient.treatmentStatus}_${state.patient.treatmentSubstatus}`
        : undefined,
    programStatusKey: (state): string | undefined =>
      state.patient &&
      state.patient.programStatus &&
      state.patient.programSubstatus
        ? `${state.patient.programStatus}_${state.patient.programSubstatus}`
        : undefined,
    patientId: (state): string | undefined => state.patient?.entityId,
  },
  actions: {
    ...apiMutations<PatientState, Patient>(transform),

    updateEntity(entity: Entity) {
      Object.assign(this.$state, transformEntity(entity))
    },

    updateEmailAddress(emailAddress: string) {
      Object.assign(this.$state, {
        ...this.$state,
        entity: { ...this.$state.entity, email: emailAddress },
      })
    },

    updatePerson(person: Person) {
      Object.assign(this.$state, { person: person })
    },

    updateAddresses(addresses: Address[]) {
      Object.assign(
        this.$state,
        updateNormalTransform(
          this.$state,
          'entity',
          'addresses',
          'addressId',
          addresses
        )
      )
    },

    updatePhoneNumbers(phoneNumbers: MapEntityPhoneNumber[] | null) {
      Object.assign(
        this.$state,
        updateNormalTransform(
          this.$state,
          'patient',
          'phoneNumbers',
          'mapEntityPhoneNumberId',
          phoneNumbers ?? []
        )
      )
    },

    updateContacts(contacts: Contact[]) {
      Object.assign(
        this.$state,
        updateNormalTransform(
          this.$state,
          'patient',
          'contacts',
          'contactId',
          contacts
        )
      )
    },

    updatePatientProviders(patientProviders: MapProviderPatient[]) {
      Object.assign(
        this.$state,
        transformPatientProviders(this.$state, patientProviders)
      )
    },
    updateInsurancesInPlace(
      insuranceIdToRemove: string,
      insuranceToAdd: Insurance | undefined = undefined
    ) {
      const currentInsurances = this.$state.insurances ?? {}
      if (currentInsurances[insuranceIdToRemove]) {
        delete currentInsurances[insuranceIdToRemove]
      }
      if (insuranceToAdd) {
        currentInsurances[insuranceToAdd.insuranceId] = insuranceToAdd
      }
    },

    async fetchPhoneNumbers(patientId: string) {
      const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
        useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId
      if (patientId === TEXTING_UNKNOWN_NUMBERS_PATIENT_ID) {
        try {
          await useMapEntityPhoneNumberApi().listAll({
            params: { filter_entity_ids: [patientId] },
          })
        } catch (err) {
          console.error(err)
          if (err instanceof Error) {
            this.setError(err)
          }
          return
        }
      } else {
        try {
          await useMapEntityPhoneNumberApi().list({
            params: { filter_entity_ids: [patientId] },
          })
        } catch (err) {
          console.error(err)
          if (err instanceof Error) {
            this.setError(err)
          }
          return
        }
      }
      this.updatePhoneNumbers(
        Object.values(useMapEntityPhoneNumberApi().data ?? {})
      )
    },

    async fetchContacts(patientId: string) {
      let data: Contact[]
      try {
        const response = await useContactsApi().list({
          params: {
            filter_patient_ids: [patientId],
            parts: ['contact_entity.person', 'contact_entity.addresses'],
          },
        })
        data = response.data

        await Promise.all(
          data.map(async (contact) => {
            const contactPhoneNumbers =
              await useMapEntityOtherContactPhoneNumberApi().list({
                params: { filter_entity_ids: contact.contactEntity.entityId },
              })
            if (contactPhoneNumbers.data) {
              contact.contactEntity.phoneNumbers = contactPhoneNumbers.data
            }
          })
        )
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          this.setError(err)
        }
        return
      }
      this.updateContacts(data)
    },

    async fetchPatientProviders(patientId: string) {
      let data

      try {
        const resp = await useMapProvidersPatientsApi().listAll({
          params: { filter_patient_ids: [patientId] },
        })
        data = resp.data
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          this.setError(err)
        }
        return
      }

      this.updatePatientProviders(data)
      if (data?.length) {
        const fetchResp = await useProvidersApi().list({
          params: {
            filter_provider_ids: normalize(data, 'providerId'),
          },
        })
        const providers: Provider[] = fetchResp.data
        if (providers) {
          const practiceIds = Object.values(providers).reduce(
            (acc: string[], { practiceMaps }) =>
              practiceMaps
                ? [...acc, ...normalize(practiceMaps, 'practiceId')]
                : acc,
            []
          )
          if (practiceIds.length) {
            void usePracticeStore().fetchPractices(practiceIds, false)
          }
        }
      }
      return data
    },
    async fetchDiagnoses(patientId: string) {
      await useDiagnosesApi().listAll({
        params: {
          filter_patient_ids: [patientId],
        },
      })
    },

    async createDiagnosis(payload: Partial<Diagnosis>) {
      const created = await useDiagnosesApi().create({
        body: payload,
      })

      return created
    },

    async fetchComorbidities(patientId: string) {
      await useComorbiditiesApi().listAll({
        params: {
          filter_patient_ids: [patientId],
        },
      })
    },
    async updateComorbidity(patientId: string, payload: Partial<Comorbidity>) {
      const updated = await useComorbiditiesApi().partialUpdate({
        body: payload,
        ids: patientId,
      })
      return updated
    },
    async createComorbidity(payload: Partial<Comorbidity>) {
      const created = await useComorbiditiesApi().create({
        body: payload,
      })

      return created
    },
    async patientApiCall(patientId: string) {
      const parts = [
        'care_pod',
        'insurances',
        'entity.addresses',
        'latest_acuity_score',
        'pinned_acuity_score',
      ]

      if (this.patient?.entityId !== patientId) {
        this.loading()
      }

      let data
      try {
        data = await usePatientsApi().retrieve({
          headers: {
            'Cache-Control': NO_CACHE,
          },
          ids: [patientId],
          params: {
            parts,
          },
        })
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          this.setError(err)
        }
        return
      }
      this.success(data)
    },
    async fetchPatient(patientId: string) {
      await this.patientApiCall(patientId)
      await this.fetchContacts(patientId)
      void this.fetchPhoneNumbers(patientId)
      void this.fetchPatientProviders(patientId)
    },
  },
})

export const useDiagnosesApi = apiStore<Diagnosis, IdMap<Diagnosis>>(
  'diagnosesApi',
  '/api/patients/diagnoses',
  {
    transformData: (d: { data: Diagnosis[] }) =>
      idMapTransform({}, 'data', 'diagnosisId', d.data),
  }
)

export const useComorbiditiesApi = apiStore<Comorbidity, IdMap<Comorbidity>>(
  'comorbiditiesApi',
  '/api/patients/comorbidities',
  {
    transformData: (d: { data: Comorbidity[] }) =>
      idMapTransform({}, 'data', 'comorbidityId', d.data) as IdMap<Comorbidity>,
  }
)

export const useContactsApi = apiStore<Contact>(
  'contactsApi',
  '/api/patients/contacts',
  {
    transformData: (d: { data: Contact[] }) => d,
  }
)

export const useOutboundPhoneNumberApi = apiStore<ThymePhoneNumber>(
  'outboundPhoneNumberApi',
  '/api/thyme_phone_numbers'
)

export const useOncologyTreatmentApi = apiStore<OncologyTreatment>(
  'oncologyTreatmentsApi',
  '/api/patients/oncology_treatments',
  {
    transformData: (d: { data: OncologyTreatment[] }) => d,
  }
)

export const usePeopleApi = apiStore<Person, IdMap<Person>>(
  'peopleApi',
  '/api/people',
  {
    transformData: (d: { data: Person[] }) => ({
      data: toIdMap(d.data, 'entityId'),
    }),
  }
)
