import {
  FilterMetaType,
  ColumnOptions,
} from '@thyme/nashville/src/types/tables'
import { startCase } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { ref, Ref, computed } from 'vue'
import {
  getAvailableStatusOptions,
  isSubtaskOverdue,
  getSubtaskIconName,
  getPriorityIcon,
} from '@/legacy/components/patient/pathways/lib/subtask'
import {
  getSubtasksFromEnrollmentQueueStacks,
  enrollmentTableFilters,
  freeTextOptions,
  segmentColumn,
  callHistoryColumn,
} from '@/legacy/components/queue/lib/enrollmentQueueParts'
import {
  getScheduledCalls,
  getPreferredContactTimes,
  patientColumn,
  languageColumn,
  getTimingColumn,
  getSubtasksColumn,
} from '@/legacy/components/queue/lib/sharedQueueParts'
import {
  showHiddenSubtasksFilter,
  setupContractingEntityIdsFilter,
  callAttemptsFilter,
  enrollmentSegmentFilter,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { buildCommunicationUrl } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePayerApi } from '@/legacy/store/modules/payers'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  renderReattemptTooltip,
  renderReattemptTooltipForLatest,
} from '@/legacy/store/modules/reattempts'
import { useAssignedToMeEnrollmentQueueApiWithScheduledCalls } from '@/legacy/store/modules/subtasks'
import {
  Subtask,
  SubtaskStatus,
  SubtaskRowDisplayType,
  SubtaskPriority,
} from '@/legacy/types/pathways/subtasks'
import { Insurance } from '@/legacy/types/patients/insurances'
import { Stack } from '@/legacy/types/reminders'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const table = ref<{
    getData: () => object
    filterMeta: Ref<FilterMetaType>
  } | null>(null)

  /**
   * Refreshes data in the table.
   * Existing table filters will persist.
   */
  const getData = () => table.value?.getData()

  const onSubtaskStatusChanged = () => {
    void getData()
    context.emit('subtaskStatusChanged')
  }

  const { selfEntity: activeUser } = storeToRefs(useProfileStore())
  const { data: payers } = storeToRefs(usePayerApi())
  const { showEnrollmentQueueEnhancements } = storeToRefs(useFlagStore())
  const { sortEnrollmentQueueByToc, showHumanaDnc } = storeToRefs(
    useFlagStore()
  )
  const showReattemptModal = ref(false)
  const selectedReattemptSubtask = ref<Subtask | null>(null)
  const showSubStatusModal = ref(false)
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
  const selectedSubStatusSubtask = ref<Subtask | null>(null)
  const subtaskStatus = ref<SubtaskStatus | null>(null)

  /**
   * Toggles reattempt modal display state on modal close
   */
  function closeReattemptModal() {
    showReattemptModal.value = false
  }
  /**
   * Toggles reattempt modal display state and sets
   * selected subtask on reattempt modal open
   * @param subtask
   */
  function openReattemptModal(subtask: Subtask) {
    showReattemptModal.value = true
    selectedReattemptSubtask.value = subtask
  }

  /**
   * Function to show/open SubStatusModal modal
   * @param subtask
   * @param status
   */
  function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
    showSubStatusModal.value = true
    subtaskStatus.value = status
    selectedSubStatusSubtask.value = subtask
  }

  /**
   * Fetch additional details for each patient in the stack:
   * - subtasks
   * - preferred contact times
   * - scheduled calls
   * @param stacks list of patient Stack objects from table rows
   */
  const getSubtaskAndCommDetailsForPatientStack = async (stacks: Stack[]) => {
    await Promise.all([
      getSubtasksFromEnrollmentQueueStacks(
        stacks,
        false,
        activeUser.value?.entityId
      ),
      getScheduledCalls(stacks, false),
      getPreferredContactTimes(stacks),
    ])
  }

  /**
   * Fetch name of payer associated with an insurance
   * @param insurance
   */
  const displayPayerForInsurance = (insurance: Insurance) => {
    const payer = safeLookup(insurance.payerId, payers.value)
    return payer?.payerName
  }

  /**
   * Column in the queue table that shows payer names associated with
   * patient's insurances.
   * If multiple payers, the names are comma-separated.
   */
  const payerColumn: ColumnOptions = {
    header: 'Payer(s)',
    display: (_: any, row: Stack) => {
      const insurances = row.patient?.insurances
      const payerNames = insurances?.map(displayPayerForInsurance)
      return payerNames?.join(', ') ?? ''
    },
    style: { 'flex-wrap': 'wrap', width: '12%' },
  }

  const columns = computed<ColumnOptions[]>(() => [
    patientColumn,
    languageColumn,
    ...(showEnrollmentQueueEnhancements.value
      ? [segmentColumn]
      : [payerColumn]),
    getTimingColumn(false),
    ...(showEnrollmentQueueEnhancements.value ? [callHistoryColumn] : []),
    getSubtasksColumn(),
  ])

  const subtaskStatusOptions = (subtaskStatus: SubtaskStatus) =>
    getAvailableStatusOptions(subtaskStatus)

  const params = computed(() => ({
    filter_staff_ids: [activeUser.value?.entityId],
    filter_subtask_status: [
      SubtaskStatus.OPEN_ASSIGNED,
      SubtaskStatus.IN_PROGRESS,
      SubtaskStatus.ON_HOLD,
      SubtaskStatus.BLOCKED,
    ],
    ff_order_by_recent_toc: sortEnrollmentQueueByToc.value,
    ...{
      filter_due_before: showEnrollmentQueueEnhancements.value
        ? DateTime.now().plus({ days: 1 }).toISO()
        : {},
    },
    ...{
      filter_excluded_program_status: showHumanaDnc.value
        ? ['enrollment__do_not_call']
        : {},
    },
  }))
  const getFilters = () => ({
    ...enrollmentTableFilters(),
    contractingEntityIds: setupContractingEntityIdsFilter(),
    ...(showEnrollmentQueueEnhancements.value
      ? {
          includeHiddenSubtasks: showHiddenSubtasksFilter,
          callAttempts: callAttemptsFilter,
          enrollmentSegment: enrollmentSegmentFilter,
        }
      : {}),
  })

  const filters = computed(() => getFilters())

  return {
    filters,
    freeTextOptions,
    isSubtaskOverdue,
    openReattemptModal,
    SubtaskRowDisplayType,
    renderReattemptTooltip,
    renderReattemptTooltipForLatest,
    selectedReattemptSubtask,
    showReattemptModal,
    closeReattemptModal,
    showSurfacedStatusReason,
    subtaskStatus,
    showSubStatusModal,
    selectedSubStatusSubtask,
    openSubStatusModal,
    table,
    columns,
    params,
    subtaskStatusOptions,
    getSubtaskIconName,
    SubtaskStatus,
    getData,
    startCase,
    getPriorityIcon,
    SubtaskPriority,
    getSubtaskAndCommDetailsForPatientStack,
    onSubtaskStatusChanged,
    useAssignedToMeEnrollmentQueueApiWithScheduledCalls,
    buildCommunicationUrl,
  }
}
