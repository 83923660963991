import { PropType } from 'vue'
import { object, string, InferType } from 'yup'

export const inputSchema = object({
  formResponseId: string().required(),
})

export type ViewFormResponseForm = InferType<typeof inputSchema>

export const viewFormResponseFormProps = {
  submitFunction: {
    type: Function,
    required: true,
  },
  initialData: {
    type: Object as PropType<Partial<ViewFormResponseForm>>,
    required: true,
  },
} as const

export enum FormResponseStates {
  INITIAL = 'initial',
  LOADING = 'loading',
  ERROR = 'error',
  AWAITING_EMBED = 'awaitingEmbed',
  EMBEDDED = 'embedded',
}

export const FORMSORT_ACCOUNT_ID = 'GVGy7e-KFn'
export const FORM_EMBED_ROOT_ID = 'embedEl'
