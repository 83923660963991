export const editPatientSummaryProps = {
  isOpen: {
    type: Boolean,
    required: true,
  },
  summary: {
    type: String,
    required: true,
  },
} as const
