import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useFormResponseApi } from '@/pages/Tools/FormResponse/stores'

/**
 *
 * @param formResponseId
 */
export async function getFormResponse(formResponseId: string) {
  try {
    const res = await useFormResponseApi().list({
      params: {
        filter_form_response_ids: [formResponseId],
      },
    })
    return res.data
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching form response',
      type: NotificationType.WARNING,
      error: `Error fetching form response: ${err}`,
    })
    return null
  }
}
