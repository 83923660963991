/**
 * Takes a possibly null value and returns if null or not,
 * to be used as a type guard for filter, when type inference fails
 *
 * eg
 * ```
 *
 * fooList: Array<MyThing | null> = [. . .]
 * notNulls: Array<MyThing> = fooList.filter(isNotNull).filter(otherStuff)
 * ```
 * @param value
 */
export function isNotNull<T>(value: T | null): value is T {
  return value !== null
}
