import find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { IdMap } from '@/legacy/types/api/store'

/**
 * Pivots an array of objects on a given key.
 * @param obj
 * @param idKey
 */
export function toIdMap<T extends { [k: string | number | symbol]: any }>(
  obj: T[],
  idKey: string | number | symbol
) {
  return obj.reduce<IdMap<T>>((acc, x) => {
    acc[x[idKey]] = x
    return acc
  }, {})
}

/**
 * Pivots an array of objects on a given key.
 * Accumulates results
 * idKey = what you're keying on
 * uniqueKey = key which uniquely identifies data in case of conflict
 * initial = passed in if you would like to append to existing accumulated map
 * @param obj
 * @param idKey
 * @param uniqueKey
 * @param initial
 */
export function toAccIdMap<T extends { [k: string]: any }>(
  obj: T[],
  idKey: string,
  uniqueKey: string | null = null,
  initial: IdMap<T[]> = {}
) {
  return obj.reduce<IdMap<T[]>>((acc, x) => {
    if (
      acc[x[idKey]] &&
      uniqueKey &&
      find(acc[x[idKey]], [uniqueKey, x[uniqueKey]])
    ) {
      return acc
    } else if (acc[x[idKey]]) {
      acc[x[idKey]].push(x)
    } else {
      acc[x[idKey]] = [x]
    }
    return acc
  }, initial)
}

/**
 * Converts an array of objects to an array of IDs.
 * @param obj
 * @param idKey
 */
export function normalize<T extends { [k: string]: any }>(
  obj: T[],
  idKey: string
) {
  return obj.map((x) => x[idKey])
}

/**
 * Transforms state data and returns updated state with idMaps and normalized maps
 * Ex: ({}, 'key', { foo: [{ id: 'bar', fizz: 'buzz' }]}, { foo: 'id' })
 * Returns: Partial State {
 *  key: { foo: ['bar'] },
 *  foo: { id: 'bar', fizz: 'buzz' }
 * }
 * @param state
 * @param objectKey
 * @param objectToFlatten
 */
export function normalTransform<S extends { [key: string]: any }>(
  state: Partial<S>,
  objectKey: string,
  objectToFlatten: { [k: string]: any } | null,
  bucketToId: { [bucket: string]: string }
) {
  const keysToNormalize = Object.keys(bucketToId)

  return {
    ...omit(state, [...keysToNormalize, objectKey]),
    ...mapValues(pick(objectToFlatten, keysToNormalize), (v, k) =>
      toIdMap(v, bucketToId[k])
    ),
    [objectKey]: {
      ...omit(objectToFlatten, keysToNormalize),
      ...mapValues(pick(objectToFlatten, keysToNormalize), (v, k) =>
        normalize(v, bucketToId[k])
      ),
    },
  }
}

/**
 * Updates nested state data that is in the normalTransform format
 * Ex: (state, 'key', 'foo', 'id', [{ id: 'bar', fizz: 'buzz' }])
 * Returns: Updated normal map and idMaps in format specified by normalTransform based on new data
 * Extended input example: ({
 *  key: {
 *   foo: ['bizz']
 *  },
 *  foo: { bizz: { id: 'bizz', fizz: 'buzz' }}
 * }, 'key', 'foo', 'id', [{ id: 'bar', fizz: 'buzz' }])
 * Returns: Partial State {
 *  key: { foo: ['bar'] },
 *  foo: { id: 'bar', fizz: 'buzz' }
 * }
 * @param state
 * @param objectKey
 * @param key
 * @param keyId
 * @param newData
 */
export function updateNormalTransform<S extends { [key: string]: any }>(
  state: S,
  objectKey: string,
  key: string,
  keyId: string,
  newData: any[]
) {
  if (!objectKey || !state[objectKey]) {
    throw Error(`cannot set ${key} without ${objectKey}`)
  }
  return normalTransform(
    state,
    objectKey,
    { ...state[objectKey], [key]: newData },
    { [key]: keyId }
  )
}

/**
 * Basic transform converting a list of objects to an object of objects in the idMap format
 *
 * Example:
 *     const state = {
 *       users: {
 *         1: { id: 1, name: 'Alice' },
 *         2: { id: 2, name: 'Bob' }
 *       },
 *       otherData: 'someValue'
 *     }
 *
 *     const newData = [
 *       { id: 3, name: 'Charlie' },
 *       { id: 4, name: 'David' }
 *     ]
 *
 *     const transformedState = idMapTransform(state, 'users', 'id', newData)
 *
 *     console.log(transformedState)
 *     {
 *       users: {
 *         3: { id: 3, name: 'Charlie' },
 *         4: { id: 4, name: 'David' }
 *       },
 *       otherData: 'someValue'
 *    }
 * @param state
 * @param objectKey
 * @param idKey
 * @param newData
 */
export function idMapTransform<S extends { [key: string]: any }>(
  state: S,
  objectKey: string,
  idKey: string,
  newData: any[]
): IdMap<any> {
  return {
    ...omit(state, objectKey),
    [objectKey]: toIdMap(newData, idKey),
  }
}
