<template>
  <section>
    <div class="columns h-full is-marginless">
      <Transition>
        <TNotification v-if="notification" />
      </Transition>

      <div
        data-cy="patient-sidebar"
        class="column is-one-fifth bg-nash-purple50 border-r border-nash-neutral400 overflow-y-auto"
      >
        <PatientSidebar />
      </div>

      <div class="overflow-y-hidden overflow-x-hidden overflow-y-hidden w-full">
        <DuplicatePatientWarning
          v-if="person"
          class="sticky-banner"
          :patient-person="person"
        />

        <div
          class="flex flex-row justify-between items-center sticky-banner top-zero bg-nash-neutral000 p-3.5"
        >
          <TTabMenu
            :items="items"
            :current-tab-index="currentTabIndex"
            @tab-change="onTabChange"
          />
          <TMSecondaryButton
            :disabled="commSidebarOpen"
            label="+ Communication"
            name="add-communication"
            @click="createCommunication"
          />
        </div>

        <div class="columns h-full is-marginless">
          <div
            class="column overflow-y-auto overflow-x-hidden space-y-4 override-padding-top"
          >
            <CarePlanTab v-if="currentTabFromUrl === TabMenuItems.CARE_PLAN" />
            <ClinicalSummaryTab
              v-if="currentTabFromUrl === TabMenuItems.CLINICAL_SUMMARY"
            />
            <SocialSummary
              v-if="currentTabFromUrl === TabMenuItems.SOCIAL_SUMMARY"
            />
            <HistoryThymeline
              v-if="currentTabFromUrl === TabMenuItems.HISTORY"
            />
            <OpenThymeline v-if="currentTabFromUrl === TabMenuItems.TO_DO" />
            <SymptomsThymeline
              v-if="currentTabFromUrl === TabMenuItems.SYMPTOMS"
            />
            <TextingTab v-if="currentTabFromUrl === TabMenuItems.TEXTING" />
            <PatientMedical
              v-if="currentTabFromUrl === TabMenuItems.MEDICAL_CONTEXT"
            />
            <DocumentsThymeline
              v-if="currentTabFromUrl === TabMenuItems.DOCUMENTS"
            />
          </div>
          <div
            v-if="activeSideBar"
            class="column overflow-y-auto is-two-fifths border-l border-nash-neutral400"
          >
            <component
              :is="activeSideBar"
              v-if="activeSideBar && patientLoaded"
              :key="editingCommunicationId"
              :data-cy="activeSideBar"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TTabMenu from '@nashville/tabmenu/TTabMenu.vue'
import { defineComponent } from 'vue'
import CommunicationsSidebar from '@/legacy/components/patient/communicationV2/sidebar/CommunicationsSidebar/CommunicationsSidebar.vue'
import DuplicatePatientWarning from '@/legacy/components/patient/duplicatePatientWarning/DuplicatePatientWarning.vue'
import PatientMedical from '@/legacy/components/patient/medical/PatientMedical/PatientMedical.vue'
import PathwaySidebar from '@/legacy/components/patient/pathways/PathwaySidebar.vue'
import SubtaskSidebar from '@/legacy/components/patient/pathways/SubtaskSidebar.vue'
import ReferralsSidebar from '@/legacy/components/patient/referral/ReferralsSidebar.vue'
import PatientSidebar from '@/legacy/components/patient/sidebar/PatientSidebar.vue'
import DocumentsThymeline from '@/legacy/components/patient/thymeline/DocumentsThymeline.vue'
import HistoryThymeline from '@/legacy/components/patient/thymeline/HistoryThymeline/HistoryThymeline.vue'
import OpenThymeline from '@/legacy/components/patient/thymeline/OpenThymeline/OpenThymeline.vue'
import SymptomsThymeline from '@/legacy/components/patient/thymeline/symptoms/SymptomsThymeline.vue'
import VisitNoteSidebar from '@/legacy/components/patient/visitNote/VisitNoteSidebar.vue'
import VisitSummarySidebar from '@/legacy/components/patient/visitNote/VisitSummarySidebar.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'
import SocialSummary from '@/pages/PatientProfile/CarePlans/SocialSummary/SocialSummary.vue'
import CarePlanTab from '@/pages/PatientProfile/Tabs/CarePlanTab/CarePlanTab.vue'
import ClinicalSummaryTab from '@/pages/PatientProfile/Tabs/ClinicalSummaryTab/ClinicalSummaryTab.vue'
import TextingTab from '@/pages/PatientProfile/Tabs/TextingTab/TextingTab.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    // Nashville
    TMSecondaryButton,
    TTabMenu,
    TNotification,
    // Alerts
    DuplicatePatientWarning,
    // Sidebars
    PatientSidebar,
    SubtaskSidebar,
    PathwaySidebar,
    CommunicationsSidebar,
    VisitNoteSidebar,
    VisitSummarySidebar,
    ReferralsSidebar,
    // Tabs
    CarePlanTab,
    ClinicalSummaryTab,
    SocialSummary,
    HistoryThymeline,
    OpenThymeline,
    SymptomsThymeline,
    TextingTab,
    PatientMedical,
    DocumentsThymeline,
  },
  setup,
})
</script>
<style lang="scss">
.v-enter-active,
.v-leave-active {
  @apply transition ease-out;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.sticky-banner {
  position: sticky;
  top: 10px;
  z-index: 999;
}

.top-zero {
  top: 0px !important;
}

.override-padding-top {
  padding-top: 0 !important;
}
</style>
