import { requiredInject } from '@thyme/libs/src/vue/inject'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { useMedicalTeam } from '@/pages/PatientProfile/CarePlans/shared/variables'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditMedicalTeamForm } from './EditMedicalTeam/types'

// Defined only for convenience and use in testing.
export type MedicalTeamViewModel = {
  patientId: Ref<string>
  patientMedTeamId: Ref<string | undefined>
  lastOncologyAppt: Ref<string | null>
  nextOncologyAppt: Ref<string | null>
  lastUpdatedDisplay: Ref<string>
  oncologistName: Ref<string>
  surgeonName: Ref<string>
  radiationOncologistName: Ref<string>
  palliativeMedicineName: Ref<string>
  primaryCareName: Ref<string>
  additionalContext: Ref<string | null>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditMedicalTeamForm>>
  toggleMedicalTeamEdit: () => void
  refetchMedicalTeam: () => Promise<void>
}

/**
 *
 *
 */
export function setup(): MedicalTeamViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)

  const {
    medicalTeam,
    patientMedTeamId,
    // Form values
    lastOncologyApptValue,
    nextOncologyApptValue,
    oncologistNameValue,
    surgeonNameValue,
    radiationOncologistNameValue,
    palliativeMedicineNameValue,
    primaryCareNameValue,
    additionalContextValue,
    // Display values with undefined
    lastOncologyAppt: lastOncologyApptWithUndefined,
    nextOncologyAppt: nextOncologyApptWithUndefined,
    oncologistName: oncologistNameWithUndefined,
    surgeonName: surgeonNameWithUndefined,
    radiationOncologistName: radiationOncologistNameWithUndefined,
    palliativeMedicineName: palliativeMedicineNameWithUndefined,
    primaryCareName: primaryCareNameWithUndefined,
    additionalContext: additionalContextWithUndefined,
    refetchMedicalTeam,
  } = useMedicalTeam(patientId)

  const lastOncologyAppt = computed(
    () => lastOncologyApptWithUndefined.value ?? '-'
  )
  const nextOncologyAppt = computed(
    () => nextOncologyApptWithUndefined.value ?? '-'
  )
  const oncologistName = computed(
    () => oncologistNameWithUndefined.value ?? '-'
  )
  const surgeonName = computed(() => surgeonNameWithUndefined.value ?? '-')
  const radiationOncologistName = computed(
    () => radiationOncologistNameWithUndefined.value ?? '-'
  )
  const palliativeMedicineName = computed(
    () => palliativeMedicineNameWithUndefined.value ?? '-'
  )
  const primaryCareName = computed(
    () => primaryCareNameWithUndefined.value ?? '-'
  )
  const additionalContext = computed(
    () => additionalContextWithUndefined.value ?? null
  )

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(medicalTeam.value)
    return lastUpdatedAtStr ?? '-'
  })

  const editModalInitialData = computed(() => ({
    lastOncologyAppt: lastOncologyApptValue.value
      ? new Date(lastOncologyApptValue.value)
      : null,
    oncologistName: oncologistNameValue.value,
    radiationOncologistName: radiationOncologistNameValue.value,
    palliativeMedicineName: palliativeMedicineNameValue.value,
    nextOncologyAppt: nextOncologyApptValue.value
      ? new Date(nextOncologyApptValue.value)
      : null,
    primaryCareName: primaryCareNameValue.value,
    surgeonName: surgeonNameValue.value,
    additionalContext: additionalContextValue.value,
  }))

  /**
   * open/close edit modal
   */
  function toggleMedicalTeamEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(refetchMedicalTeam)

  return {
    patientId,
    patientMedTeamId,
    // display values
    lastUpdatedDisplay,
    lastOncologyAppt,
    nextOncologyAppt,
    oncologistName,
    surgeonName,
    radiationOncologistName,
    palliativeMedicineName,
    primaryCareName,
    additionalContext,
    // medical team edit modal
    toggleMedicalTeamEdit,
    showEditModal,
    editModalInitialData,
    refetchMedicalTeam,
  }
}
