import { PropType } from 'vue'
import { object, string, InferType, array, boolean } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

export const schema = object({
  fallInLastTwelveMonths: boolean().nullable(),
  fallInLastTwelveMonthsEnum: string().nullable(),
  fearOfFalling: boolean().nullable(),
  fearOfFallingEnum: string().nullable(),
  hasPeripheralNeuropathy: boolean().nullable(),
  sedatingMedications: array().of(string().required()).nullable(),
  otherSedatingMedications: string().nullable(),
  unsteadyWalkingOrStanding: boolean().nullable(),
  unsteadyWalkingOrStandingEnum: string().nullable(),
  usesDmeAssistForAmbulation: boolean().nullable(),
  anticoagulation: array().of(string().required()).nullable(),
  otherAnticoagulation: string().nullable(),
  additionalContext: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditFallRiskForm = InferType<typeof schema>

export const editFallRiskProps = {
  patientId: {
    type: String,
    required: true,
  },
  fallRiskId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditFallRiskForm>>,
    required: true,
  },
} as const
