<template>
  <div class="nav-report-wrapper">
    <div class="page-block">
      <div class="content-block">
        <div class="flex justify-between mb-6">
          <img class="w-48" :src="logo" alt="Thyme Care" />
          <TOPrintButton />
        </div>
        <h1>Care Plan Report | {{ titleDisplayDate }}</h1>
        <div>
          <b>Patient Name: </b>
          {{ formatName(person?.firstName, person?.lastName) }}
        </div>
        <div>
          <b>DOB: </b>
          {{ formatCleanDateTime(stringToDateTime(person?.dateOfBirth)) }}
        </div>
        <div class="subtext mt-2">
          This Thyme Care Plan is a summary of the three highest priority goals
          our team has identified to meet the needs of this patient and the
          associated interventions that the team has selected to accomplish
          these goals. With any questions, call Thyme Care at
          {{ READABLE_CARE_TEAM_PHONE }} or email {{ CARE_TEAM_EMAIL }}.
        </div>
      </div>

      <div class="content-block">
        <h2>Care Plan</h2>
        <div v-if="activeGoals && activeGoals.length" class="subtext">
          Last updated: {{ formatDateTime(stringToDateTime(latestUpdate)) }}
        </div>
        <div class="section-box space-x-8">
          <div>
            <div class="subtext">Treatment Status</div>
            <div>{{ treatmentStatus }}</div>
          </div>
          <div>
            <div class="subtext">Treatment Intent</div>
            <div>{{ treatmentIntent }}</div>
          </div>
          <div>
            <div class="subtext">Program Status</div>
            <div>{{ programStatus }}</div>
          </div>
        </div>
      </div>

      <div class="content-block">
        <div
          v-for="(goal, index) in activeGoals"
          :key="index"
          class="section-box flex-col items-start"
        >
          <h4>Goal {{ index + 1 }}</h4>
          <h3>
            {{ goal.freeTextTitle || goal.goalReference?.titleDisplay || '' }}
          </h3>
          <div>{{ goal.timeline }}</div>
          <div class="whitespace-pre-wrap">
            {{ goal.details }}
          </div>
          <div
            v-if="goalToPathwayMap[goal.goalId] && pathways"
            class="flex gap-4 w-full flex-wrap"
          >
            <PatientTreatmentPlans
              v-for="pathwayId in goalToPathwayMap[goal.goalId]"
              :key="pathwayId"
              class="!w-2/5"
              :pathway="pathways[pathwayId]"
              @click.capture.prevent.stop
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import get from 'lodash/get'
import map from 'lodash/map'
import { computed, defineComponent } from 'vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import { getDataByTimeframe } from '@/legacy/components/reporting/navigationReportData'
import { jsDateTimeToString, stringToDateTime } from '@/legacy/libs/date'
import {
  formatName,
  formatDateTime,
  formatDateTimeWithTime,
  formatCleanDateTime,
} from '@/legacy/libs/format'
import TOPrintButton from '@/legacy/nashville/button/TOPrintButton.vue'
import {
  CARE_TEAM_EMAIL,
  READABLE_CARE_TEAM_PHONE,
} from '@/legacy/types/global/thymeInfo'
import { GoalStatus } from '@/legacy/types/pathways/goals'
import {
  treatmentStatusMap,
  treatmentIntentMap,
  programStatusMap,
} from '@/legacy/types/patients/patients'

export default defineComponent({
  components: { TOPrintButton, PatientTreatmentPlans },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const logo = '/assets/images/ThymeCare-Logo-Wordmark-Plum.jpg'

    const { patient, person, pathways, goals, goalToPathwayMap } =
      getDataByTimeframe(props.patientId)

    const activeGoals = computed(() =>
      goals.value.filter((goal) => goal.status === GoalStatus.ACTIVE)
    )

    const titleDisplayDate = computed(() => {
      const today = jsDateTimeToString(new Date())
      return formatDateTime(stringToDateTime(today))
    })

    const treatmentStatus = computed(() =>
      get(
        treatmentStatusMap,
        `${patient.value?.treatmentStatus}.${patient.value?.treatmentSubstatus}`
      )
    )
    const treatmentIntent = computed(() =>
      get(treatmentIntentMap, `${patient.value?.treatmentIntent}`)
    )

    const programStatus = computed(() =>
      get(
        programStatusMap,
        `${patient.value?.programStatus}.${patient.value?.programSubstatus}`
      )
    )

    const latestUpdate = computed(
      () =>
        map(goals.value ?? [], 'updatedAt').sort((a, b) =>
          b.localeCompare(a)
        )[0]
    )

    return {
      goals,
      goalToPathwayMap,
      latestUpdate,
      logo,
      pathways,
      patient,
      person,
      titleDisplayDate,
      treatmentStatus,
      treatmentIntent,
      programStatus,
      activeGoals,
      jsDateTimeToString,
      formatName,
      formatDateTime,
      formatCleanDateTime,
      formatDateTimeWithTime,
      stringToDateTime,
      READABLE_CARE_TEAM_PHONE,
      CARE_TEAM_EMAIL,
    }
  },
})
</script>

<style lang="scss">
.nav-report-wrapper {
  @apply flex flex-col items-center h-full;
}

.subtext {
  @apply text-nash-neutral700 text-sm;
}

.content-block {
  @apply mb-6;
}

.section-box {
  @apply flex py-4 border-t border-solid border-nash-neutral300 break-inside-avoid;
}

.page-block {
  @apply bg-nash-neutral000 p-16 print:p-0 w-9/12 print:w-full max-w-4xl print:max-w-full print:block;
}
</style>
