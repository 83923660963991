<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Fall Risk"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <TMDropdownField
            name="fallInLastTwelveMonthsEnum"
            label="Fall In The Last 12 Months"
            class="w-full my-2"
            :options="fallRiskResponseOptions"
            option-label="label"
            option-value="value"
            :model-value="initialData.fallInLastTwelveMonthsEnum ?? undefined"
          />
          <TMDropdownField
            name="fearOfFallingEnum"
            class="w-full my-2"
            label="Fear Of Falling"
            option-label="label"
            option-value="value"
            :options="fallRiskResponseOptions"
            :model-value="initialData.fearOfFallingEnum ?? undefined"
          />
          <TMDropdownField
            name="hasPeripheralNeuropathy"
            label="Peripheral Neuropathy"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
            :model-value="initialData.hasPeripheralNeuropathy ?? undefined"
          />
          <TMDropdownField
            name="sedatingMedications"
            label="Sedating Medications"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="sedatingMedicationsOptions"
            :show-clear="false"
            :model-value="initialData.sedatingMedications ?? undefined"
            @update:model-value="setShowOtherSedatingMedication"
          />
          <TInputText
            v-if="showSedatingOtherInput"
            label="Other Sedating Medications"
            name="otherSedatingMedications"
            :model-value="initialData.otherSedatingMedications"
          />
        </div>
        <div>
          <TMDropdownField
            name="unsteadyWalkingOrStandingEnum"
            label="Unsteady With Standing or Walking"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="fallRiskResponseOptions"
            :model-value="
              initialData.unsteadyWalkingOrStandingEnum ?? undefined
            "
          />
          <TMDropdownField
            name="usesDmeAssistForAmbulation"
            label="Uses DME to Assist with Ambulation"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
            :model-value="initialData.usesDmeAssistForAmbulation ?? undefined"
          />
          <TMDropdownField
            name="anticoagulation"
            label="Anticoagulation"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :show-clear="false"
            :multiple="true"
            :options="anticoagulationOptions"
            :model-value="initialData.anticoagulation ?? undefined"
            @update:model-value="setShowOtherAnticoagulation"
          />
          <TInputText
            v-if="showAnticoagulationOtherInput"
            label="Other Anticoagulation"
            name="otherAnticoagulation"
            :model-value="initialData.otherAnticoagulation"
          />
        </div>
      </div>

      <TTextarea
        label="Additional Context"
        name="additionalContext"
        :model-value="initialData.additionalContext ?? ''"
      />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="fall-risk-cancel"
          @click="close"
        />
        <TMPrimaryButton name="fall-risk-save" label="Save" @click="onSubmit" />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editFallRiskProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TInputText,
    TMSecondaryButton,
  },
  props: editFallRiskProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
