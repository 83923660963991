<template>
  <div class="overflow-visible card-style w-full">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row space-x-4 items-center">
            <h3 class="font-medium py-2 print-h3">Offboarding Summary</h3>
          </div>
          <div class="flex flex-row">
            <div v-tooltip.left="'Edit social summary'">
              <TMSecondaryButton
                icon="pencil"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleOffboardingSummaryEdit"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 flex flex-row">
          <div class="w-full">
            <div class="grid-container">
              <label class="t-grid-item">Reason for Offboarding</label>
              <div class="t-grid-item">
                {{ reasonOffboardingDisplay }}
              </div>
              <label class="t-grid-item">Reengagement Attempts</label>
              <TMarkdown
                v-if="reengagementAttemptsDescriptionDisplay"
                class="t-grid-item whitespace-pre-wrap"
                :source="reengagementAttemptsDescriptionDisplay"
              />
              <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
              <label class="t-grid-item">Additional Details</label>
              <TMarkdown
                v-if="additionalDetailsDisplay"
                class="t-grid-item whitespace-pre-wrap"
                :source="additionalDetailsDisplay"
              />
              <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import setup from './controller'
import { ediOffboardingSummaryProps } from './types'

export default defineComponent({
  components: {
    TMSecondaryButton,
    TMarkdown,
  },
  props: ediOffboardingSummaryProps,
  emits: ['toggleOffboardingSummaryEdit'],
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
