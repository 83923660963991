<template>
  <div class="flex flex-col p-3">
    <div class="mb-2 flex items-end flex-wrap space-y-2">
      <!--  // CLEANUP MT-3032-->
      <ContractingChip
        v-if="patientContract"
        :key="contractingChipKey"
        :contract-id="patientContract.contractExternalId"
        :contracting-entity-name="patientContract.contractingEntity.displayName"
        class="whitespace-nowrap"
      />
      <TChip
        :label="languageString"
        icon="language"
        icon-fill="nash-tigerlily600"
        icon-pos="left"
      />
      <TChip v-if="acuityScoreText" :label="acuityScoreText" />
      <TChip
        v-if="doNotContact && showHumanaDnc"
        :icon="IconifyIcons.phoneXMark"
        icon-fill="nash-brick600"
        icon-pos="left"
        :label="DNC_STRING"
      />
    </div>
    <PatientExternalLinks :patient-id="patientId" />
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 my-2"
    >
      <h4>Member Details</h4>
      <TMTertiaryButton
        name="edit-member-details"
        icon="pencil"
        @click="toggleEditPatientDetailsModal"
      />
    </div>
    <div class="flex flex-col space-y-0.5">
      <p>
        <span class="italic">Pod:</span>
        {{ carePodString }}
      </p>
      <p>
        <span class="italic">DOB:</span>
        {{ dobDateString }} ({{ age }} years old)
      </p>
      <p v-if="dodDateString">
        <span class="italic">DOD:</span>
        {{ dodDateString }}
      </p>
      <p>
        <span class="italic">Gender:</span>
        {{ genderString }}
      </p>
      <p v-if="enrollmentSegment">
        <span class="italic">Enrollment Segment:</span>
        {{ enrollmentSegment }}
      </p>
      <p v-if="memberAppAccessString">
        <span class="italic">{{ memberAppAccessString }}</span>
      </p>
    </div>
    <EditPatientDetails
      v-if="showEditPatientDetails"
      :patient-id="patientId"
      :patient-contract-id="patientContract?.patientContractId"
      :initial-data="editModalInitialData"
      @close="toggleEditPatientDetailsModal"
      @refresh-contracting-chip="refreshContractingChip"
    />
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import TChip from '@/legacy/nashville/chip/TChip.vue'
import ContractingChip from '@/pages/PatientProfile/PatientSidebar/PatientDetails/ContractingChip/ContractingChip.vue'
import EditPatientDetails from '@/pages/PatientProfile/PatientSidebar/PatientDetails/EditPatientDetails/EditPatientDetails.vue'
import PatientExternalLinks from '@/pages/PatientProfile/PatientSidebar/PatientDetails/PatientExternalLinks/PatientExternalLinks.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    PatientExternalLinks,
    EditPatientDetails,
    TMTertiaryButton,
    TChip,
    ContractingChip,
  },
  setup,
})
</script>
