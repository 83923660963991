import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { ExtractPropTypes, ref } from 'vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { booleanOptions } from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  transportationBarrierOptions,
  financialBarrierOptions,
  inHomeSupportOptions,
  housingBarrierOptions,
  socialSupportOptions,
  foodBarrierOptions,
  caregiverMappingOptions,
  benefitsProgramsServicesOptions,
  DirtySocialSummary,
  editSocialOrOffboardingSummaryProps,
} from '@/pages/PatientProfile/CarePlans/SocialSummary/types'
import { createSocialSummary, updateSocialSummary } from './queries'
import { EditSocialSummaryForm, schema } from './types'

type PropsType = ExtractPropTypes<typeof editSocialOrOffboardingSummaryProps>

/**
 *
 * @param props
 * @param context
 */
export default function (props: PropsType, context: any) {
  const dirtyModel = ref<DirtySocialSummary>({
    transportationBarrier:
      props.patientSocialSummary?.transportationBarrier ?? [],
    foodBarrier: props.patientSocialSummary?.foodBarrier ?? [],
    housingBarrier: props.patientSocialSummary?.housingBarrier ?? [],
    financialBarrier: props.patientSocialSummary?.financialBarrier ?? [],
    inHomeSupport: props.patientSocialSummary?.inHomeSupport ?? [],
    caregiverMapping: props.patientSocialSummary?.caregiverMapping ?? [],
    socialSupport: props.patientSocialSummary?.socialSupport ?? [],
    benefitsProgramsServices:
      props.patientSocialSummary?.benefitsProgramsServices ?? [],
    healthcareProxy: props.patientSocialSummary?.healthcareProxy ?? undefined,
    additionalContext: props.patientSocialSummary?.additionalContext ?? '',
  })

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param values
   */
  function setSocialSummaryPayload(values: EditSocialSummaryForm) {
    return {
      transportationBarrier: values.transportationBarrier?.length
        ? values.transportationBarrier
        : null,
      foodBarrier: values.foodBarrier?.length ? values.foodBarrier : null,
      housingBarrier: values.housingBarrier?.length
        ? values.housingBarrier
        : null,
      financialBarrier: values.financialBarrier?.length
        ? values.financialBarrier
        : null,
      inHomeSupport: values.inHomeSupport?.length ? values.inHomeSupport : null,
      caregiverMapping: values.caregiverMapping?.length
        ? values.caregiverMapping
        : null,
      socialSupport: values.socialSupport?.length ? values.socialSupport : null,
      benefitsProgramsServices: values.benefitsProgramsServices?.length
        ? values.benefitsProgramsServices
        : null,
      healthcareProxy: values.healthcareProxy ?? null,
      additionalContext: values.additionalContext?.length
        ? values.additionalContext
        : null,
      patientId: props.patientId,
    }
  }

  const { handleSubmit } = useForm({
    initialValues: dirtyModel.value,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   */
  async function saveSocialSummary(values: EditSocialSummaryForm) {
    const payload = setSocialSummaryPayload(values)
    if (!props.patientSocialSummary) {
      await createSocialSummary(payload)
    } else {
      await updateSocialSummary(
        payload,
        props.patientSocialSummary.socialSummaryId
      )
    }
    context.emit('refetchSocialSummary')
    thymeDispatch('care-planV2-update')
    close()
  }

  const onSubmit = handleSubmit(saveSocialSummary)

  return {
    onSubmit,
    transportationBarrierOptions,
    financialBarrierOptions,
    inHomeSupportOptions,
    housingBarrierOptions,
    socialSupportOptions,
    foodBarrierOptions,
    booleanOptions,
    caregiverMappingOptions,
    benefitsProgramsServicesOptions,
    close,
    saveSocialSummary,
    ModalSize,
    dirtyModel,
  }
}
