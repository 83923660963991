import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { FormResponse } from '@/legacy/types/forms/formSort'

export const useFormResponseApi = apiStore<FormResponse, IdMap<FormResponse>>(
  'formResponseApi',
  '/api/forms/responses',
  {
    transformData: (d: { data: FormResponse[] }) =>
      idMapTransform({}, 'data', 'formResponseId', d.data),
  }
)
