import { object, string, InferType } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

export const reasonOffboardingOptions = [
  {
    label: 'Admission to Hospice',
    value: 'ADMISSION_TO_HOSPICE',
  },
  {
    label: 'Change in Benefit Plan',
    value: 'CHANGE_IN_BENEFIT_PLAN',
  },
  {
    label: 'Change in Insurance Plan',
    value: 'CHANGE_IN_INSURANCE_PLAN',
  },
  {
    label: 'Change in Medicare EOM Program',
    value: 'CHANGE_IN_MEDICARE_EOM_PROGRAM',
  },
  {
    label: 'Change in Provider',
    value: 'CHANGE_IN_PROVIDER',
  },
  {
    label: 'Deceased',
    value: 'DECEASED',
  },
  {
    label: 'Decision to Opt Out',
    value: 'DECISION_TO_OPT_OUT',
  },
  {
    label: 'Disengagement From Services',
    value: 'DISENGAGEMENT_FROM_SERVICES',
  },
  {
    label: 'Non-Covered Diagnosis',
    value: 'NON_COVERED_DIAGNOSIS',
  },
  {
    label: 'Non-Qualifying Medication or Treatment',
    value: 'NON_QUALIFYING_MEDICATION_OR_TREATMENT',
  },
  {
    label:
      'Relocation to a Region That is Not Presently Supported by Thyme Care',
    value:
      'RELOCATION_TO_A_REGION_THAT_IS_NOT_PRESENTLY_SUPPORTED_BY_THYME_CARE',
  },
].sort((a, b) => a.label.localeCompare(b.label))

export type DirtyOffboardingSummary = {
  reengagementAttemptsDescription: string | undefined | null
  reasonOffboarding: string | undefined | null
  offboardingAdditionalDetails: string | undefined | null
}

export const schema = object({
  reasonOffboarding: string().nullable(),
  reengagementAttemptsDescription: string().nullable(),
  offboardingAdditionalDetails: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditOffboardingSummaryForm = InferType<typeof schema>
