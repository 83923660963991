<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 overflow-y-auto overflow-x-hidden"
    @mousedown.self="close"
  >
    <div
      data-cy="modal"
      :class="[
        `${
          isWarning ? 'bg-nash-tigerlily100' : 'bg-nash-neutral000'
        } shadow rounded-lg ${
          $slots.actions ? 'px-5 pt-5' : 'p-5'
        } space-y-2 relative`,
        ...modalClasses,
        allowOverflow ? 'overflow-y-auto' : '',
      ]"
    >
      <div
        v-if="title"
        data-cy="modal-title"
        :class="`flex justify-between items-center ${
          isWarning ? 'pb-1' : 'pb-5'
        }`"
      >
        <div class="flex items-center">
          <slot name="preTitle"></slot>
          <TIcon
            v-if="titleIcon"
            :icon="titleIcon"
            :color="titleIconColor"
            class="mr-2"
            width="20"
            height="20"
          />
          <h4 :class="`font-${isWarning ? 'bold' : 'normal'} !important`">
            {{ title }}
          </h4>
        </div>
        <TIcon icon="close" class="cursor-pointer" @click="close" />
      </div>

      <slot></slot>

      <div
        v-if="$slots.actions"
        class="bg-nash-neutral000 flex justify-end space-x-2 sticky bottom-0 right-0 pb-5 z-0"
      >
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ColorPalettes } from '@thyme/nashville/src/types/shared'
import { createProp } from '@thyme/nashville/src/utils/designTokens'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  watch,
} from 'vue'
import { thymeListen, thymeTuneOut } from '@/legacy/libs/eventBus'

export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<ModalSize>,
      default: ModalSize.SM,
      validator: (v: string) => Object.values<string>(ModalSize).includes(v),
    },
    allowOverflow: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    titleIcon: createProp(AllowedIcons),
    titleIconColor: createProp(ColorPalettes),
  },
  emits: ['close', 'open'],
  setup(props, context) {
    onMounted(() => {
      thymeListen('keydown', closeOnEsc)
    })
    onBeforeMount(() => {
      thymeTuneOut('keydown', closeOnEsc)
    })

    /**
     * Event listener to close the modal when ESC is pressed.
     * @param event
     */
    function closeOnEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        close()
      }
    }

    /**
     * Closes the modal.
     */
    function close() {
      context.emit('close')
    }

    /**
     *
     * @param isVisible
     */
    function open(isVisible: boolean) {
      if (isVisible) {
        context.emit('open')
      }
    }
    watch(() => props.isVisible, open)
    open(props.isVisible)

    const modalClasses = computed(() => {
      return [
        ...(props.size === ModalSize.SM ? ['w-1/4', 'max-h-3/4'] : []),
        ...(props.size === ModalSize.MD ? ['w-3/4 lg:w-1/2 max-h-95pct'] : []),
        ...(props.size === ModalSize.LG
          ? ['w-3/4 lg:w-1/2 max-h-856 h-5/6 overflow-auto']
          : []),
        ...(props.flex ? ['flex flex-col'] : []),
      ]
    })

    return {
      modalClasses,
      close,
    }
  },
})
</script>
