import apiStore from '@/legacy/store/modules/apiBuilder'
import { HumanaEligibilityData } from './types'

export const useHumanaEligibilityApi = apiStore<HumanaEligibilityData>(
  'humanaEligibilityApi',
  '/api/humana',
  {
    transformData: (d: { data: HumanaEligibilityData[] }) => d,
  }
)
