import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { SocialSummaryFields } from '../SocialSummary/types'

/**
 *
 * @param arr
 * takes a string array and sorts and formats it into a string
 * @param doNotMutateStr
 */
export function arrayToStringDisplay(arr: string[], doNotMutateStr = false) {
  let stringDisplay = ''
  const sorted = arr.sort()
  sorted.map((val, index) => {
    if (doNotMutateStr) {
      stringDisplay += val
    } else {
      stringDisplay += startCase(camelCase(val))
    }
    if (index < arr.length - 1) {
      // separate each value with a bullet point
      stringDisplay += ' • '
    }
  })
  return stringDisplay
}

/**
 *
 * @param str
 * takes a string and replaces commas with a bullet point
 */
export function formatStringDisplay(str: string) {
  return str.replace(/,/g, ' • ')
}

/**
 *
 * @param val
 * @param options
 * @param enumType
 */
export function getLabels(
  val: string,
  options: { value: any; label: string }[],
  enumType: SocialSummaryFields
) {
  const option = options.find(
    (option) => option.value === enumType[val as keyof typeof enumType]
  )
  return option ? option.label : null
}
