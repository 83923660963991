<template>
  <div class="relative">
    <div class="flex items-center justify-between">
      <h2 v-if="showTabbedPatientProfile" class="font-medium py-2 print-h3">
        To Do
      </h2>
      <TMSecondaryButton
        v-if="!showTabbedPatientProfile"
        class="add-comms-btn"
        :disabled="isCreating || !!queryCommId"
        label="+ Communication"
        name="add-communication"
        @click="createCommunication"
      />
    </div>
    <div class="flex space-x-4">
      <TDropdown
        :options="freeTextOptions"
        :initial-value="freeTextFilterName"
        :show-clear="false"
        class="bg-nash-neutral000 h-1/4 mt-5"
        option-label="label"
        option-value="value"
        @update:model-value="setFreeTextFilterName"
      />
      <TSearchAndFilter
        search-placeholder="Search"
        class="mt-5 w-1/3"
        @update-search="updateSearch"
      />
    </div>
    <div class="space-y-4">
      <!-- In Progress thymeline -->
      <TMThymeline
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-InProgressThymelineV2`"
        title="In Progress"
        :rows="perPage"
        :columns="columns"
        :params="inProgressParams"
        :api-store="useInProgressThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.subtask && !item.communication && !item.toc">
          <span class="text-nash-brick600">ERROR:</span>Item not loaded.
        </div>
        <SubtaskRow
          v-else-if="item.subtask"
          class="w-full"
          :subtask="item.subtask"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="isOverdue(item.subtask)"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.communication"
          class="w-full"
          :communication="item.communication"
          :patient-id="patientId"
          name="communications"
        />
      </TMThymeline>

      <!-- Open thymeline -->
      <TMThymeline
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-OpenThymelineV2`"
        title="Open"
        :rows="perPage"
        :columns="columns"
        :params="openParams"
        :api-store="useOpenThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.subtask && !item.communication && !item.toc">
          <span class="text-nash-brick600">ERROR:</span>Item not loaded.
        </div>
        <SubtaskRow
          v-else-if="item.subtask"
          class="w-full"
          :subtask="item.subtask"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="isOverdue(item.subtask)"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.communication"
          class="w-full"
          :communication="item.communication"
          :patient-id="patientId"
          name="communications"
        />
      </TMThymeline>

      <!-- Blocked tasks thymeline -->
      <LegacyTButton
        type="tertiary"
        class="pt-2"
        icon-position="right"
        :icon="showBlocked ? 'chevronUp' : 'chevronDown'"
        @click="showBlocked = !showBlocked"
        >{{ showBlocked ? 'Hide Blocked' : 'Show Blocked' }}</LegacyTButton
      >
      <TMThymeline
        v-if="showBlocked"
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-BlockedThymelineV2`"
        title="Blocked/On Hold"
        :rows="perPage"
        :columns="columns"
        :params="blockedParams"
        :api-store="useBlockedThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.subtask && !item.communication && !item.toc">
          <span class="text-nash-brick600">ERROR:</span>Item not loaded.
        </div>
        <SubtaskRow
          v-else-if="item.subtask"
          class="w-full"
          :subtask="item.subtask"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.communication"
          class="w-full"
          :communication="item.communication"
          :patient-id="patientId"
          name="communications"
        />
      </TMThymeline>
      <SubtaskReattemptModal
        v-if="selectedReattemptSubtask"
        :subtask="selectedReattemptSubtask"
        :is-open="showReattemptModal"
        @close="closeReattemptModal"
      />
      <SubtaskSubstatusModal
        v-if="
          showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
        "
        :subtask="selectedSubStatusSubtask"
        :status="subtaskStatus"
        :is-open="showSubStatusModal"
        @close="showSubStatusModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { defineComponent } from 'vue'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'

import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import CommunicationsRow from '@/legacy/components/patient/thymeline/CommunicationsRow/CommunicationsRow.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'

import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TMThymeline,
    SubtaskReattemptModal,
    LegacyTButton,
    SubtaskRow,
    CommunicationsRow,
    TMSecondaryButton,
    TSearchAndFilter,
    TDropdown,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.add-comms-btn {
  position: absolute;
  top: -40px;
  right: 0;
}
</style>
