import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useFieldArray, useForm } from 'vee-validate'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import {
  OTHER_OPTION,
  OTHER_STRING,
} from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import { useCancerDetailApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  createSurgeries,
  getMapSurgicalProcedureReferences,
  updateSurgery,
} from './queries'
import { useMapOncDxRefSurgicalProcedureRefApi } from './store'
import {
  AddOrEditSurgeriesForm,
  AddOrEditSurgeryForm,
  addOrEditSurgeryProps,
  listSurgerySchemas,
} from './types'

type PropsType = ExtractPropTypes<typeof addOrEditSurgeryProps>

/**
 * Set up the AddOrEditSurgery component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: surgeryRefsData } = storeToRefs(
    useMapOncDxRefSurgicalProcedureRefApi()
  )
  const { data: cancerDetailsData } = storeToRefs(useCancerDetailApi())

  const cancerDetails = computed(() => cancerDetailsData.value?.[0] ?? null)

  const oncDxRefId = computed(() => {
    return cancerDetails.value?.oncologyDiagnosisRefId
  })
  const surgeryOptions = computed(() => {
    if (surgeryRefsData.value) {
      const surgeryArr = surgeryRefsData.value
        .map((surgeryRef) => ({
          value: surgeryRef.surgicalProcedureRefId,
          label: surgeryRef.surgicalProceduresReference.description,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label))
      return [...surgeryArr, ...OTHER_OPTION]
    }
    return []
  })
  const modalTitle = computed(() => `${props.showAdd ? 'Add' : 'Edit'} Surgery`)
  const defaultForm = () => ({
    otherSurgeryType: null,
    surgicalProcedureRefId: null,
    surgeryDate: null,
    cancerDiagnosisId: props.cancerDiagnosisId,
    removed: false,
  })

  const surgeryPayloads = ref<AddOrEditSurgeryForm[]>([
    // @ts-ignore
    props.showAdd
      ? defaultForm()
      : {
          ...props.initialData,
          surgicalProcedureRefId: props.initialData.otherSurgeryType
            ? OTHER_STRING
            : props.initialData.surgicalProcedureRefId,
        },
  ])

  const showSurgeryDropdown = ref(
    cancerDetails.value?.oncologyDiagnosisRefId ??
      !cancerDetails.value?.otherOncologyDxType
  )

  const { handleSubmit, setFieldValue } = useForm({
    initialValues: { surgeryPayload: surgeryPayloads.value },
    validationSchema: toTypedSchema(listSurgerySchemas),
  })

  const { fields, push, update } =
    useFieldArray<AddOrEditSurgeryForm>('surgeryPayload')

  /**
   *
   * set other surgery field to empty string to render
   * free text input for other type.
   *
   * also sets appropriate val for surgicalProcedureRefId
   * for proper dropdown value to render.
   * @param val
   * @param index
   * if other option is selected, set otherSurgeryType to empty string
   * to trigger rendering of TextInput
   * else otherSurgeryType should be null so that otherSurgeryType does not show
   */
  function setSurgeryField(val: string, index: number) {
    if (val === OTHER_STRING) {
      setFieldValue(`surgeryPayload.${index}.otherSurgeryType`, '')
    } else {
      setFieldValue(`surgeryPayload.${index}.otherSurgeryType`, null)
    }
    setFieldValue(`surgeryPayload.${index}.surgicalProcedureRefId`, val)
  }

  /**
   * add another surgery payload
   */
  function addSurgeryForm() {
    // @ts-ignore
    push(defaultForm())
  }

  /**
   *
   * @param index
   * using remove will mess with the link between each element
   * and it's associated index
   * band-aid solution is to mark payload
   * to be removed and delete it before making api call
   */
  function removeSurgeryForm(index: number) {
    update(index, { removed: true })
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param surgeryPayloads
   * @param isEdit
   */
  function cleanupSurgeryPayloads(
    surgeryPayloads: AddOrEditSurgeriesForm,
    isEdit = false
  ) {
    const surgeryPayloadsCopy = { ...surgeryPayloads }
    // remove payloads marked to be removed
    surgeryPayloadsCopy.surgeryPayload =
      surgeryPayloadsCopy.surgeryPayload.filter((surgery) => !surgery.removed)

    // then update the payload values
    surgeryPayloadsCopy.surgeryPayload.forEach((surgery) => {
      delete surgery.removed

      if (isEdit) {
        delete surgery.cancerDiagnosisId
      }
      if (surgery.otherSurgeryType) {
        surgery.surgicalProcedureRefId = null
      }
      if (surgery.surgicalProcedureRefId) {
        surgery.otherSurgeryType = null
      }
    })

    return surgeryPayloadsCopy
  }

  /**
   *
   * @param values
   */
  async function editSurgery(values: AddOrEditSurgeriesForm) {
    const cleanedSurgeryPayloads = cleanupSurgeryPayloads(values, true)
    // make update call
    try {
      await updateSurgery(
        props.surgeryId,
        cleanedSurgeryPayloads.surgeryPayload[0]
      )
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update surgery.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  /**
   *
   * @param values
   */
  async function addSurgery(values: AddOrEditSurgeriesForm) {
    const cleanedSurgeryPayloads = cleanupSurgeryPayloads(values)
    try {
      await createSurgeries(cleanedSurgeryPayloads.surgeryPayload)
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to add surgery.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  const onAddSubmit = handleSubmit(addSurgery)
  const onEditSubmit = handleSubmit(editSurgery)

  onBeforeMount(async () => {
    if (oncDxRefId.value) {
      await getMapSurgicalProcedureReferences(oncDxRefId.value)
    }
  })

  return {
    ModalSize,
    modalTitle,
    surgeryPayloads,
    removeSurgeryForm,
    addSurgeryForm,
    close,
    fields,
    surgeryOptions,
    setSurgeryField,
    showSurgeryDropdown,
    // onSubmit,
    onAddSubmit,
    onEditSubmit,
  }
}
