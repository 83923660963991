import { ColumnProps } from 'primevue/column'
import { DataTableFilterMetaData } from 'primevue/datatable'
import { DropdownKV } from './dropdowns'

// TYPES --------------------
export type ColumnOptions = {
  alwaysVisible?: boolean
  display?: (...args: any) => string
  displayData?: (...args: any) => any
  inputAttrs?: { [key: string]: any }
  label?: string
  link?: string | { [key: string]: any }
  linkFn?: (column: ColumnOptions, row: any) => ColumnOptions['link']
  modelLink?: string
  nestedFields?: { [key: string]: string }[]
  options?: string[][] | { [key: string]: string }[] | string[]
  optionsFn?: () =>
    | Array<string[][] | { [key: string]: string }[] | string[]>
    | Promise<string[][] | { value: string; label: string }[] | undefined>
  truncateLength?: number
  type?: string
} & ColumnProps

export enum FilterType {
  Multiselect = 'multiselect',
  Select = 'select',
  DateRange = 'daterange',
  Toggle = 'toggle',
}

export type FilterModalOptions = {
  label: string
  type: string
  options?: DropdownKV[]
  optionsFn?: any
  dataLocations?: string[]
  preEnabledOptionsFn?: () => string[]
  disabledOptionsFn?: () => string[]
  preEnabledOptions?: string[]
  disabledOptions?: string[]
  search?: boolean
}

export type Filter = DataTableFilterMetaData & {
  modalOptions?: FilterModalOptions
  hidden?: boolean
  valToParamFn?: (
    val: string | string[] | undefined
  ) => string | string[] | null | undefined
}

export type FilterMetaType = {
  [key: string]: Filter
}

export type TableState = {
  page: number
  sortField: string | undefined
  sortOrder: string
  perPage: string
}

export type OrugaColumn = {
  // Thymecare additions
  display: (d: Document) => string
  isOptionsDropdown?: boolean
  // oruga defined props
  // note there may be more possible optional props in oruga
  label: string
  subheading?: string
  field?: string
  width?: string | number
  numeric?: boolean
  position?: string
  searchable?: boolean
  sortable?: boolean
  visible?: boolean
  sticky?: boolean
  meta?: any
}

export type TableSettings = {
  perPage?: string
  page: number
  sortField: string
  sortOrder: string
  totalItems: number
}

export type PageSettings = {
  sort_by?: string
  sort_order?: string
  page_length?: string
  page_number?: number
}

export type EditOptions = {
  disableFn?: (row: any) => boolean
}

// ENUMS --------------------
export enum TablePadding {
  SM = 'sm',
  MD = '', // Base Default
  LG = 'lg',
}

// CONSTANTS --------------------
export const multiValFilterIndicator = 'multi_vals'
export const dateFilterStrings = ['dueAfter', 'dueBefore']
export const completedDateFilterStrings = [
  'completedOnAfter',
  'completedOnBefore',
]
export const acuityScoreFilterStrings = ['acuityGte', 'acuityLte']
export const nonApplicableFilterStrings = [
  ...acuityScoreFilterStrings,
  ...dateFilterStrings,
  ...completedDateFilterStrings,
]

export const SORT_DESCENDING = 1
export const SORT_ASCENDING = -1

export const sortOrder = {
  ASC: 'asc',
  DESC: 'desc',
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const DEFAULT_ROWS_PER_PAGE_OPTS = [10, 25, 50, 100]
