import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { capitalize } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  updateDisposition,
  deleteCallDisposition,
} from '@/legacy/components/patient/communicationV2/lib/callDispositions'
import { updateCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import { useStaffVars } from '@/legacy/components/patient/communicationV2/lib/staffVars'
import { enumValueToKey } from '@/legacy/libs/enum'
import {
  formatNameFromEntity,
  formatNameFromPerson,
} from '@/legacy/libs/format'
import router from '@/legacy/router'
import {
  createDefaultDatetime,
  useCallDispositionApi,
  useCommunicationApi,
  useCommunicationsStore,
  usePlannedCallApi,
} from '@/legacy/store/modules/communications'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  CallDirection,
  DirtyCallDisposition,
  SpeakingWithType,
  callDispositionDisplayLookup,
} from '@/legacy/types/communications/callDispositions'
import {
  BaseCommunicationPayload,
  CommSidebarActionEnums,
  CommunicationType,
  DEFAULT_NOTES,
  STRING_WITH_SPACE,
} from '@/legacy/types/communications/communications'
import { Patient } from '@/legacy/types/patients/patients'

/**
 *
 * Function to get computed values from props with default value fallback.
 */
export function getPropComputedVars() {
  const { isCreating } = storeToRefs(useCommunicationsStore())
  const { datum: communication } = storeToRefs(useCommunicationApi())
  const { datum: callDisposition } = storeToRefs(useCallDispositionApi())
  const { datum: plannedCall, isLoading: isLoadingPlannedCall } = storeToRefs(
    usePlannedCallApi()
  )
  const fallbackDiposition =
    enumValueToKey(
      callDispositionDisplayLookup,
      callDispositionDisplayLookup.ANSWERED
    ) ?? ''

  // Call disposition fields ----------
  const completedByStaffId = computed(
    () => callDisposition.value?.completedByStaffId
  )
  const disposition = computed(
    () => callDisposition.value?.disposition ?? fallbackDiposition
  )
  const isIdentityVerified = computed(
    () => callDisposition.value?.isIdentityVerified ?? false
  )
  const usedTranslator = computed(
    () => callDisposition.value?.usedTranslator ?? false
  )
  const speakingWithPersonId = computed(
    () => callDisposition.value?.speakingWithPersonId
  )
  const communicationId = computed(
    () => callDisposition.value?.communicationId ?? null
  )
  const speakingWithType = computed(
    () => callDisposition.value?.speakingWithType ?? null
  )
  const direction = computed(() => {
    if (callDisposition.value) {
      return callDisposition.value.direction
    }
    return plannedCall.value ? CallDirection.outbound : CallDirection.inbound
  })

  // Communication fields ----------
  const notes = computed(() => communication.value?.notes ?? DEFAULT_NOTES)
  const responsibleStaffId = computed(
    () => communication.value?.responsibleStaffId ?? null
  )
  const type = computed(
    () => communication.value?.type ?? CommunicationType.Call
  )
  const completedDatetime = computed(
    () => communication.value?.completedDatetime ?? createDefaultDatetime()
  )

  const plannedCalleeId = computed(() =>
    isCreating.value
      ? callDisposition.value?.speakingWithPersonId ?? undefined
      : plannedCall.value?.calleeEntityId ?? undefined
  )

  return {
    isLoadingPlannedCall,
    plannedCalleeId,
    completedByStaffId,
    disposition,
    isIdentityVerified,
    usedTranslator,
    speakingWithPersonId,
    communicationId,
    speakingWithType,
    direction,
    notes,
    responsibleStaffId,
    type,
    completedDatetime,
  }
}

/**
 *
 * Function to get communication variables.
 */
export function getCommunicationVars() {
  const {
    completedByStaffId,
    disposition,
    isIdentityVerified,
    usedTranslator,
    speakingWithPersonId,
    communicationId,
    speakingWithType,
    direction,
    notes,
    responsibleStaffId,
    type,
    completedDatetime,
    plannedCalleeId,
    isLoadingPlannedCall,
  } = getPropComputedVars()

  const { patient, person, contacts } = storeToRefs(usePatientStore())
  const { isCreating } = storeToRefs(useCommunicationsStore())
  const { datum: communication, isLoading: isLoadingComm } = storeToRefs(
    useCommunicationApi()
  )
  const { datum: callDisposition, isLoading: isLoadingCallDisposition } =
    storeToRefs(useCallDispositionApi())

  const { selfEntity } = storeToRefs(useProfileStore())

  /**
   *
   * Function to set editing communication object.
   */
  function setDirtyCommunication() {
    if (!patient.value) {
      console.error('Patient not loaded, cannot set dirty communication')
      return {
        ...(communication.value ?? {}),
        type: type.value,
        responsibleStaffId: responsibleStaffId.value,
        notes: notes.value,
        completedDatetime: completedDatetime.value,
        patientIds: [],
      }
    } else {
      return {
        ...(communication.value ?? {}),
        patientIds: [(patient.value as Patient).entityId],
        type: type.value,
        responsibleStaffId: responsibleStaffId.value,
        notes: notes.value,
        completedDatetime: completedDatetime.value,
      }
    }
  }

  /**
   *
   * Function to set editing call disposition object.
   */
  function setDirtyCallDisposition() {
    return {
      ...(callDisposition.value ?? {}),
      communicationId: communicationId.value ?? '',
      completedByStaffId:
        completedByStaffId.value ?? selfEntity.value?.entityId ?? '',
      direction: direction.value,
      disposition: disposition.value,
      isIdentityVerified: isIdentityVerified.value,
      usedTranslator: usedTranslator.value,
      speakingWithPersonId: speakingWithPersonId.value ?? STRING_WITH_SPACE,
      speakingWithType: speakingWithType.value,
    }
  }

  const dirtyCommunication = ref<BaseCommunicationPayload>(
    setDirtyCommunication()
  )

  const dirtyCallDisposition = ref<DirtyCallDisposition>(
    setDirtyCallDisposition()
  )

  watch(callDisposition, () => {
    dirtyCallDisposition.value = setDirtyCallDisposition()
  })
  watch(communication, () => {
    dirtyCommunication.value = setDirtyCommunication()
  })

  /**
   *
   * Function to set staff ID for both comms and call disposition.
   * @param selectedOption
   */
  function setStaff(selectedOption: string) {
    dirtyCallDisposition.value.completedByStaffId = selectedOption
    dirtyCommunication.value.responsibleStaffId = selectedOption
    return
  }

  /**
   * send create/update call to sidebar
   * triggers api call in sidebar
   * @param updatedField
   */
  async function updateCallAndComm(updatedField?: string) {
    dirtyCallDisposition.value.speakingWithPersonId =
      dirtyCallDisposition.value.speakingWithPersonId === STRING_WITH_SPACE
        ? null
        : dirtyCallDisposition.value.speakingWithPersonId
    await updateCommunication(
      communicationId.value,
      dirtyCommunication.value,
      updatedField ?? '',
      communication.value?.updatedAt
    )
    await updateDisposition(communicationId.value, dirtyCallDisposition.value)
  }

  /**
   *
   * Function that sets speakingWithId and speakingWithPersonId
   * on editing objects and calls update.
   * @param val
   */
  async function setSpeakingWithIdAndType(val: string) {
    let speakingWithType = null
    if (val && patient.value) {
      const contactsList = Object.values(contacts.value ?? {})
      speakingWithType =
        val === patient.value.entityId
          ? SpeakingWithType.member
          : contactsList.length > 0 &&
            contactsList.find(
              (contactObj) => contactObj.contactEntityId === val
            )
          ? SpeakingWithType.contact
          : null
    }
    // coalesce empty string into null
    dirtyCallDisposition.value.speakingWithPersonId =
      val === STRING_WITH_SPACE ? null : val
    dirtyCallDisposition.value.speakingWithType = speakingWithType ?? null
    await updateCallAndComm(CommSidebarActionEnums.completedCallWith)
  }

  /**
   *
   * Function to update notes for editing communication.
   * @param val
   */
  function updateNotesForDirtyComm(val: string) {
    dirtyCommunication.value.notes = val
  }

  const calleeOptions = computed(() => {
    const patientObj = patient.value
    const personObj = person.value

    let options: DropdownKV[] = []

    // The ordering here is intentional: Patient > Contact > Unlisted Callee
    if (patientObj && personObj) {
      options.push({
        value: patientObj.entityId,
        label: formatNameFromPerson(personObj),
      })
    }

    if (contacts.value) {
      const contactOptions = Object.values(contacts.value)
        .map((contact) => {
          return {
            value: contact.contactEntityId,
            label: formatNameFromEntity(contact.contactEntity),
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
      options = [...options, ...contactOptions]
    }

    options.push({ value: STRING_WITH_SPACE, label: 'Unlisted Callee' })
    return options
  })

  return {
    isLoadingCallDisposition,
    isLoadingPlannedCall,
    isLoadingComm,
    callDisposition,
    plannedCalleeId,
    isCreating,
    completedByStaffId,
    isIdentityVerified,
    usedTranslator,
    speakingWithPersonId,
    completedDatetime,
    dirtyCallDisposition,
    dirtyCommunication,
    calleeOptions,
    setStaff,
    setSpeakingWithIdAndType,
    updateCallAndComm,
    updateNotesForDirtyComm,
  }
}

/**
 *
 * Function to get utility functions.
 */
export function getUtilFuncs() {
  const { communicationId } = getPropComputedVars()

  /**
   *
   * Function to return boolean as Yes/No string
   * @param val
   */
  function boolToString(val: boolean) {
    return val ? 'Yes' : 'No'
  }

  /**
   *
   * Function to delete an inbound call disposition (will only apply if direction is inbound.)
   */
  async function deleteInboundCall() {
    await deleteCallDisposition(communicationId.value)
    await router.push({
      query: {
        commId: undefined,
      },
    })
  }

  return {
    boolToString,
    deleteInboundCall,
  }
}

/**
 *
 * Function to get enum options for dropdowns.
 */
export function getEnumOptions() {
  const { staffIdOptions } = useStaffVars()
  const speakingWithTypeOptions = computed(() =>
    Object.values(SpeakingWithType).map((option) => {
      return { value: option, label: capitalize(option) }
    })
  )

  const callDirectionChoices = computed(() =>
    Object.values(CallDirection).map((option) => {
      return { value: option, label: capitalize(option) }
    })
  )
  const filteredCallDispositionDisplayLookup = Object.entries(
    callDispositionDisplayLookup
  )
  const dispositionChoices = filteredCallDispositionDisplayLookup.map(
    ([v, k]) => ({ value: v, label: k })
  )

  return {
    staffIdOptions,
    speakingWithTypeOptions,
    callDirectionChoices,
    dispositionChoices,
  }
}
