import { IFormsortWebEmbed } from '@formsort/web-embed-api'
import { storeToRefs } from 'pinia'
import { ref, watch, onMounted, Ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { updateFormResponseIdParam } from '@/pages/Tools/FormResponse/shared'
import {
  FORM_EMBED_ROOT_ID,
  FormResponseStates,
  ViewFormResponseForm,
} from '@/pages/Tools/FormResponse/types'
import { embedForm, loadFormResponse } from './helpers'

export type FormResponseViewModel = {
  canViewPageFormResponses: Ref<boolean>
  isDevEnv: Ref<boolean>
  updateFormResponseIdParam: (values: ViewFormResponseForm) => void
  state: Ref<FormResponseStates>
  FormResponseStates: typeof FormResponseStates
  initialInputData: ViewFormResponseForm
  errorMsg: Ref<string>
  formResponseCreatedAt: Ref<string>
  FORM_EMBED_ROOT_ID: string
}

/**
 *
 */
export function setup(): FormResponseViewModel {
  const route = useRoute()
  const formEmbed = ref<IFormsortWebEmbed | null>(null)
  const { configVals } = storeToRefs(useConfigStore())
  const state = ref(FormResponseStates.INITIAL)
  const formResponseId = ref(route.query.formResponseId as string | undefined)
  const initialInputData: ViewFormResponseForm = {
    formResponseId: formResponseId.value ?? '',
  }
  const errorMsg = ref('')
  const { canViewPageFormResponses } = storeToRefs(useProfileStore())

  const flowLabel = ref('')
  const variantLabel = ref('')
  const variantRevisionUuid = ref('')
  const prefillData = ref<Array<[string, string]> | null>(null)
  const formResponseCreatedAt = ref('')

  const isDevEnv = computed(() => configVals.value?.env === 'dev')

  const loadFormResponseFromQueryParam = async () => {
    const formResponseId = route.query.formResponseId as string | undefined
    if (formResponseId) {
      state.value = FormResponseStates.LOADING
      await loadFormResponse(formResponseId, onSuccess, onError)
    }
  }

  const onSuccess = (formResponse: any) => {
    flowLabel.value = formResponse.flowLabel
    variantLabel.value = formResponse.variantLabel
    variantRevisionUuid.value = formResponse.variantRevisionId
    prefillData.value = formResponse.prefillData
    formResponseCreatedAt.value = formatDateTimeWithTime(
      stringToDateTime(formResponse.createdAt)
    )
    state.value = FormResponseStates.AWAITING_EMBED
  }

  const onError = (msg: string) => {
    errorMsg.value = msg
    state.value = FormResponseStates.ERROR
  }

  watch(
    () => route.query.formResponseId,
    async () => {
      await loadFormResponseFromQueryParam()
    }
  )

  watch(state, async (newState) => {
    switch (newState) {
      case FormResponseStates.AWAITING_EMBED: {
        const onSuccess = () => {
          state.value = FormResponseStates.EMBEDDED
        }

        const onError = (msg: string) => {
          state.value = FormResponseStates.ERROR
          errorMsg.value = msg
        }

        await embedForm(
          formEmbed,
          flowLabel.value,
          variantLabel.value,
          variantRevisionUuid.value,
          prefillData.value,
          configVals.value,
          onSuccess,
          onError
        )
        break
      }
    }
  })

  onMounted(async () => {
    await loadFormResponseFromQueryParam()
  })

  return {
    canViewPageFormResponses,
    isDevEnv,
    updateFormResponseIdParam,
    state,
    FormResponseStates,
    formResponseCreatedAt,
    initialInputData,
    errorMsg,
    FORM_EMBED_ROOT_ID,
  }
}
