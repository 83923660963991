import { requiredInject } from '@thyme/libs/src/vue/inject'
import { camelCase, startCase, difference, map, uniq } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import { stringToDateTime } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { thymeListen } from '@/legacy/libs/eventBus'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { mapToLongFormGender } from '@/legacy/libs/patients'
import {
  useComorbidityRefApi,
  useComorbidityRefStore,
} from '@/legacy/store/modules/comorbiditiesReferences'
import {
  useDiagnosesRefStore,
  useDiagnosisRefApi,
} from '@/legacy/store/modules/diagnosesReferences'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useComorbiditiesApi,
  useDiagnosesApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import { Gender } from '@/legacy/types/entities/people'
import { Diagnosis, Stage } from '@/legacy/types/patients/diagnoses'
import {
  programStatusOptions,
  treatmentIntentOptions,
  treatmentStatusOptions,
} from '@/legacy/types/patients/patients'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { getPatientCancerDetailWithRefs } from '../ClinicalSummary/PatientCancerDetails/queries'
import { getSensitiveMedicalConditions } from '../shared/queries'
import {
  useMedicalConditionsCarePlanApi,
  usePatientClinicalSummaryApi,
} from '../shared/store'
import { MedicalCondition } from '../shared/types'
import { getCarePlanUpdate, getDomains } from './queries'
import { useCarePlanUpdateApi, useDomainApi } from './stores'

// Helper functions to format last updated tooltip
/**
 *
 * @param staffId
 */
export function getStaffName(staffId: string | undefined | null) {
  if (staffId) {
    return getStaffNameFromId(staffId, '') as string
  }
  return 'N/A'
}

/**
 *
 * @param timeStamp
 */
export function getFormattedDate(timeStamp: string | undefined | null) {
  if (timeStamp) {
    const formattedTimeStamp = formatDateTimeWithTime(
      stringToDateTime(timeStamp)
    )
    const [datePart] = formattedTimeStamp.split(', ')
    return datePart
  }
  return ''
}

/**
 *
 */
export default function () {
  const patientId = requiredInject(PATIENT_ID_KEY)

  const { patient, person } = storeToRefs(usePatientStore())
  const { data: comorbidityCatalog } = storeToRefs(useComorbidityRefApi())
  const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
  const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
  const { data: diagnoses } = storeToRefs(useDiagnosesApi())
  const { data: domains } = storeToRefs(useDomainApi())
  const { datum: carePlanUpdate } = storeToRefs(useCarePlanUpdateApi())
  const { data: sensitiveMedicalConditions } = storeToRefs(
    useMedicalConditionsCarePlanApi()
  )
  const { datum: clinicalSummaryData } = storeToRefs(
    usePatientClinicalSummaryApi()
  )

  const router = useRouter()

  const showEditModal = ref<boolean>(false)

  const tierLevel = computed(() =>
    patient.value?.pinnedAcuityScore
      ? formatTierScore(patient.value.pinnedAcuityScore.overallScore)
      : '-'
  )

  const getComorbidityRefIdsToFetch = () => {
    const comorbidityRefIds = map(comorbidities.value, 'comorbidityRefId')
    const storeComorbidityRefIds = Object.keys(comorbidityCatalog.value ?? {})
    return difference(comorbidityRefIds, storeComorbidityRefIds)
  }

  const getDxRefIdsToFetch = () => {
    const dxRefIds = map(diagnoses.value, 'diagnosisRefId')
    const storeComorbidityRefIds = Object.keys(diagnosisCatalog.value ?? {})
    return difference(dxRefIds, storeComorbidityRefIds)
  }

  const getData = async () => {
    // fetch last updated values for care plans
    await fetchLastUpdated()

    // fetch comorbidities and diagnoses
    void usePatientStore().fetchComorbidities(patientId.value)
    void usePatientStore().fetchDiagnoses(patientId.value)
    if (comorbidities.value) {
      const comorbidityRefIdsToBeFetched = getComorbidityRefIdsToFetch()
      if (comorbidityRefIdsToBeFetched) {
        void useComorbidityRefStore().fetchAllComorbidityRefs(
          comorbidityRefIdsToBeFetched
        )
      }
    }
    if (diagnoses.value) {
      const dxRefIdsToBeFetched = getDxRefIdsToFetch()
      if (dxRefIdsToBeFetched) {
        void useDiagnosesRefStore().fetchAllDiagnosisRefs(dxRefIdsToBeFetched)
      }
    }
    // fetch domains
    await getDomains(patientId.value)
    // fetch sensitive medical conditions
    await getSensitiveMedicalConditions(patientId.value)
    await getPatientCancerDetailWithRefs(patientId.value)
  }

  /**
   * fetch last updated values in care plan section
   */
  async function fetchLastUpdated() {
    await getCarePlanUpdate(patientId.value)
  }

  thymeListen('care-planV2-update', fetchLastUpdated)

  onMounted(getData)

  const diagnosesArray = computed(() => Object.values(diagnoses.value ?? {}))
  const primaryDiagnoses = computed(() => {
    return diagnosesArray.value.filter(
      (dx: Diagnosis) =>
        (dx.isPrimary && !dx.isInaccurate) ||
        (dx.isPrimary === null && !dx.isInaccurate)
    )
  })

  const age = computed(() => {
    const dobDatetime = stringToDateTime(person.value?.dateOfBirth)

    if (dobDatetime) {
      return Math.floor(-dobDatetime.diffNow('years').years)
    }
    return null
  })

  const patientPrimaryDiagnosisString = computed(() => {
    if (!clinicalSummaryData.value) {
      return null
    }
    if (clinicalSummaryData.value.cancerDiagnosis?.otherOncologyDxType) {
      return startCase(
        clinicalSummaryData.value.cancerDiagnosis.otherOncologyDxType
      )
    }
    return startCase(
      clinicalSummaryData.value.cancerDiagnosis?.oncologyDiagnosisReference
        .description
    )
  })

  const getDiagnosisName = (diagnosisRefId: string) =>
    (
      safeLookup(diagnosisRefId, diagnosisCatalog.value)?.description ?? ''
    ).split(': ')[0]

  /**
   * Create string to display patient name, age, diagnoses
   * TODO update where we fetch primary dx once clinical summary
   * is in flight (care plans part 3)
   */
  const patientSummary = computed(() => {
    const gender = person.value?.gender ?? Gender.Unknown
    const patientGender = mapToLongFormGender(gender)
    const readablePrimaryDiagnoses = uniq(
      primaryDiagnoses.value.map((d) =>
        lookupEnum(Stage, d.stage)
          ? `Stage ${lookupEnum(Stage, d.stage)} ${getDiagnosisName(
              d.diagnosisRefId
            )}`
          : `${getDiagnosisName(d.diagnosisRefId)}`
      )
    ).join(', ')

    // Strings based on optional fields
    const ageString = age.value ? `${age.value} year old` : ''
    const genderString = ![Gender.Unknown, Gender.PreferNotToAnswer].includes(
      gender
    )
      ? ` ${patientGender}`
      : ''
    let primaryDiagnosesString

    if (patientPrimaryDiagnosisString.value) {
      primaryDiagnosesString = ` with ${patientPrimaryDiagnosisString.value}`
    } else {
      primaryDiagnosesString = readablePrimaryDiagnoses
        ? ` with ${readablePrimaryDiagnoses}`
        : ''
    }

    return `${ageString.toLowerCase()}${genderString.toLowerCase()}${primaryDiagnosesString}.`
  })

  const hasSensitiveDx = computed(() => {
    if (sensitiveMedicalConditions.value?.length) {
      return sensitiveMedicalConditions.value.some(
        (medicalCondition: MedicalCondition) =>
          medicalCondition.medicalConditionReference?.isSensitive
      )
    }
    if (comorbidities.value && comorbidityCatalog.value) {
      const comorbiditiesArr = Object.values(comorbidities.value)
      const comorbidityRefsArr = Object.values(comorbidityCatalog.value)
      const sensitiveComorbidityRefIds = comorbidityRefsArr
        .filter((coRef) => !!coRef.isSensitive)
        .map((coRef) => coRef.comorbidityRefId)
      const patientComorbidityRefs = comorbiditiesArr
        .filter((co) => !co.isInaccurate)
        .map((co) => co.comorbidityRefId)
      return patientComorbidityRefs.some((id) =>
        sensitiveComorbidityRefIds.includes(id)
      )
    }
    return false
  })

  const patientProgramVal = computed(() => {
    if (
      patient.value &&
      patient.value.programStatus &&
      patient.value.programSubstatus
    ) {
      return `${patient.value.programStatus}__${patient.value.programSubstatus}`
    }
    return null
  })

  const patientTreatmentStatusVal = computed(() => {
    if (
      patient.value &&
      patient.value.treatmentStatus &&
      patient.value.treatmentSubstatus
    ) {
      return `${patient.value.treatmentStatus}__${patient.value.treatmentSubstatus}`
    }
    return null
  })

  const patientTreatmentIntentVal = computed(() => {
    if (patient.value && patient.value.treatmentIntent) {
      return patient.value.treatmentIntent
    }
    return null
  })

  const programStatusDisplay = computed(() => {
    if (patientProgramVal.value) {
      return programStatusOptions.find(
        (ps) => ps.key === patientProgramVal.value
      )?.display
    }
    return '-'
  })
  const treatmentStatusDisplay = computed(() => {
    if (patientTreatmentStatusVal.value) {
      return treatmentStatusOptions.find(
        (ts) => ts.key === patientTreatmentStatusVal.value
      )?.display
    }
    return '-'
  })
  const treatmentIntentDisplay = computed(() => {
    if (patientTreatmentIntentVal.value) {
      return treatmentIntentOptions.find(
        (ti) => ti.key === patientTreatmentIntentVal.value
      )?.display
    }
    return '-'
  })

  const domainsDisplay = computed(() => {
    const domainsArr = Object.values(domains.value ?? {})
    if (domains.value && domainsArr.length) {
      let domainsString = ''
      domainsArr.map((domain, index) => {
        // format each domain string to title case
        const formattedTitle = startCase(camelCase(domain.domain))
        domainsString += formattedTitle
        // add a bullet to separate each title
        if (index < domainsArr.length - 1) {
          domainsString += ' • '
        }
      })
      return domainsString
    }
    return '-'
  })

  /**
   * open/close edit modal
   */
  function toggleCarePlanEdit() {
    showEditModal.value = !showEditModal.value
  }

  const formattedCarePlanUpdateString = computed(() => {
    if (!carePlanUpdate.value) {
      return null
    }
    const carePartner =
      `${getStaffName(carePlanUpdate?.value.lastCarePartnerUpdatedBy)} ` +
      `${getFormattedDate(
        carePlanUpdate.value.lastCarePartnerUpdatedAt?.toString()
      )}`
    const nurse =
      `${getStaffName(carePlanUpdate.value.lastNurseUpdatedBy)}` +
      `${getFormattedDate(carePlanUpdate.value.lastNurseUpdatedAt?.toString())}`
    const leadership =
      `${getStaffName(carePlanUpdate.value.lastLeadershipUpdatedBy)} ` +
      `${getFormattedDate(
        carePlanUpdate.value.lastLeadershipUpdatedAt?.toString()
      )}`
    return `Care Partner: ${carePartner} • Nurse: ${nurse} • Leadership: ${leadership}`
  })

  /**
   * switch route to care plans pdf print out page
   */
  async function showPdf() {
    const { showNewCarePlanReport } = storeToRefs(useFlagStore())
    if (showNewCarePlanReport.value) {
      await router.push({
        name: '#patientCarePlanReportV2',
        params: { patientId: patientId.value },
      })
    } else {
      await router.push({
        name: '#patientCarePlanReport',
        params: { patientId: patientId.value },
      })
    }
  }

  return {
    patient,
    hasSensitiveDx,
    patientSummary,
    formattedCarePlanUpdateString,
    // display values
    programStatusDisplay,
    treatmentStatusDisplay,
    treatmentIntentDisplay,
    domainsDisplay,
    tierLevel,
    // status dropdowns
    programStatusOptions,
    treatmentIntentOptions,
    treatmentStatusOptions,
    // care plan edit modal
    toggleCarePlanEdit,
    showEditModal,
    domains,
    showPdf,
  }
}
