import { camelCase, capitalize, startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, onMounted, ref, Ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime } from '@/legacy/libs/format'
import { useGoalApi } from '@/legacy/store/modules/goals'
import { usePathwayApi } from '@/legacy/store/modules/pathways'
import { useGoalTaskApi } from '@/legacy/store/modules/tasks'
import { Goal, GoalStatus } from '@/legacy/types/pathways/goals'
import {
  Pathway,
  PathwayStatus,
  TaskStatus,
} from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'

import { useDomainApi } from '@/pages/PatientProfile/CarePlans/CarePlan/stores'
import { formatAttributesDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/format'
import {
  useCarePlanTransitionsOfCareApi,
  usePhysicalFunctionAssessmentApi,
  useMedicalTeamApi,
  useMapMedicalAttrsMedicalConditionsApi,
  useMedicalConditionAttrsRefApi,
  useMedicalConditionsWithAttrsApi,
  useMedicalConditionsWithoutAttrsApi,
  useFallRiskApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'

import {
  Radiation,
  Surgery,
  Treatment,
  Anticoagulation,
  SedatingMedications,
  MedicalCondition,
  FetchPatientDataFunction,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  arrayToStringDisplay,
  formatStringDisplay,
  getLabels,
} from '@/pages/PatientProfile/CarePlans/shared/utils'
import {
  BenefitsProgramsServices,
  benefitsProgramsServicesOptions,
  CaregiverMapping,
  caregiverMappingOptions,
  FinancialBarrier,
  financialBarrierOptions,
  FoodBarrier,
  foodBarrierOptions,
  HousingBarrier,
  housingBarrierOptions,
  InHomeSupport,
  inHomeSupportOptions,
  SocialSupport,
  socialSupportOptions,
  TransportationBarrier,
  transportationBarrierOptions,
} from '@/pages/PatientProfile/CarePlans/SocialSummary/types'
import { useSocialSummariesApi } from '../SocialSummary/store'
import {
  getMostRecentTransitionOfCare,
  getPatientMedicalTeam,
  getPhysicalFunctionAssessment,
  getPatientClinicalSummary,
  getPatientTreatmentWithParts,
  getPatientSurgeryWithParts,
  getPatientRadiationWithParts,
  getPatientFallRisk,
  getPatientMedicalConditions,
  getMapMedicalAttrsMedicalConditions,
} from './queries'
import { usePatientClinicalSummaryApi } from './store'

/**
 *
 * @param patientId
 */
export function useTransitionsOfCare(patientId: Ref<string>) {
  const { data: transitionsOfCareData } = storeToRefs(
    useCarePlanTransitionsOfCareApi()
  )
  const transitionsOfCare = computed(
    () => transitionsOfCareData.value?.[0] ?? null
  )

  //IMPROVEME(MT-3786): Almost all of the display variables in this file are being used in two different places
  // the controller and the care plan pdf, we should consolidate usage to just this file
  const admissionDateDisplay = computed(() => {
    if (!transitionsOfCare.value?.admissionDate) return
    return new Date(transitionsOfCare.value.admissionDate).toLocaleDateString()
  })

  const dischargeDateDisplay = computed(() => {
    if (!transitionsOfCare.value?.dischargeDate) return
    return new Date(transitionsOfCare.value.dischargeDate).toLocaleDateString()
  })

  const eventTypeDisplay = computed(() => {
    if (!transitionsOfCare.value?.eventType) return
    return startCase(camelCase(transitionsOfCare.value.eventType))
  })

  const dischargeDiagnosisDisplay = computed(() => {
    if (!transitionsOfCare.value?.dischargeDiagnosis) return
    return startCase(camelCase(transitionsOfCare.value.dischargeDiagnosis))
  })

  const dischargeLocationDisplay = computed(() => {
    if (!transitionsOfCare.value?.dischargeLocation) return
    return startCase(camelCase(transitionsOfCare.value.dischargeLocation))
  })

  const isReadmissionRiskDisplay = computed(() =>
    transitionsOfCare.value?.isReadmissionRisk ? 'Yes' : 'No'
  )

  const hasNewDmeOnDischargeDisplay = computed(() =>
    transitionsOfCare.value?.hasNewDmeOnDischarge ? 'Yes' : 'No'
  )

  const servicesOnDischargeDisplay = computed(() => {
    const services = transitionsOfCare.value?.servicesOnDischarge
    if (!services?.length) return
    return arrayToStringDisplay(services)
  })

  const moreThanThreeAdmissionsInLastSixMonthsDisplay = computed(() =>
    transitionsOfCare.value?.moreThanThreeAdmissionsInLastSixMonths
      ? 'Yes'
      : 'No'
  )

  const summaryOfEventsDisplay = computed(
    () => transitionsOfCare.value?.summaryOfEvents
  )

  const hasElectiveAdmissionDisplay = computed(() => {
    const hasElectiveAdmission = transitionsOfCare.value?.hasElectiveAdmission
    if (hasElectiveAdmission == null) return
    return hasElectiveAdmission ? 'Yes' : 'No'
  })

  const refetchTransitionsOfCare = async () => {
    await getMostRecentTransitionOfCare(patientId.value)
  }

  onMounted(refetchTransitionsOfCare)

  return {
    transitionsOfCareId: transitionsOfCare.value?.transitionsOfCareId,
    admissionDateDisplay,
    dischargeDateDisplay,
    eventTypeDisplay,
    dischargeDiagnosisDisplay,
    dischargeLocationDisplay,
    isReadmissionRiskDisplay,
    hasNewDmeOnDischargeDisplay,
    servicesOnDischargeDisplay,
    moreThanThreeAdmissionsInLastSixMonthsDisplay,
    summaryOfEventsDisplay,
    hasElectiveAdmissionDisplay,
    refetchTransitionsOfCare,
  }
}

/**
 *
 * @param patientId
 */
export function useMedicalTeam(patientId: Ref<string>) {
  const { data: medTeamData } = storeToRefs(useMedicalTeamApi())
  const medicalTeam = computed(() => medTeamData.value?.[0] ?? null)

  const lastOncologyApptValue = computed(
    () => medicalTeam.value?.lastOncologyAppt
  )
  const nextOncologyApptValue = computed(
    () => medicalTeam.value?.nextOncologyAppt
  )
  const oncologistNameValue = computed(() => medicalTeam.value?.oncologistName)
  const surgeonNameValue = computed(() => medicalTeam.value?.surgeonName)
  const radiationOncologistNameValue = computed(
    () => medicalTeam.value?.radiationOncologistName
  )
  const palliativeMedicineNameValue = computed(
    () => medicalTeam.value?.palliativeMedicineName
  )
  const primaryCareNameValue = computed(
    () => medicalTeam.value?.primaryCareName
  )
  const additionalContextValue = computed(
    () => medicalTeam.value?.additionalContext
  )

  // Display
  const lastOncologyAppt = computed(() => {
    if (lastOncologyApptValue.value == null) return undefined
    return formatDateTime(stringToDateTime(lastOncologyApptValue.value))
  })

  const nextOncologyAppt = computed(() => {
    if (nextOncologyApptValue.value == null) return undefined
    return formatDateTime(stringToDateTime(nextOncologyApptValue.value))
  })

  const oncologistName = computed(() => {
    if (oncologistNameValue.value == null) return undefined
    return oncologistNameValue.value
  })

  const surgeonName = computed(() => {
    if (surgeonNameValue.value == null) return undefined
    return surgeonNameValue.value
  })

  const radiationOncologistName = computed(() => {
    if (radiationOncologistNameValue.value == null) return undefined
    return radiationOncologistNameValue.value
  })

  const palliativeMedicineName = computed(() => {
    if (palliativeMedicineNameValue.value == null) return undefined
    return palliativeMedicineNameValue.value
  })

  const primaryCareName = computed(() => {
    if (primaryCareNameValue.value == null) return undefined
    return primaryCareNameValue.value
  })

  const additionalContext = computed(() => additionalContextValue.value)

  const refetchMedicalTeam = async () => {
    await getPatientMedicalTeam(patientId.value)
  }

  onMounted(refetchMedicalTeam)

  return {
    medicalTeam,
    patientMedTeamId: computed(() => medicalTeam.value?.patientMedTeamId),
    // Form
    lastOncologyApptValue,
    nextOncologyApptValue,
    oncologistNameValue,
    surgeonNameValue,
    radiationOncologistNameValue,
    palliativeMedicineNameValue,
    primaryCareNameValue,
    additionalContextValue,
    // Display
    lastOncologyAppt,
    nextOncologyAppt,
    oncologistName,
    surgeonName,
    radiationOncologistName,
    palliativeMedicineName,
    primaryCareName,
    additionalContext,
    // Actions
    refetchMedicalTeam,
  }
}

/**
 *
 * @param patientId
 */
export function usePhysicalFunctionAssessment(patientId: Ref<string>) {
  const { data: physicalFunctionAssessmentData } = storeToRefs(
    usePhysicalFunctionAssessmentApi()
  )
  const physicalFunctionAssessment = computed(
    () => physicalFunctionAssessmentData.value?.[0] ?? null
  )

  const ecogStatusDisplay = computed(() => {
    const ecogStatus = physicalFunctionAssessment.value?.ecogStatus
    if (!ecogStatus) return
    return startCase(camelCase(ecogStatus))
  })

  const unableToPerformTheFollowingIADLsDisplay = computed(() => {
    const followingIADLs = physicalFunctionAssessment.value?.iadlsInability
    if (!followingIADLs?.length) return
    return arrayToStringDisplay(followingIADLs)
  })

  const unableToPerformTheFollowingADLsDisplay = computed(() => {
    const followingADLs = physicalFunctionAssessment.value?.adlsInability
    if (!followingADLs?.length) return
    return arrayToStringDisplay(followingADLs)
  })

  const homeboundDisplay = computed(() => {
    const homebound =
      physicalFunctionAssessment.value?.unableToLeaveHomeOtherThanMedicalAppts
    if (homebound == null) return
    return homebound ? 'Yes' : 'No'
  })

  const dmeDisplay = computed(() => {
    const dme = physicalFunctionAssessment.value?.dmeAtHome
    if (!dme?.length) return
    return arrayToStringDisplay(dme)
  })

  const proceduralSupportDisplay = computed(() => {
    const proceduralSupport =
      physicalFunctionAssessment.value?.proceduralSupport
    if (!proceduralSupport?.length) return
    return arrayToStringDisplay(proceduralSupport)
  })

  const walksTwoBlocksOrOneFlightOfStairsDisplay = computed(() => {
    const walksTwoBlocksOrOneFlightOfStairs =
      physicalFunctionAssessment.value?.unableToWalkTwoBlocksOrAFlightOfStairs
    if (walksTwoBlocksOrOneFlightOfStairs == null) return
    return walksTwoBlocksOrOneFlightOfStairs ? 'Yes' : 'No'
  })

  const additionalInformationDisplay = computed(
    () => physicalFunctionAssessment.value?.additionalContext
  )

  const refetchPhysicalFunctionAssessment = async () => {
    await getPhysicalFunctionAssessment(patientId.value)
  }

  onMounted(refetchPhysicalFunctionAssessment)

  return {
    physicalFunctionAssessmentId:
      physicalFunctionAssessment.value?.physicalFunctionAssessmentId,
    ecogStatusDisplay,
    unableToPerformTheFollowingIADLsDisplay,
    unableToPerformTheFollowingADLsDisplay,
    homeboundDisplay,
    dmeDisplay,
    proceduralSupportDisplay,
    walksTwoBlocksOrOneFlightOfStairsDisplay,
    additionalInformationDisplay,
    refetchPhysicalFunctionAssessment,
  }
}

/**
 *
 * @param patientId
 */
export function usePatientClinicalSummary(patientId: Ref<string>) {
  const { datum: clinicalSummaryData } = storeToRefs(
    usePatientClinicalSummaryApi()
  )

  const cancerDisplay = computed(() => {
    const cancerDx = clinicalSummaryData.value?.cancerDiagnosis
    if (cancerDx == null) return

    if (cancerDx.otherOncologyDxType) {
      return `Other • ${cancerDx.otherOncologyDxType}`
    }

    const cancerCategory =
      cancerDx.oncologyDiagnosisReference.oncologyDiagnosisCategoryReference
        ?.description
    const cancerType = cancerDx.oncologyDiagnosisReference.description

    if (cancerCategory.toLowerCase() === cancerType.toLowerCase()) {
      return cancerType
    }
    return `${cancerCategory} • ${cancerType}`
  })

  const spreadDisplay = computed(() => {
    const cancerDx = clinicalSummaryData.value?.cancerDiagnosis
    if (cancerDx == null || cancerDx.otherOncologyDxType) return

    const spreadRefs = cancerDx.oncologySpreadRefMaps.map(
      (spread: any) => spread.oncologySpreadReference
    )
    const spreadRefDescriptions = spreadRefs.map(
      (spreadRef: any) => spreadRef.description
    )
    return arrayToStringDisplay(spreadRefDescriptions)
  })

  const diagnosisDateDisplay = computed(() => {
    const diagnosisDate =
      clinicalSummaryData.value?.cancerDiagnosis?.diagnosisDate
    if (diagnosisDate == null) return
    return formatDateTime(stringToDateTime(diagnosisDate))
  })

  onMounted(async () => {
    await getPatientClinicalSummary(patientId.value)
  })

  return {
    cancerDisplay,
    spreadDisplay,
    diagnosisDateDisplay,
  }
}

/**
 *
 */
export function usePatientTreatment() {
  const { datum: clinicalSummary } = storeToRefs(usePatientClinicalSummaryApi())

  const cancerDetailsId = computed(
    () => clinicalSummary.value?.cancerDiagnosis.cancerDiagnosisId ?? null
  )

  const allTreatments = ref<Treatment[]>([])
  const allSurgeries = ref<Surgery[]>([])
  const allRadiation = ref<Radiation[]>([])

  const PAGE_SIZE = 100

  /**
   *
   * @param fetchFunction
   */
  async function fetchAllPages<T>(fetchFunction: FetchPatientDataFunction<T>) {
    let pageNumber = 1
    let hasMoreData = true
    let allData: T[] = []

    while (hasMoreData && cancerDetailsId.value) {
      const data = await fetchFunction(
        cancerDetailsId.value,
        PAGE_SIZE,
        pageNumber
      )
      if (data) {
        // If data is not an array, wrap it in an array
        const dataArray = Array.isArray(data) ? data : [data]
        allData = [...allData, ...dataArray]
        pageNumber++
      } else {
        hasMoreData = false
      }
    }

    return allData
  }

  const treatmentDisplay = computed(() => {
    if (!allTreatments.value.length) return

    const sortedTreatments = [...allTreatments.value].sort((a, b) =>
      a.oncologySystematicTherapyReference.agent.localeCompare(
        b.oncologySystematicTherapyReference.agent
      )
    )
    const formatted = sortedTreatments.map((treatment) => {
      if (treatment.otherTreatmentType) {
        return treatment.otherTreatmentType
      }
      const oncSystematicTherapyRef =
        treatment.oncologySystematicTherapyReference
      if (oncSystematicTherapyRef) {
        return `${
          oncSystematicTherapyRef.agent
        } (current) • Brands: ${oncSystematicTherapyRef.brandNames.join(', ')}`
      }
      return ''
    })
    return formatted.join('\n')
  })

  const surgeryDisplay = computed(() => {
    if (!allSurgeries.value.length) return
    return allSurgeries.value
      .map(
        (surgery) =>
          `${surgery.surgicalProcedureReference.description} (${new Date(
            surgery.surgeryDate
          ).toLocaleDateString()})`
      )
      .join('\n')
  })

  const radiationDisplay = computed(() => {
    if (!allRadiation.value.length) return
    return allRadiation.value
      .map((rad) => new Date(rad.radiationDate).toLocaleDateString())
      .join('\n')
  })

  onBeforeMount(async () => {
    if (cancerDetailsId.value) {
      allTreatments.value = await fetchAllPages<Treatment>(
        getPatientTreatmentWithParts
      )
      allSurgeries.value = await fetchAllPages<Surgery>(
        getPatientSurgeryWithParts
      )
      allRadiation.value = await fetchAllPages<Radiation>(
        getPatientRadiationWithParts
      )
    }
  })

  return {
    treatmentDisplay,
    surgeryDisplay,
    radiationDisplay,
  }
}

/**
 *
 * @param patientId
 */
export function useDomains(patientId: Ref<string>) {
  const { data: domains } = storeToRefs(useDomainApi())

  const refetchDomains = async () => {
    await useDomainApi().list({
      params: { filter_patient_ids: [patientId.value] },
    })
  }

  onMounted(refetchDomains)

  const domainsDisplay = computed(() => {
    const domainsArr = Object.values(domains.value ?? {})
    return domainsArr.length
      ? domainsArr
          .map((domain) => startCase(camelCase(domain.domain)))
          .join(' • ')
      : '-'
  })

  return {
    domainsDisplay,
    refetchDomains,
  }
}

/**
 *
 */
export function useGoals() {
  const { data: goals } = storeToRefs(useGoalApi())
  const { data: pathways } = storeToRefs(usePathwayApi())
  const { data: tasks } = storeToRefs(useGoalTaskApi())

  const sortedGoals = computed(() => {
    return Object.values(goals.value ?? {})
      .filter((goal: Goal) => goal.status === GoalStatus.ACTIVE)
      .sort((a, b) => a.sortNumber - b.sortNumber)
  })

  const goalPathways = computed(() => {
    return Object.values(pathways.value ?? {}).filter((pathway: Pathway) => {
      return pathway.status === PathwayStatus.ACTIVE
    })
  })

  const goalTasks = computed(() => {
    return Object.values(tasks.value ?? {}).filter((task: Task) => {
      return task.goalIds.length && task.status === TaskStatus.ACTIVE
    })
  })

  /**
   *
   * @param goal
   */
  function getGoalPathways(goal: Goal) {
    return goalPathways.value
      .filter(
        (pathway: Pathway) =>
          pathway.goalIds.length && pathway.goalIds.includes(goal.goalId)
      )
      .map((pathway: Pathway) => {
        const pathwayTasks = Object.values(tasks.value ?? {}).filter(
          (task: Task) => task.pathwayIds.includes(pathway.pathwayId)
        )
        const completedTaskCount = pathwayTasks.filter(
          (task: Task) => task.status === TaskStatus.COMPLETED
        ).length
        const totalTaskCount = pathwayTasks.length
        return {
          ...pathway,
          completedTaskCount,
          totalTaskCount,
        }
      })
  }

  /**
   *
   * @param goal
   */
  function getGoalTasks(goal: Goal) {
    return goalTasks.value.filter(
      (task: Task) => task.goalIds.length && task.goalIds.includes(goal.goalId)
    )
  }

  /**
   *
   * @param goal
   */
  function getCompletedGoalTasks(goal: Goal) {
    const completedGoalTasks = Object.values(tasks.value ?? {}).filter(
      (task: Task) => {
        return task.goalIds.length && task.status === TaskStatus.COMPLETED
      }
    )
    return completedGoalTasks.filter(
      (task: Task) => task.goalIds.length && task.goalIds.includes(goal.goalId)
    )
  }

  return {
    sortedGoals,
    getGoalPathways,
    getGoalTasks,
    getCompletedGoalTasks,
  }
}

/**
 *
 * @param patientId
 */
export function usePatientSocialSummary(patientId: Ref<string>) {
  const { data: socialSummary } = storeToRefs(useSocialSummariesApi())

  const patientSocialSummary = computed(() =>
    socialSummary.value?.length ? socialSummary.value[0] : null
  )

  const benefitsProgramsServicesDisplay = computed(() => {
    if (!patientSocialSummary.value?.benefitsProgramsServices?.length) return
    return patientSocialSummary.value.benefitsProgramsServices
      .map((str) =>
        getLabels(
          str,
          benefitsProgramsServicesOptions,
          BenefitsProgramsServices
        )
      )
      .sort()
      .join(' • ')
  })

  const caregiverMappingDisplay = computed(() => {
    if (!patientSocialSummary.value?.caregiverMapping?.length) return
    return patientSocialSummary.value.caregiverMapping
      .map((str) => getLabels(str, caregiverMappingOptions, CaregiverMapping))
      .sort()
      .join(' • ')
  })

  const financialBarrierDisplay = computed(() => {
    if (!patientSocialSummary.value?.financialBarrier?.length) return
    return patientSocialSummary.value.financialBarrier
      .map((str) => getLabels(str, financialBarrierOptions, FinancialBarrier))
      .sort()
      .join(' • ')
  })

  const foodBarrierDisplay = computed(() => {
    if (!patientSocialSummary.value?.foodBarrier?.length) return
    return patientSocialSummary.value.foodBarrier
      .map((str) => getLabels(str, foodBarrierOptions, FoodBarrier))
      .sort()
      .join(' • ')
  })

  const isHealthcareProxyOrPOA = computed(() => {
    const healthcareProxy = patientSocialSummary.value?.healthcareProxy
    if (healthcareProxy == null) return
    return healthcareProxy ? 'Yes' : 'No'
  })

  const housingBarrierDisplay = computed(() => {
    if (!patientSocialSummary.value?.housingBarrier?.length) return
    return patientSocialSummary.value.housingBarrier
      .map((str) => getLabels(str, housingBarrierOptions, HousingBarrier))
      .sort()
      .join(' • ')
  })

  const inHomeSupportDisplay = computed(() => {
    if (!patientSocialSummary.value?.inHomeSupport?.length) return
    return patientSocialSummary.value.inHomeSupport
      .map((str) => getLabels(str, inHomeSupportOptions, InHomeSupport))
      .sort()
      .join(' • ')
  })

  const socialSupportDisplay = computed(() => {
    if (!patientSocialSummary.value?.socialSupport?.length) return
    return patientSocialSummary.value.socialSupport
      .map((str) => getLabels(str, socialSupportOptions, SocialSupport))
      .sort()
      .join(' • ')
  })

  const transportationBarrierDisplay = computed(() => {
    if (!patientSocialSummary.value?.transportationBarrier?.length) return
    return patientSocialSummary.value.transportationBarrier
      .map((str) =>
        getLabels(str, transportationBarrierOptions, TransportationBarrier)
      )
      .sort()
      .join(' • ')
  })

  const additionalContextDisplay = computed(
    () => patientSocialSummary.value?.additionalContext
  )

  const fetchSocialSummary = async () => {
    await useSocialSummariesApi().list({
      params: {
        filter_patient_ids: [patientId.value],
      },
    })
  }

  onMounted(fetchSocialSummary)

  return {
    socialSummaryId: patientSocialSummary.value?.socialSummaryId,
    benefitsProgramsServicesDisplay,
    caregiverMappingDisplay,
    financialBarrierDisplay,
    foodBarrierDisplay,
    isHealthcareProxyOrPOA,
    housingBarrierDisplay,
    inHomeSupportDisplay,
    socialSupportDisplay,
    transportationBarrierDisplay,
    additionalContextDisplay,
    fetchSocialSummary,
  }
}

/**
 *
 * @param patientId
 */
export function useFallRisk(patientId: Ref<string>) {
  const { data: fallRiskData } = storeToRefs(useFallRiskApi())
  const fallRisk = computed(() => fallRiskData.value?.[0] ?? null)
  const fallRiskId = computed(() => fallRisk.value?.fallRiskId)

  // Base for boolean values
  const fallInLastTwelveMonths = computed(
    () => fallRisk.value?.fallInLastTwelveMonthsEnum
  )
  const unsteadyWalkingOrStanding = computed(
    () => fallRisk.value?.unsteadyWalkingOrStandingEnum
  )
  const fearOfFalling = computed(() => fallRisk.value?.fearOfFallingEnum)
  const usesDmeAssistForAmbulation = computed(
    () => fallRisk.value?.usesDmeAssistForAmbulation
  )
  const hasPeripheralNeuropathy = computed(
    () => fallRisk.value?.hasPeripheralNeuropathy
  )
  const formatFallRiskResponse = (val: string | undefined) => {
    return val ? capitalize(val) : undefined
  }
  // Display for Yes/No
  const fallInTheLastTwelveMonthsDisplay = computed(() => {
    return formatFallRiskResponse(fallInLastTwelveMonths.value ?? undefined)
  })

  const unsteadyWithStandingOrWalkingDisplay = computed(() => {
    return formatFallRiskResponse(unsteadyWalkingOrStanding.value ?? undefined)
  })

  const fearOfFallingDisplay = computed(() => {
    return formatFallRiskResponse(fearOfFalling.value ?? undefined)
  })

  const usesDMEToAssistWithAmbulationDisplay = computed(() => {
    if (usesDmeAssistForAmbulation.value == null) return undefined
    return usesDmeAssistForAmbulation.value ? 'Yes' : 'No'
  })

  const peripheralNeuropathyDisplay = computed(() => {
    if (hasPeripheralNeuropathy.value == null) return undefined
    return hasPeripheralNeuropathy.value ? 'Yes' : 'No'
  })

  const anticoagulationDisplay = computed(() => {
    const anticoagulation = fallRisk.value?.anticoagulation
    const otherAnticoagulation = fallRisk.value?.otherAnticoagulation
    if (!anticoagulation?.length) return undefined

    const filtered = anticoagulation
      .filter((val: Anticoagulation) => val !== Anticoagulation.OTHER)
      .sort()
    const formatted = arrayToStringDisplay(filtered)

    if (!otherAnticoagulation) return formatted

    const formattedOther = formatStringDisplay(otherAnticoagulation)
    return formatted.length
      ? formatted + ' • ' + formattedOther
      : formattedOther
  })

  const sedatingMedicationsDisplay = computed(() => {
    const sedatingMedications = fallRisk.value?.sedatingMedications
    const otherSedatingMedications = fallRisk.value?.otherSedatingMedications
    if (!sedatingMedications?.length) return undefined

    const filtered = sedatingMedications.filter(
      (val: SedatingMedications) => val !== SedatingMedications.OTHER
    )
    const formatted = arrayToStringDisplay(filtered)

    if (!otherSedatingMedications) return formatted

    const formattedOther = formatStringDisplay(otherSedatingMedications)
    return formatted.length
      ? formatted + ' • ' + formattedOther
      : formattedOther
  })

  const additionalInformationDisplay = computed(
    () => fallRisk.value?.additionalContext ?? undefined
  )

  const refetchFallRisk = async () => {
    await getPatientFallRisk(patientId.value)
  }

  onMounted(refetchFallRisk)

  return {
    fallRisk,
    fallRiskId,
    // Raw values
    fallInLastTwelveMonths,
    unsteadyWalkingOrStanding,
    fearOfFalling,
    usesDmeAssistForAmbulation,
    hasPeripheralNeuropathy,
    // Display values
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
    refetchFallRisk,
  }
}

/**
 * Hook for managing medical conditions with and without attributes
 * @param patientId - Reference to the patient ID
 */
export function useMedicalConditions(patientId: Ref<string>) {
  const {
    data: medicalConditionsWithAttrs,
    isLoading: isLoadingMedicalConditionsWithAttrs,
  } = storeToRefs(useMedicalConditionsWithAttrsApi())

  const {
    data: medicalConditionsWithoutAttrs,
    isLoading: isLoadingMedicalConditionsWithoutAttrs,
  } = storeToRefs(useMedicalConditionsWithoutAttrsApi())

  const { data: medicalConditionAttrRefs, isLoading: isLoadingAttrs } =
    storeToRefs(useMedicalConditionAttrsRefApi())

  const { data: mappedAttributes, isLoading: isLoadingMappedAttributes } =
    storeToRefs(useMapMedicalAttrsMedicalConditionsApi())

  const medicalConditionsWithAttrsDisplay = computed(() => {
    if (!medicalConditionsWithAttrs.value?.length) return []

    return medicalConditionsWithAttrs.value
      .map((medicalCondition: MedicalCondition) => {
        // Get mapped attributes for this medical condition
        const conditionMappings =
          mappedAttributes.value?.filter(
            (mapping) =>
              mapping.medicalConditionRefId ===
              medicalCondition.medicalConditionRefId
          ) ?? []

        const attributesString = formatAttributesDisplay(
          medicalCondition.conditionSpecificAttributes,
          medicalConditionAttrRefs.value
        )

        return {
          medicalConditionId: medicalCondition.medicalConditionId,
          description: medicalCondition.medicalConditionReference.description,
          attributesString,
          isSensitive: medicalCondition.medicalConditionReference?.isSensitive,
          medicalConditionRefId: medicalCondition.medicalConditionRefId,
          mappedAttributes: conditionMappings,
          patientId: medicalCondition.patientId,
          isArchived: medicalCondition.isArchived,
        }
      })
      .filter((condition) => !condition.isArchived)
      .sort((a, b) => a.description.localeCompare(b.description))
  })
  const medicalConditionsWithoutAttrsDisplay = computed(() => {
    if (!medicalConditionsWithoutAttrs.value?.length) return []

    return medicalConditionsWithoutAttrs.value
      .map((medicalCondition: MedicalCondition) => ({
        medicalConditionId: medicalCondition.medicalConditionId,
        description:
          medicalCondition.otherMedicalCondition ??
          medicalCondition.medicalConditionReference.description,
        isSensitive: medicalCondition.medicalConditionReference?.isSensitive,
        medicalConditionRefId: medicalCondition.medicalConditionRefId,
        patientId: medicalCondition.patientId,
        isArchived: medicalCondition.isArchived,
      }))
      .filter((condition) => !condition.isArchived)
      .sort((a, b) => a.description.localeCompare(b.description))
  })

  const isLoadingMedicalConditions = computed(() => {
    return (
      isLoadingMedicalConditionsWithAttrs.value ||
      isLoadingMedicalConditionsWithoutAttrs.value ||
      isLoadingAttrs.value ||
      isLoadingMappedAttributes.value
    )
  })

  const fetchMedicalConditionsData = async () => {
    await getPatientMedicalConditions(patientId.value, true)
    await getPatientMedicalConditions(patientId.value, false)

    const medConditionRefIds =
      medicalConditionsWithAttrs.value
        ?.map((mc) => mc.medicalConditionRefId)
        .filter(Boolean) ?? []

    if (medConditionRefIds.length) {
      await getMapMedicalAttrsMedicalConditions(
        medConditionRefIds,
        [],
        ['medical_condition_reference', 'medical_condition_attribute_reference']
      )
    }
  }

  onMounted(fetchMedicalConditionsData)

  return {
    medicalConditionsWithAttrsDisplay,
    medicalConditionsWithoutAttrsDisplay,
    isLoadingMedicalConditions,
    fetchMedicalConditionsData,
  }
}
