import { capitalize } from 'lodash'
import { computed, ExtractPropTypes } from 'vue'
import { INSURANCE_DISPLAY_PROPS } from './types'

type InsuranceDisplaySetupProps = ExtractPropTypes<
  typeof INSURANCE_DISPLAY_PROPS
>

/**
 *
 * @param props
 * @param props.insurance
 * @param props.payers
 */
export function setup(props: InsuranceDisplaySetupProps) {
  /**
   * Return the insurance's name or 'N/A' if not available
   * @returns string
   */
  const renderInsuranceName = computed(() => {
    if (props.insurance.planName) {
      return props.insurance.planName
    }

    if (props.insurance.payerName === 'Other insurance') {
      return props.insurance.otherInsuranceName
    }

    return 'N/A'
  })

  /**
   * Returns 'Primary' if insurance is primary, 'Secondary' otherwise
   * @returns string
   */
  const renderInsurancePrimaryOrSecondary = computed(() =>
    props.insurance.isPrimary ? 'Primary' : 'Secondary'
  )

  /**
   * Returns 'Active' if insurance is active, 'Inactive' otherwise
   * @returns string
   */
  const renderInsuranceStatus = computed(() =>
    props.insurance.isActive ? 'Active' : 'Inactive'
  )

  /**
   * Returns different css style class for insurance status,
   * depending on whether the insurance is active or not
   * @returns css class string
   */
  const insuranceStatusStyle = computed(() =>
    props.insurance.isActive ? 'text-nash-fern600' : 'text-nash-brick600'
  )

  /**
   * Return the insurance's group id or 'N/A' if not available
   * @returns string
   */
  const renderGroup = computed(() => props.insurance.groupId ?? 'N/A')

  /**
   * Return the insurance's network tier or 'N/A' if not available
   * @returns string
   */
  const renderNetworkTier = computed(() => props.insurance.networkTier ?? 'N/A')

  /**
   * Return the insurance's line of business or 'N/A' if not available
   * @returns string
   */
  const renderLOB = computed(() => props.insurance.planType ?? 'N/A')

  /**
   * Return the insurance's market segment or 'N/A' if not available
   * @returns string
   */
  const renderMarketSegment = computed(() =>
    props.insurance.marketSegment
      ? capitalize(props.insurance.marketSegment)
      : 'N/A'
  )

  return {
    renderInsurancePrimaryOrSecondary,
    insuranceStatusStyle,
    renderInsuranceStatus,
    renderInsuranceName,
    renderLOB,
    renderMarketSegment,
    renderNetworkTier,
    renderGroup,
  }
}
