import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { compact } from 'lodash'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import {
  prioritiesFilter,
  languagesFilter,
  lobFilter,
  setupPayerFilter,
  subtaskTypeFilter,
  programStatusFilter,
  scheduledCallFilter,
  memberStateFilter,
  tierFilters,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { maxDateWithWeekends, stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime, formatName } from '@/legacy/libs/format'
import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import {
  useAssignedQueueScheduledCallsApi,
  useUnassignedQueueScheduledCallsApi,
} from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useReattemptStore } from '@/legacy/store/modules/reattempts'
import {
  usePreferredContactTimesApi,
  useSubtaskStaffStatsApi,
} from '@/legacy/store/modules/reminders'
import {
  useAssignedScheduledCallsSubtaskStacksApi,
  useAssignedSubtaskStacksApi,
  usePodLeadAssignedSubtaskStacksApi,
  useUnassignedScheduledCallsSubtaskStacksApi,
  useUnassignedSubtaskStacksApi,
} from '@/legacy/store/modules/subtasks'
import { MAX_PAGE_SIZE } from '@/legacy/types/api/apiBuilder'
import { CarePod } from '@/legacy/types/carePods'
import {
  Communication,
  CommunicationParts,
} from '@/legacy/types/communications/communications'
import { minDateTomorrow, today } from '@/legacy/types/global/dates'
import {
  QueueStyle,
  Subtask,
  openSubtaskStatuses,
} from '@/legacy/types/pathways/subtasks'
import { PreferredContactTime } from '@/legacy/types/patients/contactTimes'
import {
  ReminderPatient,
  Stack,
  queueSortBy,
  StackData,
} from '@/legacy/types/reminders'
import { formatTierScore } from '../../patient/acuity/lib/acuityFns'
import { SCHEDULED_CALL_TIME_SET_DURATION } from '../../patient/communicationV2/lib/plannedCalls'

// CONST
const STACK_ADDITIONAL_DAYS = 11
export const SELECTED_SUBTASK_LIMIT = 100
export const QUERY_PARTS = ['latest_reattempt']

export const maxStackDate = maxDateWithWeekends(
  today,
  STACK_ADDITIONAL_DAYS,
  false
)

export const maxStackDateDateTime = DateTime.fromJSDate(maxStackDate).set({
  hour: 0,
  minute: 0,
  second: 0,
})

export const dueBeforeTomorrow = DateTime.fromJSDate(minDateTomorrow)

/**
 *
 * Function to grab and display patient's contact preferences from fetched data
 * @param patientId
 */
function displayPreferredContactTimes(patientId: string | undefined) {
  const dataStream = usePreferredContactTimesApi().data
  if (dataStream && patientId && dataStream[patientId]) {
    return dataStream[patientId]
      .map(({ dayPart }: PreferredContactTime) => capitalize(dayPart))
      .join(', ')
  }
  return ''
}

/**
 *
 * Function to grab and display patient's contact preferences from fetched data
 * @param carePodId
 */
function displayCarePodName(carePodId: string | null | undefined) {
  const dataStream = useAdminCarePodApi().data
  if (dataStream?.length && carePodId) {
    return (
      dataStream.find((carePod: CarePod) => carePod.carePodId === carePodId)
        ?.name ?? 'Unknown Care Pod'
    )
  }
  return ''
}

/**
 *
 * Function that fetches patient's preferred contacts
 * @param stacks
 */
export async function getPreferredContactTimes(stacks: Stack[]) {
  const patientIds = stacks.map((stack: Stack) => stack.patient.entityId)
  await usePreferredContactTimesApi().list({
    params: {
      filter_patient_ids: patientIds,
    },
  })
}

/**
 *
 * Helper function to return proper api builder for store use
 * @param unassigned
 * @param isPodLeadView
 */
export function getQueueSubtaskApi(unassigned = false, isPodLeadView = false) {
  if (unassigned) {
    return useUnassignedSubtaskStacksApi
  }
  if (isPodLeadView) {
    return usePodLeadAssignedSubtaskStacksApi
  }
  return useAssignedSubtaskStacksApi
}

/**
 *
 * Function that looks up subtask stack list from existing api store data
 * based on passed patient ID for display in table
 *from store
 * @param patientId
 * @param unassigned
 * @param isPodLeadView
 */
export function returnSubtaskStackListPerPatient(
  patientId: string | undefined,
  unassigned = false,
  isPodLeadView = false
): Subtask[] | undefined {
  const subtaskApi = getQueueSubtaskApi(unassigned, isPodLeadView)
  const dataStream = subtaskApi().data

  if (dataStream && patientId) {
    return dataStream[patientId]
  }
}

/**
 *
 * Function that looks up subtask stack list from existing api store data
 * based on passed patient ID for display in table
 *from store
 * @param patientId
 * @param unassigned
 * @param isPodLeadView
 */
export function lookupAssignSubtaskStackListPerPatient(
  patientId: string | undefined,
  unassigned = false,
  isPodLeadView = false
): StackData | undefined {
  const subtaskApi = getQueueSubtaskApi(unassigned, isPodLeadView)

  const dataStream = subtaskApi().data

  if (dataStream && patientId) {
    return {
      subtasks: dataStream[patientId],
    }
  }
}

/**
 *
 * Function that looks up subtask stack list for a scheduled call queue row
 * from existing api store data based on passed communication ID for display in table
 * @param communicationId
 * @param unassigned
 */
export function lookupSubtaskStackListPerCommunication(
  communicationId: string,
  unassigned = false
): Subtask[] | undefined {
  const subtaskApi = unassigned
    ? useUnassignedScheduledCallsSubtaskStacksApi
    : useAssignedScheduledCallsSubtaskStacksApi
  const dataStream = subtaskApi().data

  if (dataStream) {
    const commsApi = unassigned
      ? useUnassignedQueueScheduledCallsApi
      : useAssignedQueueScheduledCallsApi
    const commsData = commsApi().data

    if (commsData) {
      const currentCommSubtaskIds = commsData[
        communicationId
      ]?.subtaskMaps?.map((subtask) => subtask.subtaskId)

      if (currentCommSubtaskIds?.length) {
        // We filter out linked subtasks that are not in openSubtaskStatuses in API call so
        // dataStream may not include some currentCommSubtaskIds so must filter results
        return currentCommSubtaskIds
          ?.map((subtaskId) => dataStream[subtaskId])
          .filter((maybeSubtask) => !!maybeSubtask)
      }
    }
  }
}

/**
 *
 * Function that looks up communication data and subtask stack list for a scheduled
 * call queue row from existing api store data based on passed communication ID for
 * use by Assign All column
 * @param communicationId
 * @param unassigned
 */
export function lookupAssignStackListPerCommunication(
  communicationId: string,
  unassigned = false
): StackData | undefined {
  const subtaskApi = unassigned
    ? useUnassignedScheduledCallsSubtaskStacksApi
    : useAssignedScheduledCallsSubtaskStacksApi
  const dataStream = subtaskApi().data

  if (dataStream) {
    const commsApi = unassigned
      ? useUnassignedQueueScheduledCallsApi
      : useAssignedQueueScheduledCallsApi
    const commsData = commsApi().data

    if (commsData) {
      const commObj = commsData[communicationId]
      if (commObj === undefined) {
        return
      }
      const currentCommSubtaskIds = commObj.subtaskMaps?.map(
        (subtask) => subtask.subtaskId
      )

      return {
        scheduled_call: commObj,
        subtasks: compact(
          currentCommSubtaskIds?.map((subtaskId) => dataStream[subtaskId])
        ),
      }
    }
  }
}

/**
 *
 * Function that looks up a communication for a scheduled call queue row
 * from existing api store data based on passed communication ID for display in table
 * from store
 * @param communicationId
 * @param isUnassignedQueue
 */
export function lookupScheduledCall(
  communicationId: string,
  isUnassignedQueue = false
): Communication | undefined {
  const scheduledCallsApi = isUnassignedQueue
    ? useUnassignedQueueScheduledCallsApi
    : useAssignedQueueScheduledCallsApi

  const dataStream = scheduledCallsApi().data

  if (dataStream && communicationId) {
    return dataStream[communicationId]
  }
}

/**
 *
 *Function that returns subtask stack staff stats list based on passed staff ID
 *from store
 * @param staffId
 */
export function returnSubtaskStatsPerStaff(staffId: string | undefined) {
  const dataStream = useSubtaskStaffStatsApi().data
  if (dataStream && staffId) {
    return dataStream[staffId]
  }
}

/**
 * Function that populates needed data to display scheduled calls rows in queues
 * It takes all communication IDs for a given page of the queue and grabs the
 * communication objects associated with them and then calls to get associated subtask objects
 * @param stacks
 * @param isUnassignedQueue
 */
export async function getScheduledCalls(
  stacks: Stack[],
  isUnassignedQueue: boolean
) {
  const commIdsWithNone = stacks.map((stack: Stack) => stack.communicationId)
  const commIds = commIdsWithNone.filter((maybeId?: string) => !!maybeId)

  const scheduledCallsApi = isUnassignedQueue
    ? useUnassignedQueueScheduledCallsApi
    : useAssignedQueueScheduledCallsApi
  // if no commIds then stacks do not include any scheduled call rows
  // and no need to grab any communication data
  if (!commIds?.length) {
    return
  }

  const results = await scheduledCallsApi().listAll({
    params: {
      page_length: MAX_PAGE_SIZE,
      filter_communication_ids: commIds,
      parts: [CommunicationParts.planned, CommunicationParts.subtaskMaps],
    },
  })

  if (results?.data) {
    await getScheduledCallSubtaskStacks(results.data, isUnassignedQueue)
  }
}

/**
 * Function that takes communications and uses the subtask ids given in their subtaskMaps
 * to grab all subtask objects (if any) needed to populate scheduled call queue rows task columns
 * @param communications all scheduled call objects for a given queue page
 * @param isUnassignedQueue
 */
export async function getScheduledCallSubtaskStacks(
  communications: Communication[],
  isUnassignedQueue: boolean
) {
  const scheduledCallSubtaskIds = communications.flatMap(
    (comm) => comm.subtaskMaps?.map((subtask) => subtask.subtaskId) ?? []
  )
  const scheduledCallSubtasksStackApi = isUnassignedQueue
    ? useUnassignedScheduledCallsSubtaskStacksApi
    : useAssignedScheduledCallsSubtaskStacksApi

  // if no scheduledCallSubtaskIds then none of the scheduled calls in the queue
  // are linked to subtasks and no need to grab subtask data
  if (!scheduledCallSubtaskIds?.length) {
    return
  }

  await scheduledCallSubtasksStackApi().listAll({
    params: {
      page_length: MAX_PAGE_SIZE,
      sort_by: queueSortBy,
      filter_subtask_ids: scheduledCallSubtaskIds,
      filter_subtask_status: openSubtaskStatuses,
      parts: QUERY_PARTS,
    },
  })
}

/**
 *
 * Function that fetches stacks of subtasks per given patient IDs
 * @param stacks
 * @param isUnassignedQueue
 * @param isPodLeadView
 * @param isEnrollment
 * @param includeScheduledCalls
 * @param additionalQueryParams
 */
export async function getSubtaskStacks(
  stacks: Stack[],
  isUnassignedQueue: boolean,
  isPodLeadView = false,
  isEnrollment = false,
  includeScheduledCalls: boolean,
  additionalQueryParams?: { [key: string]: any }
) {
  const subtaskStacks = stacks.filter((stack: Stack) => !stack.communicationId)
  const patientIds = subtaskStacks.map((stack: Stack) => stack.patient.entityId)

  const queueStyle = computed(() => {
    // Assigned queue should not filter by queue_style
    if (!isUnassignedQueue) {
      return null
    } else if (isEnrollment) {
      return QueueStyle.ENROLLMENT
    } else {
      return QueueStyle.STANDARD
    }
  })

  const subtaskApi = getQueueSubtaskApi(isUnassignedQueue, isPodLeadView)

  const results = await subtaskApi().listAll({
    params: {
      parts: QUERY_PARTS,
      page_length: MAX_PAGE_SIZE,
      sort_by: queueSortBy,
      filter_patient_ids: patientIds,
      ...(queueStyle.value ? { filter_queue_style: [queueStyle.value] } : {}),
      ...(isPodLeadView ? {} : { limit_per_member: 5 }),
      ...(includeScheduledCalls
        ? { exclude_scheduled_call_subtasks: true }
        : {}),
      ...additionalQueryParams,
    },
  })

  return results
}

/**
 *
 * Function to fetch reattempted subtask information if subtask has been reattempted
 * @param subtasks
 */
export async function getReattemptsForListedSubtasks(subtasks: Subtask[]) {
  const reattemptedSubtaskIds = subtasks
    .filter((subtask: Subtask) => !!subtask.hideDatetime)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((subtask: Subtask) => subtask.subtaskId)

  if (reattemptedSubtaskIds.length) {
    await useReattemptStore().getReattempts({
      filter_subtask_ids: reattemptedSubtaskIds,
    })
  }
}

/**
 *
 * Function to fetch extra data needed to render table information
 * @param stacks
 * @param isUnassignedQueue
 * @param isPodLeadView
 * @param isEnrollment
 * @param includeScheduledCalls
 * @param additionalSubtaskQueryParams additional query params for LIST /subtasks call
 */
export async function getFollowUpStacksData(
  stacks: Stack[],
  isUnassignedQueue: boolean,
  isPodLeadView: boolean,
  isEnrollment = false,
  includeScheduledCalls = false,
  additionalSubtaskQueryParams?: { [key: string]: any }
) {
  if (includeScheduledCalls) {
    await Promise.all([
      getPreferredContactTimes(stacks),
      getSubtaskStacks(
        stacks,
        isUnassignedQueue,
        isPodLeadView,
        isEnrollment,
        includeScheduledCalls,
        additionalSubtaskQueryParams
      ),
      getScheduledCalls(stacks, isUnassignedQueue),
    ])
  } else {
    await Promise.all([
      getPreferredContactTimes(stacks),
      getSubtaskStacks(
        stacks,
        isUnassignedQueue,
        isPodLeadView,
        isEnrollment,
        includeScheduledCalls,
        additionalSubtaskQueryParams
      ),
    ])
  }
}

/**
 *
 * Function that takes in field names and columns and filter out columns flagged to be hidden
 * @param fields
 * @param columns
 */
export function hideColumns(fields: string[], columns: ColumnOptions[]) {
  columns
    .filter((column) => fields.includes(column.field as string))
    .forEach((column) => (column.hidden = true))
  return columns
}

/**
 * Helper function to return patient's preferred contact
 * time and if applicable, their time in their timezone.
 * @param entityId
 * @param timezone
 * @param displayCurrentTime
 */
function renderPreferredContactString(
  entityId: string,
  timezone: string | null,
  displayCurrentTime = true
) {
  const preferredContactTime = displayPreferredContactTimes(entityId)
  const patientPrefersString = `${
    preferredContactTime ? `Prefers ${preferredContactTime}` : ''
  }`

  let finalStr = ''

  if (timezone && displayCurrentTime) {
    const dateStr = DateTime.now().setZone(timezone).toLocaleString({
      timeZone: timezone,
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
    })

    const currentMemberTimeStr = `(Member current time is ${dateStr})`
    finalStr += `${currentMemberTimeStr}`
  }

  if (patientPrefersString) {
    finalStr += ` \n \n ${patientPrefersString}`
  }

  return finalStr
}

export const patientColumn: ColumnOptions = {
  field: 'patient',
  header: 'Member Name',
  display: (p: ReminderPatient) =>
    p ? formatName(p.person.firstName, p.person.lastName) : 'Unknown Member',
  linkFn: (_: any, row: Stack) => ({
    path: `/patient/${row.patient.entityId}/`,
  }),
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

export const tierColumn: ColumnOptions = {
  field: 'acuityScore',
  header: 'Tier',
  display: (_: any, row: Stack) =>
    row.patient
      ? formatTierScore(row.patient.pinnedAcuityScore?.overallScore)
      : '',
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

export const languageColumn: ColumnOptions = {
  field: 'preferredLanguage',
  header: 'Language',
  display: (_: any, row: Stack) =>
    row.patient ? startCase(row.patient.person.preferredLanguage) || '' : '',
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

export const getTimingColumn = (isUnassignedQueue = false): ColumnOptions => {
  return {
    field: 'preferredContactTime',
    header: 'Preferred Time(s)',
    sortable: false,
    display: (_: any, row: Stack) => {
      if (row?.communicationId) {
        const comm = lookupScheduledCall(row.communicationId, isUnassignedQueue)
        if (comm?.plannedCall?.dueDatetime) {
          const callTimeSet =
            comm.plannedCall.duration === SCHEDULED_CALL_TIME_SET_DURATION
          const dateFormat = callTimeSet
            ? DateTime.DATETIME_FULL
            : DateTime.DATE_FULL
          const dateStr = formatDateTime(
            stringToDateTime(comm.plannedCall.dueDatetime),
            dateFormat
          )
          return (
            `Scheduled call on ${dateStr} \n \n` +
            renderPreferredContactString(
              row.patient.entityId,
              row.patient.timezone,
              false
            )
          )
        }
        return (
          `Scheduled call \n \n` +
          renderPreferredContactString(
            row.patient.entityId,
            row.patient.timezone
          )
        )
      }
      return renderPreferredContactString(
        row.patient.entityId,
        row.patient.timezone
      )
    },
    style: { 'flex-wrap': 'wrap', width: '15%' },
    truncateLength: 1000,
  }
}

export const getSubtasksColumn = (
  unassigned = false,
  isPodLeadView = false
) => {
  const { showEnrollmentQueueEnhancements } = storeToRefs(useFlagStore())
  return {
    field: 'subtasks',
    header: showEnrollmentQueueEnhancements.value ? 'Subtasks' : 'Tasks',
    displayData: (row: Stack) =>
      row.communicationId
        ? lookupSubtaskStackListPerCommunication(
            row.communicationId,
            unassigned
          )
        : returnSubtaskStackListPerPatient(
            row.patient.entityId,
            unassigned,
            isPodLeadView
          ),
    style: { 'flex-wrap': 'wrap', width: '38%' },
  }
}

export const assignAllColumn = {
  field: 'assign',
  header: 'Assign All',
  displayData: (row: Stack) =>
    row.communicationId
      ? lookupAssignStackListPerCommunication(row.communicationId, true)
      : lookupAssignSubtaskStackListPerPatient(row.patient.entityId, true),
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

export const carePodColumn = {
  field: 'carePodId',
  header: 'Pod',
  display: (_: any, r: Stack) =>
    r ? displayCarePodName(r.patient.carePodId) : 'Unknown Care Pod',
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

/**
 * Better name for this would be great.
 * I'm not sure what this does exactly in the pod lead view,
 * but it was part of the previous "sharedColumns" column array.
 * I think it's just a filler/blank spacing column?
 */
export const podLeadViewBlankFillerColumn = {
  field: '',
  header: '',
  style: { 'flex-wrap': 'wrap', width: '40rem' },
  display: () => '',
}

export const sharedFilters = () => {
  const nonAcuityFilters = {
    priorities: prioritiesFilter,
    languages: languagesFilter,
    lobs: lobFilter,
    payerIds: setupPayerFilter(),
    programStatus: programStatusFilter,
    scheduledCallSubtasks: scheduledCallFilter,
    state: memberStateFilter,
  }
  return {
    ...tierFilters,
    ...nonAcuityFilters,
    subtaskKeys: subtaskTypeFilter,
  }
}

export const queueListParams = {
  limit_per_member: 1,
  subtask_parts: [
    'member',
    'member.latest_acuity_score',
    'member.pinned_acuity_score',
    'subtask_template.base',
  ],
  sort_by: queueSortBy,
}
