import {
  ColumnOptions,
  FilterMetaType,
} from '@thyme/nashville/src/types/tables'
import startCase from 'lodash/startCase'
import {
  alignedPracticeFilter,
  languagesFilter,
  lobFilter,
  memberStateFilter,
  programStatusFilter,
  setupPayerFilter,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { MAX_PAGE_SIZE } from '@/legacy/types/api/apiBuilder'
import { EntityRole } from '@/legacy/types/entities/entities'
import {
  QueueStyle,
  SubtaskStatus,
  SubtaskVisibility,
  openAssignedSubtaskStatuses,
} from '@/legacy/types/pathways/subtasks'
import { Stack } from '@/legacy/types/reminders'
import { getQueueSubtaskApi, QUERY_PARTS } from './sharedQueueParts'

export const getSubtasksFromEnrollmentQueueStacks = async (
  stacks: Stack[],
  unassigned: null | any = null,
  staffId: string | null = null
) => {
  /**
   * Fetches all enrollment subtasks for the patients in the
   * provided stack.
   */

  const patientIds = stacks.map((stack: Stack) => stack.patient.entityId)

  const subtaskApi = getQueueSubtaskApi(unassigned)

  const statusesToFilterBy = unassigned
    ? [SubtaskStatus.OPEN_UNASSIGNED]
    : openAssignedSubtaskStatuses

  await subtaskApi().listAll({
    params: {
      parts: QUERY_PARTS,
      page_length: MAX_PAGE_SIZE,
      filter_patient_ids: patientIds,
      filter_subtask_status: statusesToFilterBy,
      ...(unassigned ? {} : { filter_staff_ids: [staffId] }),
      ...(unassigned ?? {}),
      ...(unassigned
        ? { filter_roles: [EntityRole.CLINICAL__ENROLLMENT_SPECIALIST] }
        : {}),
      ...(unassigned ? { filter_queue_style: [QueueStyle.ENROLLMENT] } : {}),
      filter_subtask_visibility: [SubtaskVisibility.NO_HIDDEN],
    },
  })
}

export const enrollmentTableFilters = (): FilterMetaType => ({
  languages: languagesFilter,
  lobs: lobFilter,
  payerIds: setupPayerFilter(),
  programStatus: programStatusFilter,
  alignedPractice: alignedPracticeFilter,
  state: memberStateFilter,
  freeTextName: { value: null, matchMode: undefined },
})

export const freeTextOptions = [{ label: 'Name', value: 'freeTextName' }]

export const segmentColumn: ColumnOptions = {
  field: 'enrollmentSegment',
  header: 'Segment',
  display: (_: any, row: Stack) =>
    row.patient.prioritySegment
      ? startCase(row.patient.prioritySegment.enrollmentSegment.toLowerCase())
      : '',
  style: { 'flex-wrap': 'wrap', width: '10%' },
}

export const callHistoryColumn: ColumnOptions = {
  field: 'prioritySegment',
  header: 'Call History',
  display: (_: any, row: Stack) => {
    const prioritySegment = row.patient.prioritySegment
    if (prioritySegment) {
      const callNum = prioritySegment.totalEnrollmentOutreaches
      return `${callNum} call(s)`
    }
    return ''
  },
  style: { 'flex-wrap': 'wrap', width: '15%' },
  truncateLength: 1000,
}
