<template>
  <div>
    <CarePlan />
    <ClinicalSummary />
    <SocialSummary />
    <PatientGoals />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CarePlan from '@/pages/PatientProfile/CarePlans/CarePlan/CarePlan.vue'
import ClinicalSummary from '@/pages/PatientProfile/CarePlans/ClinicalSummary/ClinicalSummary.vue'
import PatientGoals from '@/pages/PatientProfile/CarePlans/PatientGoals/PatientGoals.vue'
import SocialSummary from '@/pages/PatientProfile/CarePlans/SocialSummary/SocialSummary.vue'
export default defineComponent({
  components: {
    CarePlan,
    PatientGoals,
    SocialSummary,
    ClinicalSummary,
  },
  setup() {
    return {}
  },
})
</script>
<style lang="scss"></style>
