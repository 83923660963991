import FormsortWebEmbed, { IFormsortWebEmbed } from '@formsort/web-embed-api'
import { Ref } from 'vue'
import { Config } from '@/legacy/types/api/config'
import {
  FORMSORT_ENV_KEY,
  INLINE_STEPS_KEY,
} from '@/legacy/types/forms/formSort'
import { getFormResponse } from './queries'
import { FORM_EMBED_ROOT_ID, FORMSORT_ACCOUNT_ID } from './types'

export const getExistingFormResponse = async (formResponseId: string) => {
  const formResponseData = await getFormResponse(formResponseId)

  if (!formResponseData || !formResponseData.length) {
    throw Error(`The form response ${formResponseId} could not be found`)
  }

  if (formResponseData.length > 1) {
    throw Error(
      `More than one form response found for the given id ${formResponseId}`
    )
  }

  const formResponse = formResponseData[0]
  return formResponse
}

export const loadFormResponse = async (
  formResponseId: string,
  onSuccess: (formResponse: any) => void,
  onError: (errorMsg: string) => void
) => {
  try {
    const formResponse = await getExistingFormResponse(formResponseId)
    onSuccess(formResponse)
  } catch (e: any) {
    onError(
      e.message ?? `The form response ${formResponseId} could not be loaded`
    )
  }
}

export const embedForm = async (
  formEmbed: Ref<IFormsortWebEmbed | null>,
  flowLabel: string,
  variantLabel: string,
  variantRevisionUuid: string,
  prefillData: [string, string][] | null,
  configVals: Config | null,
  onSuccess: () => void,
  onError: (errorMsg: string) => void
) => {
  const rootEl = document.getElementById(FORM_EMBED_ROOT_ID)
  if (!rootEl) {
    const msg = `Root element with id ${FORM_EMBED_ROOT_ID} not found. If developing locally, ensure the embedding div is present in the HTML`
    console.debug(msg)
    onError(msg)
    return
  }

  if (!formEmbed.value) {
    formEmbed.value = FormsortWebEmbed(rootEl, {
      autoHeight: true,
      style: { width: '100%' },
    })
  }

  let formsortEnvParam: [string, string] | null = null
  if (configVals && configVals.env !== 'prod') {
    formsortEnvParam = [FORMSORT_ENV_KEY, configVals.env]
  }
  if (flowLabel && variantLabel && configVals?.env) {
    formEmbed.value.loadFlow(FORMSORT_ACCOUNT_ID, flowLabel, variantLabel, [
      ...(formsortEnvParam ? [formsortEnvParam] : []),
      ['variantRevisionUuid', variantRevisionUuid],
      [INLINE_STEPS_KEY, 'true'],
      ...(prefillData ?? []),
    ])

    onSuccess()
  } else {
    const msg = `Either no flow associated with form response or data is missing to load a flow with
          flow label: ${flowLabel} and variant label: ${variantLabel}`

    console.log(msg)
    onError(msg)
  }
}
