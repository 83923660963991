import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useSocialSummariesApi } from '@/pages/PatientProfile/CarePlans/SocialSummary/store'
import { SocialSummaryPayload } from '../types'

/**
 * create social summary
 * @param payload
 */
export async function createSocialSummary(payload: SocialSummaryPayload) {
  let res
  try {
    res = await useSocialSummariesApi().create({ body: payload })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error creating social summary',
      type: NotificationType.DANGER,
      error: `Error creating social summary: ${err}`,
    })
  }
  useNotificationStore().setNotification({
    message: 'Successfully created social summary.',
    type: NotificationType.SUCCESS,
  })
  return res
}

/**
 * update existing social summary
 * @param payload
 * @param socialSummaryId
 */
export async function updateSocialSummary(
  payload: SocialSummaryPayload,
  socialSummaryId: string
) {
  let res
  try {
    res = await useSocialSummariesApi().partialUpdate({
      body: payload,
      ids: [socialSummaryId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error updating social summary',
      type: NotificationType.DANGER,
      error: `Error updating social summary: ${err}`,
    })
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated social summary.',
    type: NotificationType.SUCCESS,
  })
  return res
}
