<template>
  <div class="overflow-visible px-5">
    <div v-if="showTabbedPatientProfile" class="mb-5">
      <div class="flex justify-between items-center">
        <h3 class="font-medium py-2 print-h3">Additional Information</h3>
        <TMSecondaryButton icon="pencil" @click="toggleEditPatientSummary" />
      </div>
      <TSpinner
        v-if="patientLoading"
        name="patient-summary"
        :partial-page="true"
      />
      <TMarkdown v-else-if="patientDisplay" :source="patientDisplay.summary" />
    </div>
    <div v-else>
      <h3 class="py-2">Summary</h3>
      <TSpinner
        v-if="patientLoading"
        name="patient-summary"
        :partial-page="true"
      />

      <div v-else-if="patientDisplay">
        <TMAutosaveInput
          :auto-resize="false"
          :data="patientDisplay.summary"
          :api-call="submit"
          :warn-unsaved-changes="true"
          :save-state="saveState"
          :type="InputType.TEXTAREA"
        />
      </div>
      <div v-else class="p-5">Error loading patient summary details.</div>
    </div>
  </div>
  <EditPatientSummary
    v-if="showEditPatientSummary"
    :is-open="showEditPatientSummary"
    :summary="patientDisplay?.summary ?? ''"
    @close="toggleEditPatientSummary"
    @submit="submit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { setup } from './controller'
import EditPatientSummary from './EditPatientSummary/EditPatientSummary.vue'

export default defineComponent({
  components: {
    EditPatientSummary,
    TMSecondaryButton,
    TMarkdown,
    TSpinner,
    TMAutosaveInput,
  },
  setup,
})
</script>
