import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useSocialSummariesApi } from './store'

/**
 *
 * @param patientId
 */
export async function getSocialSummary(patientId: string) {
  try {
    const res = await useSocialSummariesApi().list({
      params: {
        filter_patient_ids: [patientId],
      },
    })
    return res.datum
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching social summary',
      type: NotificationType.WARNING,
      error: `Error fetching social summary: ${err}`,
    })
    return null
  }
}
