export enum TabMenuItems {
  CARE_PLAN = 'care-plan',
  CLINICAL_SUMMARY = 'clinical-summary',
  SOCIAL_SUMMARY = 'social-summary',
  HISTORY = 'history',
  TO_DO = 'to-do',
  SYMPTOMS = 'symptoms',
  TEXTING = 'texting',
  MEDICAL_CONTEXT = 'medical-context',
  DOCUMENTS = 'documents',
}
