import { UUID } from '../global/base'

export type HumanaDncPayload = {
  patient_id: UUID
  phone_number: string // plain text phone number
}

export type HumanaDncResponse = {
  isDnc: boolean
}

export type HumanaDncDisplay = {
  isDnc: boolean | null
  alternateModalText: string | null
}

export const DNC_STRING = 'Do Not Call'
