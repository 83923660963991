import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { startCase, camelCase, toLower, toUpper } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useGoalApi } from '@/legacy/store/modules/goals'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { NotificationType } from '@/legacy/types/notifications'
import { GoalStatus } from '@/legacy/types/pathways/goals'
import {
  IntentMap,
  Patient,
  StatusMap,
  programStatusOptions,
  treatmentIntentOptions,
  treatmentStatusOptions,
} from '@/legacy/types/patients/patients'
import { getDomains } from '../queries'
import { useDomainApi } from '../stores'
import { DomainsEnum } from '../types'
import {
  createPinnedScore,
  updatePinnedScore,
  upsertDomain,
  updatePatient,
} from './queries'
import { usePinnedAcuityApi } from './stores'
import { TierLevel } from './types'

// Helper functions to update tier level
export const tierOptions = Object.values(TierLevel)
  .filter((level) => level !== TierLevel.UNMAPPED_TIER)
  .map((level) => ({
    label: startCase(toLower(level)),
    value: level,
  }))

export const domainDropdownOptions = Object.values(DomainsEnum)
  .map((title) => {
    return { label: startCase(camelCase(title)), value: title }
  })
  .sort((a, b) => a.label.localeCompare(b.label))

// Setup -------------

/**
 *@param props
 * @param context
 */
export default function (props: any, context: any) {
  const { patient } = storeToRefs(usePatientStore())
  const { data: domains } = storeToRefs(useDomainApi())
  const { data: goals } = storeToRefs(useGoalApi())
  const { datum: pinnedAcuityScore } = storeToRefs(usePinnedAcuityApi())

  const { canEditPatientAcuityScore, canEditDomains } = storeToRefs(
    useProfileStore()
  )
  const currentTierLevel = computed(() =>
    patient.value?.pinnedAcuityScore
      ? toUpper(formatTierScore(patient.value.pinnedAcuityScore.overallScore))
      : ''
  )
  const currentTreatmentIntent = ref(patient.value?.treatmentIntent)
  const currentDomains = computed(() => {
    return domains.value
      ? Object.values(domains.value).map((domain) => domain.domain)
      : []
  })

  const dirtyDomains = ref(currentDomains.value)
  const dirtyTier = ref<TierLevel | string>(currentTierLevel.value)
  const dirtyTreatmentStatus = ref<Partial<Patient>>({
    treatmentStatus: patient.value?.treatmentStatus,
    treatmentSubstatus: patient.value?.treatmentSubstatus,
  })
  const dirtyTreatmentIntent = ref<Partial<Patient>>({
    treatmentIntent: currentTreatmentIntent.value,
  })
  const dirtyProgramStatus = ref<Partial<Patient>>({
    programStatus: patient.value?.programStatus,
    programSubstatus: patient.value?.programSubstatus,
  })

  /**
   *
   * @param patientId
   * @param domains
   */
  async function updateDomain(patientId: string, domains: string[]) {
    try {
      await upsertDomain(patientId, domains)
      await getDomains(patientId)
    } catch (err) {
      console.log(err)
      useNotificationStore().setNotification({
        message: 'Failed to update domain.',
        type: NotificationType.DANGER,
      })
    }
  }

  /**
   * set new program / treatment status values
   * @param fieldPrefix
   * @param val
   */
  async function handleStatusInput(fieldPrefix: string, val: StatusMap) {
    const newValues = {
      [`${fieldPrefix}Status`]: val.status,
      [`${fieldPrefix}Substatus`]: val.substatus,
    }
    if (fieldPrefix === 'treatment') {
      dirtyTreatmentStatus.value = newValues
    } else if (fieldPrefix === 'program') {
      dirtyProgramStatus.value = newValues
    }
  }

  /**
   * set new TreatmentIntent value
   * @param val
   */
  async function handleIntentInput(val: IntentMap) {
    dirtyTreatmentIntent.value.treatmentIntent = val.key
  }

  /**
   * update existing pinned acuity or create new one
   * @param tierLevel
   */
  async function updateOrCreatePinnedScore(tierLevel: string) {
    if (!patient.value) {
      return
    }
    const tier = tierLevel ?? ''
    try {
      if (patient.value?.pinnedAcuityScore) {
        await updatePinnedScore(
          tier,
          patient.value.entityId,
          patient.value.pinnedAcuityScore.pinnedAcuityScoreId
        )
      } else {
        await createPinnedScore(tier, patient.value.entityId)
      }
    } catch (err) {
      console.log(err)
      useNotificationStore().setNotification({
        message: 'Failed to update tier.',
        type: NotificationType.DANGER,
      })
      return
    }
    patient.value.pinnedAcuityScore = pinnedAcuityScore.value
  }

  /**
   * reset dirty values to current values for non-domain dropdowns
   */
  function resetNonDomainDirtyValues() {
    dirtyTier.value = currentTierLevel.value
    dirtyTreatmentStatus.value = {
      treatmentStatus: patient.value?.treatmentStatus,
      treatmentSubstatus: patient.value?.treatmentSubstatus,
    }
    dirtyProgramStatus.value = {
      programStatus: patient.value?.programStatus,
      programSubstatus: patient.value?.programSubstatus,
    }
    dirtyTreatmentIntent.value.treatmentIntent = currentTreatmentIntent.value
  }

  /**
   *
   */
  function verifyNonDomainChanges() {
    const promises: Promise<void>[] = []
    // check for dropdown values that have changed so
    // that we only make necessary updates
    if (currentTierLevel.value !== dirtyTier.value) {
      promises.push(updateOrCreatePinnedScore(dirtyTier.value))
    }
    if (dirtyTreatmentIntent.value !== currentTreatmentIntent.value) {
      promises.push(
        updatePatient(patient.value?.entityId, {
          ...dirtyTreatmentIntent.value,
        })
      )
    }
    const shouldUpdateTreatmentStatus =
      dirtyTreatmentStatus.value.treatmentStatus !==
        patient.value?.treatmentStatus ||
      dirtyTreatmentStatus.value.treatmentSubstatus !==
        patient.value?.treatmentSubstatus
    const shouldUpdateProgramStatus =
      dirtyProgramStatus.value.programStatus !== patient.value?.programStatus ||
      dirtyProgramStatus.value.programSubstatus !==
        patient.value?.programSubstatus

    if (shouldUpdateTreatmentStatus) {
      promises.push(
        updatePatient(patient.value?.entityId, {
          ...dirtyTreatmentStatus.value,
        })
      )
    }
    if (shouldUpdateProgramStatus) {
      promises.push(
        updatePatient(patient.value?.entityId, {
          ...dirtyProgramStatus.value,
        })
      )
    }
    return promises
  }

  /**
   *
   * verify if domain changes are valid and update
   */
  async function verifyAndUpdateDomainChanges() {
    if (currentDomains.value !== dirtyDomains.value) {
      const removedDomains = currentDomains.value.filter(
        (d) => dirtyDomains.value.indexOf(d) === -1
      )
      const domainsToBeRemovedIsMapped = removedDomains.filter((d) =>
        checkForMappedDomains(d)
      )
      if (domainsToBeRemovedIsMapped.length) {
        useNotificationStore().setNotification({
          message: `Cannot remove domain(s) that are associated with active goals: ${domainsToBeRemovedIsMapped}`,
          type: NotificationType.DANGER,
        })
        return
      } else if (patient.value && dirtyDomains.value) {
        await updateDomain(patient.value.entityId, dirtyDomains.value)
      }
    }
    return
  }

  /**
   * function to check if domain is already mapped to a goal
   * @param domainStr
   */
  function checkForMappedDomains(domainStr: string) {
    const currentMappedDomainIds = Object.values(goals.value ?? {})
      .filter((goal) => goal.status === GoalStatus.ACTIVE)
      .map((goal) => goal.domainId)
    const domainId = Object.values(domains.value ?? {}).find(
      (d) => d.domain === domainStr
    )?.domainId
    return currentMappedDomainIds.some(
      (goalDomainId) => goalDomainId === domainId
    )
  }

  /**
   * reset dirty values and close modal
   */
  function close() {
    resetNonDomainDirtyValues()
    context.emit('close')
  }

  /**
   * make updates and close modal
   * first updates non-domain values, then domain values
   */
  async function save() {
    const nonDomainUpdates = verifyNonDomainChanges()
    try {
      await Promise.all(nonDomainUpdates)
    } catch (err) {
      console.log(err)
      return
    }
    useNotificationStore().setNotification({
      message: 'Successfully updated care plan.',
      type: NotificationType.SUCCESS,
    })
    resetNonDomainDirtyValues()

    try {
      await verifyAndUpdateDomainChanges()
    } catch (err) {
      console.log(err)
      return
    }
    // refetch values for last updated section under care plans
    thymeDispatch('care-planV2-update')
    close()
  }

  return {
    resetNonDomainDirtyValues,
    currentTierLevel,
    patient,
    ModalSize,
    ButtonSize,
    canEditPatientAcuityScore,
    canEditDomains,
    updateOrCreatePinnedScore,
    // modal values for dropdowns
    dirtyTreatmentStatus,
    dirtyTreatmentIntent,
    dirtyProgramStatus,
    dirtyTier,
    dirtyDomains,
    // dropdown options
    tierOptions,
    treatmentStatusOptions,
    treatmentIntentOptions,
    programStatusOptions,
    domainDropdownOptions,
    // update dropdowns
    handleStatusInput,
    handleIntentInput,
    // button actions
    close,
    save,
  }
}
