<template>
  <Transition>
    <TNotification v-if="notification" />
  </Transition>
  <section class="bg-nash-neutral000">
    <div class="h-full overflow-y-auto flex">
      <div class="is-fullwidth mx-auto my-0">
        <div class="my-5">
          <h2>Humana Eligibility Check</h2>
          <p>
            Please complete the fields below to check a member's eligibility
          </p>
        </div>
        <div class="my-5 space-y-4">
          <div>
            <TInputText
              v-model="humanaMemberId"
              label="Member ID"
              name="humanaMemberId"
              data-testid="humana-member-id"
            />
          </div>
          <div>
            <TCalendar
              v-model="humanaMemberDob"
              :min-date="null"
              :disable-holidays="false"
              name="humanaMemberDob"
              label="DOB (MM/DD)"
              class="w-full"
              date-format="mm/dd"
            />
          </div>
          <div class="flex justify-between">
            <TMPrimaryButton
              :disabled="disableSubmit"
              :loading="isLoading"
              name="humana-eligibility-check-submit"
              label="Check Eligibility"
              @click="onSubmit"
            />
            <TMTertiaryButton
              v-if="showClearButton"
              :disabled="isLoading"
              label="Clear"
              icon-position="left"
              :icon="AllowedIcons.close"
              @click="clearInputs"
            />
          </div>
          <div>
            <TMessage
              v-if="eligibilityResponse && eligibilityResponseDetails"
              :expanded="true"
              :severity="eligibilityResponseDetails.severity"
              name="humana-eligibility-response"
              :custom-icon="eligibilityResponseDetails.icon"
              :label="eligibilityResponseDetails.label"
              class="max-w-lg"
            >
              <template #extracontent>
                <div class="instructions">
                  <ul class="list-disc">
                    <li>
                      {{ eligibilityResponseDetails.instructions }}
                      <div
                        v-if="eligibilityResponseDetails.resourceLink"
                        class="mt-3"
                      >
                        <a
                          :href="eligibilityResponseDetails.resourceLink"
                          target="_blank"
                        >
                          <TMTertiaryButton
                            label="Inbound Referrals SOP"
                            icon-pos="right"
                            icon="heroicons:arrow-top-right-on-square"
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TMessage>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'

import { setup } from './controller'

export default defineComponent({
  components: {
    TMessage,
    TInputText,
    TCalendar,
    TMPrimaryButton,
    TMTertiaryButton,
    TNotification,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.instructions {
  padding: 10px 20px 5px;
}
</style>
