import { IconifyIcons } from '@thyme/nashville/src/types/icons'
import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { Message } from '@twilio/conversations'
import { storeToRefs } from 'pinia'
import { ComputedRef, computed, onMounted, ref, watch, onUpdated } from 'vue'

import { useRoute } from 'vue-router'
import { formatPhone } from '@/legacy/libs/format'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE,
  useTextingStore,
} from '@/legacy/store/modules/textingV2'
import { TextCommunication } from '@/legacy/types/communications/communications'
import { DNC_STRING } from '@/legacy/types/patients/humanaDnc'
import { PROGRAM_STATUS_DO_NOT_CALL_KEY } from '@/legacy/types/patients/patients'
import {
  isPhoto,
  getTexteeNameOrFormatNumber,
  isPatient,
  scrollIntoViewToLatestMessage,
  UNKNOWN_TEXTS_PAGE_URL_NAME,
} from '../lib/sharedTextingParts'

/**
 *
 * Setup function for AllSms.vue
 */
export default function () {
  const shouldNotContactMessage =
    'Text messages cannot be sent because this member is on the Humana Do Not Call (DNC) list. Members on this list have asked their insurance not to call them.'

  const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
    useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId
  const {
    activeCommunication,
    activeConversation,
    messages,
    messagesLoading,
    conversationsError,
    messagesError,
    mediaLinks,
    smsCommsForActiveConversation,
    messageDeliveryStatusMapBySid,
    conversationsClientError,
  } = storeToRefs(useTextingStore())
  const { showHumanaDnc } = storeToRefs(useFlagStore())
  const { patient } = storeToRefs(usePatientStore())

  const route = useRoute()

  const doNotContact = computed(
    () => patient.value?.programSubstatus === PROGRAM_STATUS_DO_NOT_CALL_KEY
  )

  const shouldNotContact = computed(
    () => doNotContact.value && showHumanaDnc.value
  )

  const isUnknownTextsPage = computed(
    () => route.name === UNKNOWN_TEXTS_PAGE_URL_NAME
  )
  const patientId = computed(() =>
    isUnknownTextsPage.value && TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      ? TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      : `${route.params.patientId ?? ''}`
  )
  const queryData = computed(() => route.query)
  const queryPhoneNumId = computed(() =>
    getValFromQuery(queryData.value, 'phoneNumberId')
  )
  const queryCommId = computed(() => getValFromQuery(queryData.value, 'commId'))

  const totalSmsCommsForIndividual = computed(
    () => smsCommsForActiveConversation.value?.queryMetadata?.total ?? 0
  )
  const currentNumOfCommsFetched = computed(
    () => smsCommsForActiveConversation.value?.data.length ?? 0
  )
  const currentPage = ref(DEFAULT_PAGE_NUM)

  const currCommPhoneNumberStr: ComputedRef<string> = computed(
    () =>
      `+${activeCommunication.value?.smsThread.phoneNumber.countryCode}${activeCommunication.value?.smsThread.phoneNumber.phoneNumber}`
  )

  const showLoadMore = computed(
    () => currentNumOfCommsFetched.value < totalSmsCommsForIndividual.value
  )
  const hasErrors = computed(
    () =>
      !!conversationsError.value ||
      !!messagesError.value ||
      !!conversationsClientError.value
  )

  /**
   *
   * Function to create a mapping of messages to sent date
   * e.g. { '01/01/2020': Message[]  }
   */
  function mapMessagesToDate() {
    const mapping: { [key: string]: Message[] } = {}
    for (const message of messages.value) {
      const monthDateYearString = message.dateUpdated?.toLocaleDateString()
      if (!mapping[monthDateYearString]) {
        mapping[monthDateYearString] = [message as Message]
      } else {
        mapping[monthDateYearString].push(message as Message)
      }
    }
    return mapping
  }

  const messagesByDateArray: ComputedRef<[string, Message[]][] | null> =
    computed(() => {
      const messagesMappedByDate = mapMessagesToDate()
      if (Object.keys(messagesMappedByDate).length) {
        return Object.keys(messagesMappedByDate).map((dateStr: string) => [
          dateStr,
          messagesMappedByDate[dateStr],
        ])
      }
      return null
    })

  const noUnknownTexts = computed(
    () =>
      isUnknownTextsPage.value && !queryCommId.value && !queryPhoneNumId.value
  )

  const allMessageDataLoaded = computed(() => {
    // This is the first message we're sending to this number
    if (queryPhoneNumId.value?.length) {
      return true
    }
    if (!!queryCommId.value?.length && !!hasErrors.value) {
      return true
    }
    if (noUnknownTexts.value) {
      return true
    }
    // If there is a comm, but there are no messages for convo, stop loading
    // This can happen if we created a planned text, but it hasn't been sent yet OR we cancelled and closed the convo without sending
    if (
      !!queryCommId.value?.length &&
      (!messagesByDateArray.value?.length ||
        !messageDeliveryStatusMapBySid.value.length)
    ) {
      return true
    }

    return (
      !messagesLoading.value &&
      !!queryCommId.value?.length &&
      !!messages.value.length &&
      !!messagesByDateArray.value?.length &&
      !!Object.values(messageDeliveryStatusMapBySid.value).length
    )
  })

  const neverTextedMember = computed(
    () => !messagesByDateArray.value?.length && queryPhoneNumId.value?.length
  )

  watch(currentNumOfCommsFetched, () => {
    // if current number of fetched sms communications for
    // active thread is 10, which indicates a refreshed state,
    // set currentPage value back to default of 1
    if (currentNumOfCommsFetched.value === DEFAULT_PER_PAGE) {
      currentPage.value = DEFAULT_PAGE_NUM
    }
  })

  /**
   * Function that opens download prompt on the user's window
   * @param media
   */
  async function download(media: any) {
    window.open(await media.getContentTemporaryUrl())
  }

  /**
   * Function that checks if a message is part an auto text or
   * bulk text.
   * @param message
   */
  function isAutoOrBulkText(message: Message) {
    const convoSid = message.conversation.sid
    const smsCommunicationMatch =
      smsCommsForActiveConversation.value?.data.find(
        (smsCommunication: TextCommunication) => {
          return smsCommunication.smsThread.twilioConversationIds.includes(
            convoSid
          )
        }
      )
    if (smsCommunicationMatch) {
      return (
        smsCommunicationMatch.campaignId ??
        !smsCommunicationMatch.smsThread.includesManualTexts
      )
    }
    return false
  }

  /**
   *
   * Function that is called when "Load more" button
   * is clicked in AllSms.vue.
   *
   * Calls a method `loadMoreSmsCommsForActiveThread()` which
   * fetches the previous 10 sms communications for
   * current selected individual's current 10 sms communications
   * that were initially loaded.
   *
   * Then, calls methods to set conversations and messages
   *  data in the store.
   */
  async function loadPrevTenSmsComms() {
    currentPage.value = currentPage.value += 1
    await useTextingStore().loadMoreSmsCommsForActiveThread({
      phoneNumberIds: [
        activeCommunication.value?.smsThread.phoneNumberId ?? '',
      ],
      entityIds: [patientId.value],
      pageNumber: currentPage.value,
    })
    await useTextingStore().getAndSetConversationsForIndividual()

    await useTextingStore().getAndSetMessagesForIndividual()
    await useTextingStore().mapMessagesToDeliveryStatus()
  }

  onMounted(async () => {
    if (activeConversation.value) {
      try {
        await activeConversation.value.join()
      } catch (e) {
        console.log('conversation already joined')
        console.log(e)
      }
    }
  })

  onUpdated(() => {
    const routeHash = route.hash
    if (
      allMessageDataLoaded.value &&
      currentPage.value === DEFAULT_PAGE_NUM &&
      !routeHash.length
    ) {
      scrollIntoViewToLatestMessage()
    }
  })

  return {
    DNC_STRING,
    IconifyIcons,
    shouldNotContactMessage,
    shouldNotContact,
    formatPhone,
    isUnknownTextsPage,
    noUnknownTexts,
    queryData,
    MessageSeverity,
    hasErrors,
    neverTextedMember,
    allMessageDataLoaded,
    queryCommId,
    queryPhoneNumId,
    currentPage,
    totalSmsCommsForIndividual,
    currentNumOfCommsFetched,
    loadPrevTenSmsComms,
    showLoadMore,
    isAutoOrBulkText,
    messagesByDateArray,
    currCommPhoneNumberStr,
    download,
    // textingStoreV2.ts
    messageDeliveryStatusMapBySid,
    mediaLinks,
    activeCommunication,
    conversationsError,
    messagesError,
    conversationsClientError,
    // sharedTextingParts.ts
    isPhoto,
    getTexteeNameOrFormatNumber,
    isPatient,
    // store/modules/staff.ts
    getStaffNameFromId,
  }
}
