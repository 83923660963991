<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Social Summary"
    :size="ModalSize.LG"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5 overflow-scroll">
      <div class="flex flex-col space-y-3">
        <TMDropdownField
          label="Transportation Barrier"
          name="transportationBarrier"
          class="w-full my-2"
          :multiple="true"
          :options="transportationBarrierOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.transportationBarrier"
        />

        <TMDropdownField
          label="Food Barrier"
          name="foodBarrier"
          class="w-full my-2"
          :multiple="true"
          :options="foodBarrierOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.foodBarrier"
        />

        <TMDropdownField
          label="Housing Barrier"
          name="housingBarrier"
          class="w-full my-2"
          :multiple="true"
          :options="housingBarrierOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.housingBarrier"
        />

        <TMDropdownField
          label="Financial Barrier"
          name="financialBarrier"
          class="w-full my-2"
          :multiple="true"
          :options="financialBarrierOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.financialBarrier"
        />

        <TMDropdownField
          label="Social Support"
          name="socialSupport"
          class="w-full my-2"
          :multiple="true"
          :options="socialSupportOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.socialSupport"
        />

        <TMDropdownField
          label="In-Home Support"
          name="inHomeSupport"
          class="w-full my-2"
          :multiple="true"
          :options="inHomeSupportOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.inHomeSupport"
        />

        <TMDropdownField
          label="Caregiver Mapping"
          name="caregiverMapping"
          class="w-full my-2"
          :multiple="true"
          :options="caregiverMappingOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.caregiverMapping"
        />

        <TMDropdownField
          label="Benefits, Programs, and Services"
          name="benefitsProgramsServices"
          class="w-full my-2"
          :multiple="true"
          :options="benefitsProgramsServicesOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.benefitsProgramsServices"
        />

        <TMDropdownField
          label="Healthcare Proxy / Healthcare POA"
          name="healthcareProxy"
          class="w-full my-2"
          :options="booleanOptions"
          option-label="label"
          option-value="value"
          :model-value="dirtyModel.healthcareProxy ?? undefined"
        />

        <TTextarea
          label="Additional Context"
          name="additionalContext"
          :model-value="dirtyModel.additionalContext"
        />
      </div>
    </div>

    <template #actions>
      <div class="w-full flex flex-row justify-end space-x-4">
        <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
        <TMPrimaryButton label="Save" name="save" @click="onSubmit" />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'

import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { editSocialOrOffboardingSummaryProps } from '../types'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TMDropdownField,
    TTextarea,
  },
  props: editSocialOrOffboardingSummaryProps,
  emits: ['close', 'refetchSocialSummary'],
  setup,
})
</script>
