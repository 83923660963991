import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useCancerDetailApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { CancerDiagnosis } from '@/pages/PatientProfile/CarePlans/shared/types'

/**
 * Retrieve the patient primary diagnosis details for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientCancerDetailWithRefs(
  patientId: string
): Promise<CancerDiagnosis | null> {
  const cancerDetailsApi = useCancerDetailApi()
  const cancerDetailsResponse = await cancerDetailsApi.list({
    params: {
      parts: [
        'oncology_spread_ref_maps.oncology_spread_reference',
        'oncology_diagnosis_reference.oncology_diagnosis_category_reference',
      ],
      filter_is_primary_diagnosis: true,
      filter_patient_ids: [patientId],
    },
  })
  if (!cancerDetailsResponse?.data?.length) {
    return null
  }

  if (cancerDetailsApi.error) {
    const error = cancerDetailsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient cancer detail`,
      type: NotificationType.WARNING,
      error: `Error fetching patient cancer detail: ${error}`,
    })
  }

  return cancerDetailsResponse.data[0]
}
