import uniq from 'lodash/uniq'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import {
  capitalize,
  computed,
  ref,
  onMounted,
  watch,
  ComputedRef,
  onBeforeUnmount,
} from 'vue'
import { useRouter } from 'vue-router'
import {
  getConvosForSmsComms,
  getMessagesForConvos,
} from '@/legacy/components/texting/lib/sharedTextingParts'
import { stringToDateTime } from '@/legacy/libs/date'
import { humanizeRole } from '@/legacy/libs/entity'
import {
  formatDateTime,
  formatName,
  formatNameFromEntity,
  formatNameFromPerson,
  formatPhone,
  separateAndTruncate,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'

import {
  isCallType,
  useCommunicationsStore,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { getStaffNameFromId, useStaffApi } from '@/legacy/store/modules/staff'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { useTextingStore } from '@/legacy/store/modules/textingV2'
import {
  CallDirection,
  callDispositionDisplayLookup,
  callTypeDisplayLookupV2,
  SpeakingWithType,
} from '@/legacy/types/communications/callDispositions'
import {
  CommunicationType,
  CommunicationSubtype,
  CommunicationSubtaskMap,
  TextCommunication,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import { handleRerouteToTextingInbox } from '../../sidebar/lib/texting'

/**
 *
 * @param props
 */
export default function (props: any) {
  // -----   Stores
  const { patient } = storeToRefs(usePatientStore())
  const communicationsStore = useCommunicationsStore()
  const staffApi = useStaffApi()

  const router = useRouter()

  const mappedSubtasks = ref<Subtask[] | null>(null)

  // ----- Collapsable UI for SMS communications
  const convos = ref<any[] | null>(null)
  const convoError = ref<string | null>(null)
  const messages = ref<any[] | null>(null)
  const messagesError = ref<string | null>(null)

  const mappedSubtaskIds = computed(() =>
    props.communication.subtaskMaps
      ? props.communication.subtaskMaps.map(
          (obj: CommunicationSubtaskMap) => obj.subtaskId
        )
      : []
  )
  watch(mappedSubtaskIds, async () => await fetchSubtask())

  /**
   * Function to set up communication row data and
   * texting details if SMS communication.
   */
  async function setupCommsRowData() {
    await fetchSubtask()
    if (isText.value) {
      await getAndSetConvoDetails()
      await getAndSetMessageDetails()
    }
  }

  /**
   *
   * Function to fetch and set any mapped subtasks to current
   * communication.
   */
  async function fetchSubtask() {
    if (mappedSubtaskIds.value.length) {
      const results = await useSubtasksStore().getSubtasks({
        filter_patient_ids: [props.patientId],
        filter_subtask_ids: mappedSubtaskIds.value,
      })
      mappedSubtasks.value = results.data
    } else {
      mappedSubtasks.value = null
    }
  }

  onMounted(setupCommsRowData)

  // -----   Basic Comm Info
  const commType = computed<CommunicationType | CommunicationSubtype>(() =>
    props.communication.callDisposition
      ? CommunicationSubtype.Disposition
      : props.communication.plannedCall
      ? CommunicationSubtype.Planned
      : props.communication.smsThread &&
        props.communication.smsThread.twilioConversationIds
      ? CommunicationType.Text
      : props.communication.type
  )

  const isCall = computed(() => isCallType(commType.value))

  const isEmail = computed(() => commType.value === CommunicationType.Email)

  const isPlanned = computed(
    () => commType.value === CommunicationSubtype.Planned
  )

  const isFax = computed(() => commType.value === CommunicationType.Fax)

  const isOther = computed(() => commType.value === CommunicationType.Other)
  const isText = computed(() => commType.value === CommunicationType.Text)

  const isPlannedText = computed(
    () => isText.value && !!props.communication.plannedText
  )

  const hasSmsThread = computed(
    () =>
      props.communication.smsThread &&
      props.communication.smsThread.twilioConversationIds &&
      !props.communication.callDisposition &&
      !props.communication.plannedCall
  )

  const time = computed(() => {
    if (
      props.communication.callDisposition ||
      (!isCall.value && props.communication.completedDatetime)
    ) {
      return props.communication.completedDatetime ?? ''
    }

    if (props.communication.plannedCall) {
      return props.communication.plannedCall.dueDatetime
    }

    return props.communication.createdAt
  })

  const displayDate = computed(() =>
    formatDateTime(stringToDateTime(time.value))
  )
  const displayTime = computed(() =>
    formatDateTime(stringToDateTime(time.value), DateTime.TIME_SIMPLE)
  )

  const showTime = computed(
    () =>
      props.communication.callDisposition ||
      !isCall.value ||
      (props.communication.plannedCall &&
        props.communication.plannedCall.showTime)
  )

  const note = computed(() => props.communication.notes)

  const clickCommunication = async () => {
    if (!props.communication.communicationId) {
      useNotificationStore().setNotification({
        message: 'The requested communication was not found.',
        type: NotificationType.DANGER,
      })
      return
    }
    if (isText.value) {
      const phoneNumberId = (props.communication as TextCommunication).smsThread
        .phoneNumberId
      void handleRerouteToTextingInbox(phoneNumberId, patient.value?.entityId)
    } else {
      await router.push({
        query: {
          commId: props.communication.communicationId,
        },
      })
    }
  }

  // -----   Call Display Lookups

  const callDispositionType = computed(() =>
    hasSmsThread.value
      ? props.communication.completedDatetime
        ? 'Completed'
        : 'Owned'
      : (props.communication.callDisposition &&
          callDispositionDisplayLookup[
            props.communication.callDisposition.disposition
          ]) ??
        (props.communication.plannedCall && 'To Do') ??
        ''
  )

  const callTypeDisplay = computed(() =>
    hasSmsThread.value
      ? callTypeDisplayLookupV2['text']
      : props.communication.callDisposition
      ? callTypeDisplayLookupV2[
          props.communication.callDisposition.direction.toLocaleLowerCase()
        ]
      : props.communication.plannedCall
      ? callTypeDisplayLookupV2['plannedCall']
      : commType.value === CommunicationType.Call
      ? 'Outbound Call'
      : 'Unknown'
  )

  const phoneNumberDisplay = computed(() => {
    const unformattedNumber =
      props.communication.smsThread?.phoneNumber.phoneNumber
    return unformattedNumber ? formatPhone(unformattedNumber) : null
  })

  // -----   Person Lookups

  const getPersonName = (
    entityId: string | null,
    speakingWithType?: SpeakingWithType
  ): string => {
    if (entityId === null) {
      return speakingWithType ? capitalize(speakingWithType) : 'Unlisted Callee'
    } else {
      const { firstName, lastName } =
        safeLookup(entityId, communicationsStore.communicationPersons) ?? {}
      return formatName(firstName, lastName)
    }
  }

  const getStaffName = (staffId: string | null): string => {
    if (staffId) {
      return getStaffNameFromId(staffId, '') as string
    }
    return 'Unknown'
  }

  const commOwner = computed(() => {
    if (props.communication.responsibleRole) {
      return humanizeRole(props.communication.responsibleRole)
    }
    return (
      getStaffName(props.communication.responsibleStaffId) ||
      'Unknown Staff Member'
    )
  })
  const iconDisplay = computed(() => {
    if (isCall.value && isPlanned.value) {
      return { icon: 'phone', color: '' }
    }
    if (
      isCall.value &&
      props.communication.callDisposition?.direction ===
        CallDirection.inbound &&
      props.communication.callDisposition?.disposition === 'ANSWERED'
    ) {
      return { icon: 'phoneIncoming', color: '' }
    }
    if (
      isCall.value &&
      props.communication.callDisposition?.direction === CallDirection.inbound
    ) {
      return { icon: 'phoneMissed', color: '' }
    }
    if (
      isCall.value &&
      props.communication.callDisposition?.disposition === 'ANSWERED'
    ) {
      return { icon: 'phoneOutgoing', color: 'nash-purple600' }
    }
    if (
      isCall.value &&
      props.communication.callDisposition?.disposition === 'LEFT_VOICEMAIL'
    ) {
      return { icon: 'phoneLeftVoiceMail', color: '' }
    }
    if (
      isCall.value &&
      props.communication.callDisposition?.disposition === 'NUMBER_INOPERABLE'
    ) {
      return { icon: 'exclamationTriangle', color: '' }
    }
    if (isCall.value) {
      return { icon: 'phoneXMark', color: '' }
    }
    if (isText.value) {
      return { icon: 'chatSolid', color: 'nash-purple600' }
    }
    if (isEmail.value) {
      return { icon: 'email', color: '' }
    }
    if (isFax.value) {
      return { icon: 'fax', color: '' }
    }
    return { icon: 'heroicons:paper-airplane', color: '' }
  })

  const texteeDisplay = computed(() => {
    if (hasSmsThread.value) {
      const smsThread = props.communication.smsThread
      let texteeArr: string[] = []
      if (smsThread && smsThread.people) {
        texteeArr = smsThread.people.map(formatNameFromPerson)
      }
      return `with ${uniq(texteeArr).join(', ')}`
    }
    return null
  })

  const speakingWithType = computed(
    () => props.communication.callDisposition?.speakingWithType
  )
  const callDirection = computed(
    () => props.communication.callDisposition?.direction
  )

  const callerDisplay = computed(() => {
    if (callDirection.value === CallDirection.inbound) {
      return ` ${getPersonName(
        props.communication.callDisposition.speakingWithPersonId,
        speakingWithType.value
      )}`
    }
    return props.communication.callDisposition
      ? getStaffName(props.communication.callDisposition.completedByStaffId)
      : props.communication.plannedCall
      ? getStaffName(props.communication.responsibleStaffId)
      : 'Unknown'
  })

  const calleeDisplay = computed(() => {
    if (hasSmsThread.value) {
      return getStaffName(props.communication.responsibleStaffId)
    }

    if (callDirection.value === CallDirection.inbound) {
      return getStaffName(
        props.communication.callDisposition.completedByStaffId
      )
    }

    return props.communication.callDisposition
      ? ` ${getPersonName(
          props.communication.callDisposition.speakingWithPersonId,
          speakingWithType.value
        )}`
      : props.communication.plannedCall
      ? ` ${getPersonName(
          props.communication.plannedCall.calleeEntityId,
          speakingWithType.value
        )}`
      : ''
  })

  // -----   Subtasks information
  const linkedSubtasks = computed<Subtask[] | null>(() => mappedSubtasks.value)

  /**
   * Helper function to get and render subtask owner name (staff).
   * @param staffId
   */
  function getSubtaskOwner(staffId: string) {
    return formatNameFromEntity(safeLookup(staffId, staffApi.data))
  }

  /**
   *
   * Helper function to push selected subtask id to URL
   * @param subtaskId
   */
  function selectSubtask(subtaskId: string) {
    void router.push({
      query: { subtaskId },
    })
  }

  // -----   Outcome information

  const outcomeModalOpen = ref(false)

  const setOutcomeModalVisibility = (isVisible: boolean) =>
    (outcomeModalOpen.value = isVisible)

  // -----  SMS Communications Messages UI
  onBeforeUnmount(useTextingStore().reset)
  const totalMessages = computed(() => messages.value?.length)
  const currCommPhoneNumberStr: ComputedRef<string> = computed(
    () =>
      `+${props.communication?.smsThread.phoneNumber.countryCode}${props.communication?.smsThread.phoneNumber.phoneNumber}`
  )
  /**
   *
   * Function to get and set conversation details from current communication
   * if SMS.
   */
  async function getAndSetConvoDetails() {
    await useTextingStore().init()
    const convoDetails = await getConvosForSmsComms([props.communication])
    convos.value = convoDetails?.convos ?? null
    convoError.value = convoDetails?.error ?? null
  }

  /**
   * Function to get and set conversation messages details from current communication
   * if SMS.
   */
  async function getAndSetMessageDetails() {
    if (convos.value?.length) {
      const messageDetails = await getMessagesForConvos(convos.value)

      if (messageDetails?.messages) {
        messages.value = messageDetails.messages.sort()
      }
      messagesError.value = messageDetails?.error ?? null
    }
  }

  return {
    isPlannedText,
    currCommPhoneNumberStr,
    messages,
    messagesError,
    totalMessages,
    convos,
    convoError,
    mappedSubtasks,
    mappedSubtaskIds,
    selectSubtask,
    humanizeRole,
    linkedSubtasks,
    getSubtaskOwner,
    isEmail,
    isFax,
    isPlanned,
    isText,
    isOther,
    commId: props.communication.communicationId,
    isCall,
    displayDate,
    displayTime,
    note,
    callDispositionType,
    calleeDisplay,
    callerDisplay,
    callTypeDisplay,
    phoneNumberDisplay,
    commOwner,
    outcomeModalOpen,
    clickCommunication,
    separateAndTruncate,
    setOutcomeModalVisibility,
    showTime,
    texteeDisplay,
    CallDirection,
    iconDisplay,
    callDispositionDisplayLookup,
  }
}
