<template>
  <div
    v-if="dataHasLoaded && hasTextablePhoneNumber"
    class="bg-nash-neutral000 p-5"
    data-cy="thymeline-container"
  >
    <TextingThymeline />
  </div>
  <div v-else-if="!hasTextablePhoneNumber">
    This patient does not have any textable phone numbers.
  </div>
  <TSpinner v-else name="texting-tab" :partial-page="true" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TextingThymeline from '@/legacy/components/patient/thymeline/texting/TextingThymeline.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TextingThymeline,
  },
  setup,
})
</script>
