import type { Entity } from '@/legacy/types/entities/entities'
import type { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type Contact = {
  contactId: UUID
  contactEntityId: UUID
  patientId: UUID
  approvedToSpeak: boolean
  isPrimaryCaregiver: boolean
  caregiverPoa: boolean
  relationshipToPatient: Nullable<string>
  approvedToAccessMedInfo: Nullable<string>
  contactEntity: Entity
} & Timestamps

export type ContactsFormData = {
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  state: string
  zip: string

  firstName: string
  lastName: string
  preferredLanguage: string

  phoneNumber: string
  dateOfBirth: Date | undefined
  messagingOptInStatus: boolean | null

  approvedToSpeak: string
  isPrimaryCaregiver: string | undefined
  caregiverPoa: string | undefined
  relationshipToPatient: string | undefined
  approvedToAccessMedInfo: string | undefined
}

// ENUMS --------------------
export enum OldRelationshipToPatient {
  partner = 'Partner',
  parent = 'Parent',
  child = 'Child',
  sibling = 'Sibling',
  poa = 'POA',
  guardian = 'Guardian',
  other = 'Other',
}

export enum NewRelationshipToPatient {
  child = 'Child',
  cousin = 'Cousin',
  friend = 'Friend',
  grandparent = 'Grandparent',
  niece_or_nephew = 'Niece or Nephew',
  parent = 'Parent',
  partner = 'Partner',
  religious_or_community_leader = 'Religious or community based leader',
  sibling = 'Sibling',
  thyme_care_team_member = 'Thyme Care Team Member',
  other = 'Other',
}

export enum ApprovedToAccessMedInfo {
  UNKNOWN = 'Unknown',
  YES = 'Yes',
  NO = 'No',
}
