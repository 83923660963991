import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import {
  CancerDiagnosis,
  CarePlanTransitionsOfCare,
  FallRisk,
  MapMedicalAttrsMedicalConditions,
  MedicalCondition,
  MedicalConditionAttributeReference,
  MedicalTeam,
  PatientClinicalSummary,
  PhysicalFunctionAssessment,
  Radiation,
  RadiationDataState,
  Surgery,
  SurgeryDataState,
  Treatment,
  TreatmentDataState,
} from './types'

// PATIENT CANCER DETAILS ----------------
export const useCancerDetailApi = apiStore<CancerDiagnosis>(
  'cancerDetailApi',
  '/api/care_plan/cancer_diagnoses',
  {
    transformData: (d: { data: CancerDiagnosis[] }) => d,
  }
)

// PATIENT CLINICAL SUMMARY ----------------
export const usePatientClinicalSummaryApi = apiStore<PatientClinicalSummary>(
  'patientClinicalSummaryApi',
  '/api/care_plan/patient_clinical_summary'
)

// MEDICAL CONDITIONS ----------------
export const useMedicalConditionsCarePlanApi = apiStore<MedicalCondition>(
  'medicalConditionsCarePlanApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

// PATIENT TOCS ----------------

export const useCarePlanTransitionsOfCareApi =
  apiStore<CarePlanTransitionsOfCare>(
    'carePlanTransitionsOfCareApi',
    '/api/care_plan/care_plan_transitions_of_care',
    {
      transformData: (d: { data: CarePlanTransitionsOfCare[] }) => d,
    }
  )

// PHYSICAL FUNCTION ASSESSMENT ----------------
export const usePhysicalFunctionAssessmentApi =
  apiStore<PhysicalFunctionAssessment>(
    'physicalFunctionAssessmentApi',
    '/api/care_plan/physical_function_assessment',
    {
      transformData: (d: { data: PhysicalFunctionAssessment[] }) => d,
    }
  )

// FALL RISK ----------------
export const useFallRiskApi = apiStore<FallRisk>(
  'fallRiskApi',
  '/api/care_plan/fall_risk',
  {
    transformData: (d: { data: FallRisk[] }) => d,
  }
)

// MEDICAL TEAM ----------------
export const useMedicalTeamApi = apiStore<MedicalTeam>(
  'medicalTeamApi',
  '/api/care_plan/cancer_med_team',
  {
    transformData: (d: { data: MedicalTeam[] }) => d,
  }
)

// MEDICAL CONDITIONS ----------------
export const useMedicalConditionsWithAttrsApi = apiStore<MedicalCondition>(
  'medicalConditionsWithAttrsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

export const useMedicalConditionsWithoutAttrsApi = apiStore<MedicalCondition>(
  'medicalConditionsWithoutAttrsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

export const useMapMedicalAttrsMedicalConditionsApi =
  apiStore<MapMedicalAttrsMedicalConditions>(
    'mapMedicalAttrsMedicalConditionsApi',
    '/api/map_med_condition_attrs_ref_med_conditions_ref',
    {
      transformData: (d: { data: MapMedicalAttrsMedicalConditions[] }) => d,
    }
  )

export const useMedicalConditionAttrsRefApi =
  apiStore<MedicalConditionAttributeReference>(
    'medicalConditionAttrsRefApi',
    '/api/medical_condition_attributes/reference',
    {
      transformData: (d: { data: MedicalConditionAttributeReference[] }) => d,
    }
  )

/**
 *
 * @param data
 * @param total
 */
function transformTreatments(
  data: Treatment[],
  total: number
): Partial<TreatmentDataState> {
  const currentData = useTreatmentApi().data
  const currentDataMap = toIdMap(data, 'patientTreatmentId')
  const newData = Object.assign({}, currentDataMap, currentData)
  return {
    ...idMapTransform({}, 'data', 'patientTreatmentId', Object.values(newData)),
    queryMetadata: { total },
  }
}
export const useTreatmentApi = apiStore<Treatment, IdMap<Treatment>>(
  'treatmentApi',
  '/api/care_plan/patient_treatments',
  {
    transformData: (d: {
      data: Treatment[]
      queryMetadata: { total: number }
    }) => transformTreatments(d.data, d.queryMetadata.total),
  }
)

/**
 *
 * @param data
 * @param total
 */
function transformSurgeries(
  data: Surgery[],
  total: number
): Partial<SurgeryDataState> {
  const currentData = useSurgeryApi().data
  const currentDataMap = toIdMap(data, 'patientSurgeryId')
  const newData = Object.assign({}, currentDataMap, currentData)
  // sort direction should be in desc order based on surgeryDate
  const sortedData = Object.values(newData).sort((a: Surgery, b: Surgery) => {
    const aDate = new Date(a.surgeryDate).valueOf()
    const bDate = new Date(b.surgeryDate).valueOf()
    return bDate - aDate
  })
  return {
    ...idMapTransform({}, 'data', 'patientSurgeryId', sortedData),
    queryMetadata: { total },
  }
}

export const useSurgeryApi = apiStore<Surgery, IdMap<Surgery>>(
  'surgeryApi',
  '/api/care_plan/patient_surgeries',
  {
    transformData: (d: { data: Surgery[]; queryMetadata: { total: number } }) =>
      transformSurgeries(d.data, d.queryMetadata.total),
  }
)

/**
 *
 * @param data
 * @param total
 */
function transformRadiations(
  data: Radiation[],
  total: number
): Partial<RadiationDataState> {
  const currentData = useRadiationApi().data
  const currentDataMap = toIdMap(data, 'patientRadiationId')
  const newData = Object.assign({}, currentDataMap, currentData)
  // sort direction should be in desc order based on radiationDate
  const sortedData = Object.values(newData).sort(
    (a: Radiation, b: Radiation) => {
      const aDate = new Date(a.radiationDate).valueOf()
      const bDate = new Date(b.radiationDate).valueOf()
      return bDate - aDate
    }
  )
  return {
    ...idMapTransform({}, 'data', 'patientRadiationId', sortedData),
    queryMetadata: { total },
  }
}

export const useRadiationApi = apiStore<Radiation, IdMap<Radiation>>(
  'radiationApi',
  '/api/care_plan/patient_radiations',
  {
    transformData: (d: {
      data: Radiation[]
      queryMetadata: { total: number }
    }) => transformRadiations(d.data, d.queryMetadata.total),
  }
)
