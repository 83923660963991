<template>
  <div class="flex flex-col p-3">
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 mb-2"
    >
      <h4>Other Contacts</h4>
      <TMTertiaryButton icon="pencil" @click="toggleEditOtherContactsModal" />
    </div>
    <div v-if="contacts" class="flex flex-col space-y-5">
      <div
        v-for="contact in contacts"
        :key="contact.contactId"
        class="space-y-2"
      >
        <div class="flex justify-between">
          <div>
            <div class="font-bold flex items-center space-x-2">
              {{
                formatName(
                  contactPersonObj(contact)?.firstName,
                  contactPersonObj(contact)?.lastName
                )
              }}
              <TIcon
                v-if="!contact.approvedToSpeak"
                color="nash-brick600"
                class="is-inline-block ml-2"
                icon="exclamationCircle"
              />
            </div>
            <div class="flex flex-row space-x-2">
              <p v-if="displayRelationship(contact.relationshipToPatient)">
                {{ displayRelationship(contact.relationshipToPatient) }}
              </p>
              <div
                v-if="
                  displayLanguage(
                    contact.contactEntity.person?.preferredLanguage
                  )
                "
                class="bullet"
              ></div>
              <p
                v-if="
                  displayLanguage(
                    contact.contactEntity.person?.preferredLanguage
                  )
                "
              >
                {{
                  displayLanguage(
                    contact.contactEntity.person?.preferredLanguage
                  )
                }}
              </p>
              <div v-if="contact.caregiverPoa" class="bullet"></div>
              <p v-if="contact.caregiverPoa">HCP</p>
            </div>
            <div
              v-if="contactPhone(contact)"
              class="flex items-center space-x-1 py-1.5"
            >
              <p>
                {{ formatPhone(contactPhone(contact)!.phoneNumber) || 'N/A' }}
              </p>
              <div v-if="contactPhone(contact)!.type" class="bullet"></div>
              <p v-if="contactPhone(contact)!.type">
                {{ capitalize(contactPhone(contact)!.type || '') }}
              </p>
            </div>
          </div>
          <div
            v-if="contactPhone(contact)"
            class="flex space-x-2 mb-2 items-center"
          >
            <div
              v-if="shouldNotContact"
              v-tooltip.right="DNC_STRING"
              class="relative inline-block"
            >
              <TOCallButton
                name="other-contact-dnc"
                :phone-number="contactPhone(contact)!.phoneNumber"
                :call-icon="IconifyIcons.phoneXMark"
                call-icon-fill="nash-brick600"
                :is-patient-contact="false"
                @call="createCallDisposition(contact.contactEntityId)"
              />
            </div>
            <TOCallButton
              v-else
              name="contact"
              :phone-number="contactPhone(contact)!.phoneNumber"
              @call="createCallDisposition(contact.contactEntityId)"
            />
            <TMChipButton
              v-if="
                contactPhone(contact)!.messagingOptInStatus &&
                contact.approvedToSpeak &&
                isMobilePhoneNumberType(contactPhone(contact)!) && 
                !shouldNotContact
              "
              name="contact-phonenumber"
              icon="heroicons:chat-bubble-oval-left"
              size="sm"
              icon-fill="nash-purple500"
              @click="createOrOpenText(contactPhone(contact)!.phoneNumberId)"
            />
            <div
              v-else-if="isMobilePhoneNumberType(contactPhone(contact)!) && !shouldNotContact"
              v-tooltip.right="
                renderNonTextableMsg(
                  contactPhone(contact)!,
                  contact.approvedToSpeak
                )
              "
              class="relative inline-block"
            >
              <TMChipButton
                v-if="!contact.approvedToSpeak"
                :disabled="true"
                name="contact-phonenumber"
                icon="notApprovedToSpeak"
                size="sm"
                icon-fill="nash-purple500"
              />
              <div
                v-else-if="
                  contactPhone(contact)!.messagingOptInStatus === false
                "
              >
                <TMChipButton
                  name="contact-phonenumber"
                  icon="declinedTexting"
                  :disabled="true"
                  size="sm"
                  icon-fill="nash-purple500"
                />
              </div>
              <div
                v-else-if="contactPhone(contact)!.messagingOptInStatus === null"
              >
                <TMChipButton
                  name="contact-phonenumber"
                  icon="notConsented"
                  size="sm"
                  icon-fill="nash-purple500"
                  @click="
                    createOrOpenText(contactPhone(contact)!.phoneNumberId)
                  "
                />
              </div>
            </div>
            <div
              v-else-if="isMobilePhoneNumberType(contactPhone(contact)!) && shouldNotContact"
              v-tooltip.right="DNC_STRING"
              class="relative inline-block"
            >
              <TMChipButton
                name="patient-phonenumber-dnc"
                icon="notApprovedToSpeak"
                icon-fill="nash-purple500"
                size="sm"
                @click="createOrOpenText(contactPhone(contact)!.phoneNumberId)"
              />
            </div>
          </div>
        </div>
        <div v-if="contactAddressObj(contact)">
          <div class="font-bold italic">Address</div>
          <div>{{ contactAddressObj(contact)!.streetAddressLine1 }}</div>
          <div v-if="contactAddressObj(contact)!.streetAddressLine2">
            {{ contactAddressObj(contact)!.streetAddressLine2 }}
          </div>
          <div>
            {{ contactAddressObj(contact)!.city }},
            {{ contactAddressObj(contact)!.state }}
            {{ contactAddressObj(contact)!.zip }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>No contacts documented.</div>
    <EditOtherContacts
      :is-visible="showEditOtherContacts"
      @close="toggleEditOtherContactsModal"
    />
  </div>
</template>

<script lang="ts">
import { datadogRum } from '@datadog/browser-rum'
import TMChipButton from '@nashville/button/TMChipButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { FillColor, IconifyIcons } from '@thyme/nashville/src/types/icons'
import capitalize from 'lodash/capitalize'
import kebabCase from 'lodash/kebabCase'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { createDisposition } from '@/legacy/components/patient/communicationV2/lib/callDispositions'
import { createCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import { dial } from '@/legacy/libs/call'
import { lookupEnum } from '@/legacy/libs/enum'
import { formatName, formatPhone } from '@/legacy/libs/format'
import TOCallButton from '@/legacy/nashville/button/TOCallButton.vue'
import router from '@/legacy/router'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  isMobilePhoneNumberType,
  renderNonTextableMsg,
} from '@/legacy/store/modules/lib/phoneNumbers'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { SpeakingWithType } from '@/legacy/types/communications/callDispositions'
import { OUTBOUND_CALL_TYPE_POST } from '@/legacy/types/communications/communications'
import { Language } from '@/legacy/types/entities/people'
import { PhoneNumberType } from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import { Contact } from '@/legacy/types/patients/contacts'
import { DNC_STRING } from '@/legacy/types/patients/humanaDnc'
import {
  PROGRAM_STATUS_DO_NOT_CALL_KEY,
  PROGRAM_STATUS_ENROLLMENT_KEY,
} from '@/legacy/types/patients/patients'
import { contactPersonObj, displayRelationship } from './lib/contacts'
import { handleRerouteToTextingInbox } from './lib/texting'
import EditOtherContacts from './modals/EditOtherContacts.vue'

export default defineComponent({
  components: {
    EditOtherContacts,
    TMChipButton,
    TIcon,
    TMTertiaryButton,
    TOCallButton,
  },
  setup() {
    const route = useRoute()
    const showEditOtherContacts = ref(false)

    const { patient, contacts } = storeToRefs(usePatientStore())
    const { selfEntity } = storeToRefs(useProfileStore())
    const { showHumanaDnc } = storeToRefs(useFlagStore())

    const doNotContact = computed(
      () =>
        patient.value?.programSubstatus === PROGRAM_STATUS_DO_NOT_CALL_KEY &&
        patient.value?.programStatus === PROGRAM_STATUS_ENROLLMENT_KEY
    )
    const shouldNotContact = computed(
      () => doNotContact.value && showHumanaDnc.value
    )

    const currentOpenSubtaskId = computed(() => {
      return (route.query.subtaskId as string) ?? null
    })

    /**
     * Function to show/hide edit contacts modal
     */
    function toggleEditOtherContactsModal() {
      showEditOtherContacts.value = !showEditOtherContacts.value
    }

    /**
     * Function to help display language from enum
     * @param lang
     */
    function displayLanguage(lang: string | null | undefined) {
      if (!lang) {
        return undefined
      }
      return lookupEnum(Language, lang.toLowerCase())
    }

    /**
     * Function to link any open subtasks to created communication
     * @param id
     */
    async function linkOpenSubtasks(
      { newIds }: { [key: string]: string[] },
      id: string
    ) {
      const commsStore = useCommunicationsStore()
      for (const newId of newIds) {
        await commsStore.linkSubtask(id, newId)
      }
      if (patient.value) {
        await useSubtasksStore().getSubtasks({
          filter_patient_ids: [patient.value.entityId],
          filter_subtask_ids: newIds,
        })
      }
    }

    /**
     * Function that links an open subtask to a created communication ID.
     * @param createdId
     */
    async function linkOpenSubtask(createdId: string) {
      if (currentOpenSubtaskId.value) {
        await linkOpenSubtasks(
          { newIds: [currentOpenSubtaskId.value] },
          createdId
        )
      }
    }

    /**
     *
     * Function that creates communication and outbound call disposition.
     * All communications and call dispositions created from
     * left patient sidebar call button will be considered an immedate
     * outbound call without the scheduled calls UI workflow.
     * @param contactEntityId
     */
    async function createCallDisposition(contactEntityId: string) {
      const commType = OUTBOUND_CALL_TYPE_POST
      const createdComm = await createCommunication(commType)
      if (!createdComm) {
        useNotificationStore().setNotification({
          message: `Communication for ${commType.type} not created`,
          type: NotificationType.DANGER,
        })
        return
      }

      const commId = createdComm.communicationId
      const dispositionPayload = {
        ...commType.payload,
        speakingWithPersonId: contactEntityId,
        speakingWithType: SpeakingWithType.contact,
        isIdentityVerified: false,
        completedByStaffId: selfEntity.value?.entityId,
      }

      datadogRum.addAction('createDispositionStart', {
        patientId: patient.value?.entityId,
        communicationId: createdComm.communicationId,
        staffId: selfEntity.value?.entityId,
        calledFrom: 'OtherContacts.vue',
      })
      const createdCallDisposition = await createDisposition(
        commId,
        dispositionPayload
      )

      if (!createdCallDisposition) {
        useNotificationStore().setNotification({
          message: `Communication Subtype ${commType.subtype} not created`,
          type: NotificationType.DANGER,
        })
      }

      await linkOpenSubtask(commId)
      useCommunicationsStore().communicationType = commType
      await router.push({
        query: {
          commId: createdComm.communicationId,
        },
      })
      useNotificationStore().setNotification({
        message: `Communication for ${commType.label} created`,
        type: NotificationType.SUCCESS,
      })
    }

    /**
     * Function to create or open existing SMS communication from message button click
     * @param phoneNumberId
     */
    async function createOrOpenText(phoneNumberId: string) {
      if (patient.value?.entityId) {
        await handleRerouteToTextingInbox(
          phoneNumberId,
          patient.value.entityId,
          currentOpenSubtaskId.value
        )
      }
    }

    /**
     * Function to trigger zoom call on phone button click
     * @param phoneNumber
     * @param contactEntityId
     */
    async function call(phoneNumber: string, contactEntityId: string) {
      // Attempt to wait for call disposition to be created before dialing
      // to prevent network interruption by zoom
      // see MT-3659 for detailed error
      await createCallDisposition(contactEntityId)
      await dial({ phoneNumber })
    }

    /**
     * Function to grab nested phone number object from contact object
     * @param contact
     */
    function contactPhone(contact: Contact) {
      if (
        contact.contactEntity.phoneNumbers &&
        contact.contactEntity.phoneNumbers.length
      ) {
        return contact.contactEntity.phoneNumbers[0].phoneNumber ?? undefined
      }
      return undefined
    }

    /**
     * Function to grab nested address object from contact object
     * @param contact
     */
    function contactAddressObj(contact: Contact) {
      if (contact.contactEntity.addresses) {
        return contact.contactEntity.addresses[0] ?? undefined
      }
    }

    return {
      DNC_STRING,
      shouldNotContact,
      IconifyIcons,
      showHumanaDnc,
      doNotContact,
      isMobilePhoneNumberType,
      toggleEditOtherContactsModal,
      showEditOtherContacts,
      contactAddressObj,
      PhoneNumberType,
      call,
      contactPhone,
      createOrOpenText,
      renderNonTextableMsg,
      formatPhone,
      displayLanguage,
      displayRelationship,
      FillColor,
      formatName,
      contactPersonObj,
      patient,
      contacts,
      kebabCase,
      createCallDisposition,
      capitalize,
    }
  },
})
</script>
<style lang="scss" scoped>
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
../communicationV2/lib/sharedCommunicationParts
