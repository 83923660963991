<template>
  <div v-if="allPreDataLoaded && allCarePods.length">
    <TTable
      ref="table"
      title="Assigned Queues"
      name="StaffQueue"
      :show-header-container="false"
      :rows="10"
      :rows-per-page="[10, 25]"
      :columns="columns"
      :params="params"
      :api-store="useStaffQueueApi"
      :filters="filters"
      @data-loaded="getStaffStats"
    >
      <template #startColumns>
        <Column expander class="w-20" />
      </template>

      <template #column-assignee="slotProps">
        {{ slotProps.column.display(null, slotProps.row) }}
      </template>

      <template #column-member="slotProps">
        <div class="italic">
          {{ slotProps.column.displayData(slotProps.row)?.memberCount ?? 0 }}
          Members
        </div>
      </template>

      <template #column-pod="slotProps">
        <div class="italic">
          {{ slotProps.column.displayData(slotProps.row)?.carePodCount ?? 0 }}
          Pods
        </div>
      </template>

      <template #column-timing="slotProps">
        <div class="italic">
          {{
            slotProps.column.displayData(slotProps.row)?.scheduledCallCount ?? 0
          }}
          Scheduled Call(s)
        </div>
      </template>

      <template #column-tasks="slotProps">
        <div class="italic">
          {{ slotProps.column.displayData(slotProps.row)?.subtaskCount ?? 0 }}
          Subtask(s)
        </div>
      </template>

      <template #expansion="slotProps">
        <MyQueueTable
          v-if="useStacksIncident121225"
          ref="myQueue"
          :is-enrollment-specialist="isEnrollmentSpecialist"
          :responsible-staff-id="slotProps.data.entityId"
          :custom-api-store="createMyQueueApi"
        />
      </template>
    </TTable>
  </div>
</template>

<script lang="ts">
import Column from 'primevue/column'
import { defineComponent } from 'vue'
import MyQueueTable from '@/legacy/components/queue/MyQueueTable/MyQueueTable.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'

import setup from './controller'

export default defineComponent({
  components: {
    MyQueueTable,
    Column,
    TTable,
  },
  props: {
    isEnrollmentSpecialist: {
      type: Boolean,
      default: false,
    },
  },
  setup,
})
</script>
