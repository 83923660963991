import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useHumanaEligibilityApi } from './store'
import { HumanaEligibilityPayload, HumanaEligibilityData } from './types'

/**
 * Retrieve eligibility data from Humana for a given date of birth and member ID.
 * Returns null if no data is returned.
 * @param payload
 */
export async function getHumanaEligibility(
  payload: HumanaEligibilityPayload
): Promise<HumanaEligibilityData | null> {
  const humanaEligibilityApi = useHumanaEligibilityApi()
  const response = await humanaEligibilityApi.retrieve({
    ids: ['eligibility'],
    params: payload,
    metaOptions: { bubbleErrorThrow: true },
  })
  if (!response) {
    return null
  }

  if (humanaEligibilityApi.error) {
    const error = humanaEligibilityApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching Humana Eligibility for ID ${payload.umid}',
      type: NotificationType.WARNING,
      error: `Error fetching Humana Eligibility for ID ${payload.umid}: ${error}`,
    })
  }
  return response
}
