import { IconifyIcons } from '@thyme/nashville/src/types/icons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { computed } from 'vue'
import { DNC_STRING } from '@/legacy/types/patients/humanaDnc'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  /**
   * Close the modal
   */
  function close() {
    context.emit('close')
  }

  // IMPROVEME(MT-3883): Add different message for case when Humana Member ID not found
  const dncText = computed(() => {
    return props.alternateModalText
      ? props.alternateModalText
      : 'Call cannot be made because this member is on the **Humana Do Not Call (DNC) list.** Members on this list have asked their insurance not to call them.'
  })
  const iconColor = 'nash-tigerlily800'
  return {
    DNC_STRING,
    close,
    dncText,
    IconifyIcons,
    iconColor,
    ModalSize,
  }
}
