export enum TierLevel {
  COMPLEX = 'COMPLEX',
  STANDARD = 'STANDARD',
  ESSENTIAL = 'ESSENTIAL',
  UNMAPPED_TIER = 'UNMAPPED_TIER',
}

const _HIGH_ACUITY_THRESHOLD = 49
const _MEDIUM_ACUITY_THRESHOLD = 25
export enum AcuityThreshold {
  High = _HIGH_ACUITY_THRESHOLD,
  Medium = _MEDIUM_ACUITY_THRESHOLD,
}
