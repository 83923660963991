import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import {
  AcuityThreshold,
  TierLevel,
} from '@/pages/PatientProfile/CarePlans/CarePlan/EditCarePlan/types'

/**
 * To replace acuity
 * @param score
 */
export function formatTierScore(score: number | undefined) {
  if (!score) {
    return '-'
  }
  return score > AcuityThreshold.High
    ? startCase(toLower(TierLevel.COMPLEX))
    : score > AcuityThreshold.Medium
    ? startCase(toLower(TierLevel.STANDARD))
    : startCase(toLower(TierLevel.ESSENTIAL))
}
