<template>
  <div class="container-style card-style">
    <h2 class="font-medium py-2 print-h3">Clinical Summary</h2>
    <div>
      <h3 class="font-medium print-h3">Cancer</h3>
      <PatientCancerDetails />
    </div>
    <div>
      <h3 class="font-medium print-h3">Treatment</h3>
      <PatientTreatment />
    </div>
    <div>
      <h3 class="font-medium print-h3">Medical Conditions</h3>
      <MedicalConditions />
    </div>
    <div>
      <h3 class="font-medium print-h3">Medical Team</h3>
      <MedicalTeam />
    </div>
    <div>
      <h3 class="font-medium print-h3">Fall Risk</h3>
      <FallRisk />
    </div>
    <div>
      <h3 class="font-medium print-h3">Physical Function</h3>
      <PhysicalFunctionAssessment />
    </div>
    <div>
      <h3 class="font-medium print-h3">Transitions of Care</h3>
      <TransitionsOfCare />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FallRisk from '@/pages/PatientProfile/CarePlans/ClinicalSummary/FallRisk/FallRisk.vue'
import MedicalConditions from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/MedicalConditions.vue'
import MedicalTeam from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalTeam/MedicalTeam.vue'
import PatientCancerDetails from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientCancerDetails/PatientCancerDetails.vue'
import PatientTreatment from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/PatientTreatment.vue'
import PhysicalFunctionAssessment from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PhysicalFunctionAssessment/PhysicalFunctionAssessment.vue'
import TransitionsOfCare from '@/pages/PatientProfile/CarePlans/ClinicalSummary/TransitionsOfCare/TransitionsOfCare.vue'

export default defineComponent({
  components: {
    FallRisk,
    PhysicalFunctionAssessment,
    PatientCancerDetails,
    PatientTreatment,
    TransitionsOfCare,
    MedicalTeam,
    MedicalConditions,
  },
  setup() {
    return {}
  },
})
</script>
