<template>
  <TModal
    :is-visible="isOpen"
    :title="DNC_STRING"
    :title-icon="IconifyIcons.phoneXMarkFilled"
    :title-icon-color="iconColor"
    :is-warning="true"
    class="text-nash-neutral900"
    :size="ModalSize.SM"
    @close="close"
  >
    <TMarkdown :source="dncText" />
  </TModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TMarkdown,
    TModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    alternateModalText: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup,
})
</script>
