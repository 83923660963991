<template>
  <TModal
    :flex="true"
    :is-visible="isVisible"
    :allow-overflow="true"
    title="Add Subtasks"
    :size="ModalSize.LG"
    @close="close"
  >
    <TSearchAndFilter
      search-placeholder="Search Subtasks..."
      :filter-options="[]"
      class="text-nash-neutral900 focus:nash-purple500"
      @update-search="updateSearch"
    />
    <div class="overflow-auto h-full">
      <div v-if="!!subtasks.length" class="overflow-auto space-y-4 h-full">
        <AddActualSubtaskInner
          v-for="subtask in subtasks"
          :key="subtask.subtaskId"
          :subtask="subtask"
          :linked-subtask-ids="linkedSubtaskIds"
          @add="addSubtask"
          @remove="removeSubtask"
        />
        <TMTertiaryButton
          v-if="totalSubtasks > subtasks.length"
          label="Load more"
          @click="loadMore"
        />
      </div>
      <div v-else>No existing subtasks to add.</div>
    </div>
    <template #actions>
      <div :class="MODAL_FOOTER_CLASSES">
        <TMSecondaryButton
          label="Create New Evergreen Tasks"
          @click="openCreateNewEvergreenTasksModal"
        />
        <div :class="SAVE_CANCEL_BTN_CLASSES">
          <LegacyTButton type="button" value="Cancel" @click="close" />
          <LegacyTButton value="Save" @click="saveAdd" />
        </div>
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import capitalize from 'lodash/capitalize'
import remove from 'lodash/remove'
import {
  defineComponent,
  computed,
  ref,
  PropType,
  watch,
  onBeforeMount,
} from 'vue'
import { useRoute } from 'vue-router'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { Subtask, openSubtaskStatuses } from '@/legacy/types/pathways/subtasks'
import AddActualSubtaskInner from './AddActualSubtaskInner.vue'

const MODAL_FOOTER_CLASSES = 'flex justify-between w-full'
const SAVE_CANCEL_BTN_CLASSES = 'w-1/3 flex flex-row justify-end space-x-4'

export default defineComponent({
  components: {
    TMTertiaryButton,
    TSearchAndFilter,
    TModal,
    AddActualSubtaskInner,
    LegacyTButton,
    TMSecondaryButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    linkedSubtaskIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['close', 'linkSubtasks', 'openCreateNewEvergreenTasksModal'],
  setup(props, context) {
    const route = useRoute()

    const subtasks = ref<Subtask[]>([])
    const currentSearch = ref('')
    const pageNumber = ref(1)
    const totalSubtasks = ref(0)
    const addedSubtaskIds = ref<string[]>([])
    const showCreateNewEvergreenTasksModal = ref(false)

    /**
     * Function for fetching paginated open-status subtasks
     */
    async function fetchSubtasks() {
      return await useSubtasksStore().getSubtasks({
        // Not fetching latest reattempt, since not rendered in this modal
        filter_patient_ids: [patientId.value],
        page_length: 10,
        page_number: pageNumber.value,
        filter_free_text: currentSearch.value,
        filter_subtask_status: openSubtaskStatuses,
      })
    }

    onBeforeMount(async () => {
      const results = await fetchSubtasks()
      subtasks.value = results.data
      totalSubtasks.value = results.queryMetadata.total
    })

    const patientId = computed(() => `${route.params.patientId ?? ''}`)

    watch(currentSearch, async () => {
      pageNumber.value = 1
      const results = await fetchSubtasks()
      subtasks.value = [...results.data]
      totalSubtasks.value = results.queryMetadata.total
    })

    const addSubtask = (taskTemplateIds: string) =>
      addedSubtaskIds.value.push(taskTemplateIds)

    const removeSubtask = (taskTemplateId: string) => {
      remove(addedSubtaskIds.value, (v) => v === taskTemplateId)
    }

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      const results = await fetchSubtasks()
      subtasks.value = [...subtasks.value, ...results.data]
    }

    const close = () => {
      context.emit('close')
      addedSubtaskIds.value = []
    }

    const saveAdd = async () => {
      context.emit('linkSubtasks', addedSubtaskIds.value)
      close()
    }

    const updateSearch = (newSearch: string) =>
      (currentSearch.value = newSearch ?? '')

    const openCreateNewEvergreenTasksModal = () => {
      context.emit('openCreateNewEvergreenTasksModal')
      close()
    }

    return {
      ModalSize,
      totalSubtasks,
      loadMore,
      saveAdd,
      currentSearch,
      subtasks,
      addedSubtaskIds,
      addSubtask,
      removeSubtask,
      capitalize,
      close,
      remove,
      updateSearch,
      showCreateNewEvergreenTasksModal,
      openCreateNewEvergreenTasksModal,
      MODAL_FOOTER_CLASSES,
      SAVE_CANCEL_BTN_CLASSES,
    }
  },
})
</script>
