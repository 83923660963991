import { requiredInject } from '@thyme/libs/src/vue/inject'
import { InputType } from '@thyme/nashville/src/types/inputs'
import { storeToRefs } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

/**
 * Set up the PatientSummary component
 */
export function setup(): {
  InputType: typeof InputType
  patientDisplay: Ref<{
    carePodId?: string | null
    entityId: string
    summary: string
  } | null>
  patientLoading: Ref<boolean>
  submit: (summary: string) => Promise<void>
  saveState: Ref<SaveState>
  showTabbedPatientProfile: Ref<boolean>
  toggleEditPatientSummary: () => void
  showEditPatientSummary: Ref<boolean>
} {
  const patientStore = usePatientStore()
  const { patient, isLoading: patientLoading } = storeToRefs(patientStore)
  const { showTabbedPatientProfile } = storeToRefs(useFlagStore())
  const patientId = requiredInject(PATIENT_ID_KEY)

  const patientDisplay = computed(() => {
    if (!patient.value) {
      return null
    }
    return {
      carePodId: patient.value.carePodId,
      entityId: patient.value.entityId,
      summary: patient.value.summaryNote,
    }
  })

  const saveState = ref<SaveState>(newSaveState())
  const showEditPatientSummary = ref(false)

  /**
   *
   */
  function toggleEditPatientSummary() {
    showEditPatientSummary.value = !showEditPatientSummary.value
  }

  /**
   * Update the patient's summary note with new content
   * @param summary new summary note content
   */
  async function submit(summary: string) {
    try {
      await usePatientsApi().partialUpdate({
        ids: [patientId.value],
        body: { summaryNote: summary },
        metaOptions: { saveState: saveState.value, bubbleErrorThrow: true },
      })
      void patientStore.patientApiCall(patientId.value)

      if (showEditPatientSummary.value) {
        toggleEditPatientSummary()
      }
    } catch (err) {
      console.error(err)
      return
    }
  }

  return {
    showEditPatientSummary,
    toggleEditPatientSummary,
    showTabbedPatientProfile,
    InputType,
    patientDisplay,
    patientLoading,
    submit,
    saveState,
  }
}
