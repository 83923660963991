import { reduce } from 'lodash'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useThymelineEntityApi } from '@/legacy/store/modules/thymelines'
import {
  Communication,
  ALL_COMMUNICATION_PARTS,
} from '@/legacy/types/communications/communications'
import {
  ThymelineItem,
  ThymelineItemType,
} from '@/legacy/types/patients/thymelines'

export const perPage = 10
export const columns = [
  {
    field: 'thymeline',
    header: 'Thymeline',
  },
]

// Outcomes Thymelines
/**
 * Function that returns params object for the outcomes thymeline
 * @param patientId
 */
export function outcomesParams(patientId: string) {
  return {
    filter_patient_ids: [patientId],
    parts: ['outcome_validation'],
    sort_by: 'createdAt,desc',
  }
}

// Communications Thymelines
/**
 * Function that returns params object for general comms thymeline
 * @param patientId
 */
export function commsSharedParams(patientId: string) {
  return {
    filter_patient_ids: [patientId],
    parts: ALL_COMMUNICATION_PARTS,
  }
}

// Communications History Thymelines
/**
 * Function that returns params object for completed comms history thymeline
 * @param patientId
 * @param filteredIds
 */
export function completedCommsHistoryParams(
  patientId: string,
  filteredIds: string[]
) {
  return {
    ...commsSharedParams(patientId),
    filter_is_completed: true,
    filter_communication_ids: filteredIds,
  }
}

/**
 * Function that returns params object for incomplete comms history thymeline
 * @param patientId
 * @param filteredIds
 */
export function incompleteHistoryCommsParams(
  patientId: string,
  filteredIds: string[]
) {
  return {
    ...commsSharedParams(patientId),
    filter_is_completed: false,
    filter_communication_ids: filteredIds,
  }
}

/**
 * Create a dictionary of item ids sorted by item type
 * @param items unsorted ThymelineItems
 * returns { [key in ThymelineItemType]: UUID[] } item ids sorted by item type
 */
export function getThymelineItemIds(items: ThymelineItem[]) {
  return reduce(
    items,
    (acc, { itemType, itemId }: ThymelineItem) => {
      if (acc[itemType]) {
        acc[itemType].push(itemId)
      }
      return acc
    },
    {
      [ThymelineItemType.TRANSITION_OF_CARE]: [],
      [ThymelineItemType.SUBTASK]: [],
      [ThymelineItemType.COMMUNICATION]: [],
    } as { [key in ThymelineItemType]: string[] }
  )
}

/**
 * Get Entities for Listed communications.
 * Save to Communications Store in communicationPersons
 * @param communications
 */
export async function getCommunicationEntities(
  communications: Communication[]
) {
  const speakingWithIds = communications.reduce(
    (acc: string[], cur: Communication) => {
      cur.callDisposition?.speakingWithPersonId &&
      !acc.includes(cur.callDisposition?.speakingWithPersonId)
        ? acc.push(cur.callDisposition.speakingWithPersonId)
        : cur.plannedCall?.calleeEntityId &&
          !acc.includes(cur.plannedCall?.calleeEntityId)
        ? acc.push(cur.plannedCall.calleeEntityId)
        : null
      return acc
    },
    []
  )
  if (speakingWithIds.length) {
    const speakingWithEntities = await useThymelineEntityApi().list({
      params: {
        filter_entity_ids: speakingWithIds,
        parts: ['person'],
      },
    })
    useCommunicationsStore().updateCommunicationPersons(
      speakingWithEntities.data
    )
  }
}
