import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { getPatientClinicalSummary } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'
import { formatTocMetadataString } from './format'
import { getPaginatedTransitionsOfCare } from './queries'
import { useManageTransitionsOfCareApi } from './store'
import {
  ManageTocDisplay,
  manageTransitionsOfCareProps,
  PAGE_LENGTH,
  tocActions,
} from './types'

type PropsType = ExtractPropTypes<typeof manageTransitionsOfCareProps>

/**
 * Set up the ManageMedicalConditions component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: tocs, queryMetadata: tocMetadata } = storeToRefs(
    useManageTransitionsOfCareApi()
  )
  const { showTabbedPatientProfile } = storeToRefs(useFlagStore())

  const creating = ref(false)
  const editingToc = ref<ManageTocDisplay | null>(null)
  const deletingToc = ref<ManageTocDisplay | null>(null)
  const pageNumber = ref(1)

  const tocsArray = computed(() => Object.values(tocs.value ?? {}))
  const totalTocCount = computed(() => tocMetadata.value?.total ?? 0)

  const showAddOrEditModal = computed(
    () => (creating.value || !!editingToc.value) && !deletingToc.value
  )

  const showMoreTocsButton = computed(
    () => Object.values(tocs.value ?? {}).length < totalTocCount.value
  )

  const manageTocDisplay = computed(() => {
    if (!tocs.value || tocsArray.value.length === 0) {
      return []
    }

    return tocsArray.value
      .map((toc: CarePlanTransitionsOfCare) => ({
        ...toc,
        dateDisplay: toc.dischargeDate
          ? `${new Date(toc.admissionDate).toLocaleDateString()} - ${new Date(
              toc.dischargeDate
            ).toLocaleDateString()}`
          : new Date(toc.admissionDate).toLocaleDateString(),
        metadataDisplay: formatTocMetadataString(
          toc.dischargeDiagnosis,
          toc.dischargeLocation
        ),
      }))
      .sort((a, b) => {
        return (
          new Date(b.admissionDate).getTime() -
          new Date(a.admissionDate).getTime()
        )
      })
  })

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    clearStates()
    context.emit('close')
  }

  const addOrEditModalInitialData = computed(() => ({
    admissionDate: editingToc.value?.admissionDate
      ? new Date(editingToc.value.admissionDate)
      : undefined,
    dischargeDate: editingToc.value?.dischargeDate
      ? new Date(editingToc.value.dischargeDate)
      : undefined,
    eventType: editingToc.value?.eventType,
    dischargeDiagnosis: editingToc.value?.dischargeDiagnosis,
    dischargeLocation: editingToc.value?.dischargeLocation,
    isReadmissionRisk: editingToc.value?.isReadmissionRisk,
    hasNewDmeOnDischarge: editingToc.value?.hasNewDmeOnDischarge,
    servicesOnDischarge: editingToc.value?.servicesOnDischarge,
    moreThanThreeAdmissionsInLastSixMonths:
      editingToc.value?.moreThanThreeAdmissionsInLastSixMonths,
    summaryOfEvents: editingToc.value?.summaryOfEvents,
    hasElectiveAdmission: editingToc.value?.hasElectiveAdmission,
  }))

  /**
   *
   * @param value
   * @param manageTocDisplay
   */
  function triggerTocAction(value: string, manageTocDisplay: ManageTocDisplay) {
    if (!manageTocDisplay) {
      useNotificationStore().setNotification({
        message: 'Selected medical condition not found - cannot perform action',
        type: NotificationType.DANGER,
      })
      return
    }

    if (value === 'delete') {
      deletingToc.value = manageTocDisplay
    } else if (value === 'edit') {
      editingToc.value = manageTocDisplay
    }
  }

  /**
   *
   */
  function resetTocStores() {
    useManageTransitionsOfCareApi().data = {}
    useManageTransitionsOfCareApi().datum = null
    useManageTransitionsOfCareApi().queryMetadata = null
  }

  // fetch 3 more treatments, surgeries, or radiation dates
  /**
   *
   */
  async function loadMore() {
    pageNumber.value = pageNumber.value + 1
    await getPaginatedTransitionsOfCare(
      props.patientId,
      PAGE_LENGTH,
      pageNumber.value
    )
  }

  /**
   *
   */
  function clearStates() {
    editingToc.value = null
    deletingToc.value = null
    creating.value = false
  }

  /**
   *
   * refetch tocs
   */
  async function refetchTocs() {
    pageNumber.value = 1

    try {
      await getPaginatedTransitionsOfCare(
        props.patientId,
        PAGE_LENGTH,
        pageNumber.value
      )
      context.emit('refetch')
      if (!showTabbedPatientProfile.value) {
        await getPatientClinicalSummary(props.patientId)
      }
      clearStates()
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to fetch transitions of care',
        type: NotificationType.DANGER,
      })
    }
  }

  onBeforeMount(async () => {
    resetTocStores()
    await getPaginatedTransitionsOfCare(
      props.patientId,
      PAGE_LENGTH,
      pageNumber.value
    )
  })

  return {
    // status
    resetTocStores,
    creating,
    // display
    manageTocDisplay,
    showAddOrEditModal,
    clearStates,
    // modal
    tocActions,
    ModalSize,
    editingToc,
    deletingToc,
    triggerTocAction,
    loadMore,
    showMoreTocsButton,
    // actions
    getPaginatedTransitionsOfCare,
    addOrEditModalInitialData,
    refetchTocs,
    close,
  }
}
