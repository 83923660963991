import { idMapTransform, toAccIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Entity } from '@/legacy/types/entities/entities'
import { PreferredContactTime } from '@/legacy/types/patients/contactTimes'
import { Patient } from '@/legacy/types/patients/patients'
import {
  Reminder,
  RemindersSubtaskStatus,
  Stack,
  SubtaskStaffStats,
} from '@/legacy/types/reminders'

export const useRemindersPatientsApi = apiStore<Patient, IdMap<Patient>>(
  'taskPatientsApi',
  '/api/patients',

  {
    transformData: (d: { data: Patient[] }) =>
      idMapTransform({}, 'data', 'entityId', d.data),
  }
)

export const useRemindersApi = apiStore<Reminder>(
  'remindersApi',
  '/api/subtasks',

  {
    transformData: (d: { data: Reminder[] }) => d,
    params: {
      filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
      parts: ['latest_reattempt'],
    },
  }
)

// Stacks
export const useStacksApi = apiStore<Stack>(
  'stacksApi',
  '/api/subtasks/stacks',

  {
    transformData: (d: { data: Stack[] }) => d,
    params: {
      filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
    },
  }
)

export const useUnassignedStacksApi = apiStore<Stack>(
  'unassignedStacksApi',
  '/api/subtasks/stacks',
  {
    transformData: (d: { data: Stack[] }) => d,
    params: {
      filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
    },
  }
)

// Pod Lead
export const useStaffQueueApi = apiStore<Entity>(
  'staffQueueApi',
  '/api/entities/staff',

  {
    transformData: (d: { data: Entity[] }) => d,
  }
)

export const useSubtaskStaffStatsApi = apiStore<
  SubtaskStaffStats,
  IdMap<SubtaskStaffStats>
>(
  'subtaskStaffStatsApi',
  '/api/subtasks/stacks/staff_stats',

  {
    transformData: (d: { data: SubtaskStaffStats[] }) =>
      idMapTransform({}, 'data', 'staffId', d.data),
  }
)

export const createMyQueueApi = (staffId: string) =>
  apiStore<Stack>(`${staffId}MyQueueApi`, '/api/subtasks/stacks', {
    transformData: (d: { data: Stack[] }) => d,
    params: {
      filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
      filter_staff_ids: [staffId],
    },
  })

export const usePreferredContactTimesApi = apiStore<
  PreferredContactTime,
  IdMap<PreferredContactTime[]>
>('preferredContactTimesApi', '/api/patients/contact_preferences/times', {
  transformData: (d: { data: PreferredContactTime[] }, state) => ({
    data: toAccIdMap(
      d.data,
      'patientId',
      'preferredContactTimeId',
      state?.data ?? {}
    ),
  }),
})
