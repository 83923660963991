import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ref } from 'vue'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const summaryString = ref<string>(props.summary)

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   */
  function submit() {
    context.emit('submit', summaryString.value)
  }

  return {
    submit,
    close,
    ModalSize,
    summaryString,
  }
}
